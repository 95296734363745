import React from "react"
import _ from "lodash"
import { toast } from "react-toastify"
import { useDashboardContext } from "@services/DashboardContext"
import {
  detectMob,
  handleLink,
  handleAddHttpPrefix,
  useInput,
  handleCloseSidenav,
} from "@src/Helper"
import { updateLinks } from "@http/DashboardRepository"
import { AlertWarning } from "@components/utils/Alert"
import Loading from "@components/utils/Loading"
import VanillaLinkItem from "./VanillaLinkItem"

const VanillaLinkLayout = React.forwardRef((props, ref) => {
  // Hooks
  const { personalize, vanillaLinks, isLoadingDashboard } =
    useDashboardContext()
  const isSubmitLoading = useInput(false)
  const errors = useInput({})

  // Variable
  let data = [...vanillaLinks.value]

  // Custom Functions
  const handleUpdateLinks = () => {
    isSubmitLoading.setValue(true)
    updateLinks(personalize.value.id, vanillaLinks.value)
      .then(() => {
        isSubmitLoading.setValue(false)
        handleCloseSidenav(ref, "vanilla-link__layout", () =>
          errors.setValue({})
        )
        toast.success("Berhasil menyimpan perubahan!")
      })
      .catch(err => {
        if (err.response.status === 422) {
          errors.setValue(err.response.data.errors)
        }
        isSubmitLoading.setValue(false)
        toast.error("Terdapat kesalahan pengisian data. Silahkan cek kembali.")
      })
  }

  const handleAddLink = () => {
    vanillaLinks.setValue([
      ...vanillaLinks.value,
      {
        label: "",
        url: "",
        is_active: true,
      },
    ])
  }

  const handleDeleteLink = idx => {
    let newLinkList = [...vanillaLinks.value]
    _.remove(newLinkList, (value, index) => {
      return index === idx
    })
    vanillaLinks.setValue(newLinkList)
    errors.setValue({})
  }

  const handleToggleLink = (idx, value) => {
    handleLink(idx, data, !value, "is_active", vanillaLinks)
  }

  const handleChangeName = (idx, value) => {
    handleLink(idx, data, value, "label", vanillaLinks)
  }

  const handleChangeUrl = (idx, value) => {
    handleLink(idx, data, value, "url", vanillaLinks)
  }

  const handleBlurUrl = (idx, value) => {
    handleAddHttpPrefix(idx, data, value, "url", vanillaLinks, false)
  }

  // JSX
  return (
    <div
      ref={ref}
      className={`customization__layout vanilla-link__layout ${
        detectMob()
          ? "vanilla-link__layout--slide-down"
          : "vanilla-link__layout--expand"
      }`}
    >
      <form
        onSubmit={e => {
          e.preventDefault()
          handleUpdateLinks()
        }}
      >
        <div className="vanilla-link__header">
          <div className="vanilla-link__header--left">
            <span
              className="mdi mdi-close mdi-24px"
              onClick={() =>
                handleCloseSidenav(ref, "vanilla-link__layout", () =>
                  errors.setValue({})
                )
              }
            ></span>
            <h2 className="vanilla-link__header-title--heading-5">
              <em>Link</em>
            </h2>
          </div>
          <div className="layout__header--right vanilla-link__header--right">
            <button
              className={`button button-md button-secondary ${
                detectMob() ? "w-100" : ""
              }`}
              type="submit"
              disabled={isSubmitLoading.value}
            >
              {isSubmitLoading.value ? (
                <span
                  className="spinner-border spinner-border-sm ms-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                <>Simpan Perubahan</>
              )}
            </button>
          </div>
        </div>
        {isLoadingDashboard.value ? (
          <Loading />
        ) : (
          <div className="vanilla-link__body">
            <div className={`container ${detectMob() ? "pad-b-90" : ""}`}>
              <AlertWarning
                className={`flex ${detectMob() ? "px-3" : "mx-4"}`}
                text={
                  <p className="flex-a-center body mb-0">
                    Hati-hati, menyunting atau menghapus sebuah link akan
                    mempengaruhi analitik dari link tersebut.
                  </p>
                }
              />
              <div className="vanilla-link__list" role="list">
                {vanillaLinks.value?.map((vanillaLink, key) => (
                  <VanillaLinkItem
                    id={key}
                    vanillaLink={vanillaLink}
                    handleDeleteLink={handleDeleteLink}
                    handleToggleLink={handleToggleLink}
                    handleChangeName={handleChangeName}
                    handleChangeUrl={handleChangeUrl}
                    handleBlurUrl={handleBlurUrl}
                    errors={errors}
                  />
                ))}
              </div>
              {vanillaLinks.value?.length !== 10 && (
                <div
                  className="vanilla-link__add-button"
                  role="button"
                  tabIndex="0"
                  onClick={() => handleAddLink()}
                  onKeyDown={e => {
                    if (e.key === "Enter") handleAddLink()
                  }}
                >
                  <span className="vanilla-link__add-icon mdi mdi-plus-circle"></span>
                  <p className="vanilla-link__add-text--body">
                    Tambah Link Baru
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
      </form>
    </div>
  )
})

export default VanillaLinkLayout
