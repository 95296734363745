import React, { useState } from "react"
import { useDashboardContext } from "@services/DashboardContext"
import { detectMob } from "@src/Helper"
import HelpTooltip from "@components/utils/HelpTooltip"
import { ColorPickerModal } from "@components/utils/Modal"

const StylingFeed = () => {
  // Hooks
  const { currentStylingValue } = useDashboardContext()
  const [isFgFeedModal, setIsFgFeedModal] = useState(false)
  const [isBgFeedModal, setIsBgFeedModal] = useState(false)

  // Custom Functions
  const handleDeleteFeedColor = type => {
    currentStylingValue.setValue(prevState => {
      return {
        ...prevState,
        settings: {
          ...prevState.settings,
          post: {
            ...prevState.settings.post,
            [type]: null,
          },
        },
      }
    })
  }

  // JSX
  return (
    <>
      <ColorPickerModal
        show={isFgFeedModal}
        onHide={() => setIsFgFeedModal(false)}
        id={detectMob() ? "m-feedFg" : "feedFg"}
        className={`${detectMob() ? "modal-z-index" : ""} color-picker-modal`}
        title="Warna Font Feed"
        size="sm"
        valueOne={
          currentStylingValue.value.settings.post?.foreground_color ?? "#ffffff"
        }
        isGradient={false}
        fn={(currentStylingValue, color) => {
          currentStylingValue.setValue(prevState => {
            return {
              ...prevState,
              settings: {
                ...prevState.settings,
                post: {
                  ...prevState.settings.post,
                  foreground_color: color.value,
                },
              },
            }
          })
        }}
      />
      <ColorPickerModal
        show={isBgFeedModal}
        onHide={() => setIsBgFeedModal(false)}
        id={detectMob() ? "m-feedBg" : "feedBg"}
        className={`${detectMob() ? "modal-z-index" : ""} color-picker-modal`}
        title="Warna Feed"
        size="sm"
        valueOne={
          currentStylingValue.value.settings.post?.background_color ?? "#ffffff"
        }
        isGradient={false}
        fn={(currentStylingValue, color) => {
          currentStylingValue.setValue(prevState => {
            return {
              ...prevState,
              settings: {
                ...prevState.settings,
                post: {
                  ...prevState.settings.post,
                  background_color: color.value,
                },
              },
            }
          })
        }}
      />
      <section className={`styling__feed ${detectMob() ? "px-0" : ""}`}>
        <h2 className="feed--heading-6">
          <em>Feed</em>
          <HelpTooltip>
            <em>Edit Profile</em> ini untuk mengganti warna pada post, warna
            yang dapat diganti berupa background dan tulisan.
          </HelpTooltip>
        </h2>
        <div className={`feed__container ${detectMob() ? "flex-col" : ""}`}>
          <div className="feed__fg">
            <h3 className="feed__fg--caption">
              Warna <em>Font Feed</em>
            </h3>
            <div className="flex mt-2">
              <div
                className={`feed__fg-selector ${
                  currentStylingValue.value.settings.post?.foreground_color ===
                  null
                    ? "border-dash__button"
                    : ""
                }`}
                onClick={() => setIsFgFeedModal(true)}
              >
                {currentStylingValue.value.settings.post?.foreground_color ? (
                  <div
                    className="feed__fg-placeholder"
                    style={{
                      background: currentStylingValue.value.settings.post
                        ?.foreground_color
                        ? currentStylingValue.value.settings.post
                            .foreground_color
                        : "",
                      border:
                        currentStylingValue.value.settings.post
                          ?.foreground_color === "#ffffff"
                          ? "1px solid #b9b9b9"
                          : "",
                    }}
                  ></div>
                ) : (
                  <span className="mdi mdi-palette text-black-30"></span>
                )}
              </div>
              <div
                className="feed__fg-value--caption"
                onClick={() => setIsFgFeedModal(true)}
              >
                {currentStylingValue.value.settings.post?.foreground_color
                  ? currentStylingValue.value.settings.post.foreground_color
                  : "Warna default"}
              </div>
              <button
                className="feed__fg-delete-button"
                onClick={e => {
                  e.preventDefault()
                  handleDeleteFeedColor("foreground_color")
                }}
              >
                <span className="mdi mdi-trash-can mdi-24px"></span>
              </button>
            </div>
          </div>
          <div className={`feed__bg ${detectMob() ? "ms-0 mt-3" : ""}`}>
            <h3 className="feed__bg--caption">
              Warna <em>Feed</em>
            </h3>
            <div className="flex mt-2">
              <div
                className={`feed__bg-selector ${
                  currentStylingValue.value.settings.post?.background_color ===
                  null
                    ? "border-dash__button"
                    : ""
                }`}
                onClick={() => setIsBgFeedModal(true)}
              >
                {currentStylingValue.value.settings.post?.background_color ? (
                  <div
                    className="feed__bg-placeholder"
                    style={{
                      background: currentStylingValue.value.settings.post
                        ?.background_color
                        ? currentStylingValue.value.settings.post
                            .background_color
                        : "",
                      border:
                        currentStylingValue.value.settings.post
                          ?.background_color === "#ffffff"
                          ? "1px solid #b9b9b9"
                          : "",
                    }}
                  ></div>
                ) : (
                  <span className="mdi mdi-palette text-black-30"></span>
                )}
              </div>
              <div
                className="feed__bg-value--caption"
                onClick={() => setIsBgFeedModal(true)}
              >
                {currentStylingValue.value.settings.post?.background_color
                  ? currentStylingValue.value.settings.post.background_color
                  : "Warna default"}
              </div>
              <button
                className="feed__bg-delete-button"
                onClick={e => {
                  e.preventDefault()
                  handleDeleteFeedColor("background_color")
                }}
              >
                <span className="mdi mdi-trash-can mdi-24px"></span>
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default StylingFeed
