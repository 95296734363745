import React, { useEffect } from "react"
import Modal from "react-bootstrap/Modal"
import { ChromePicker } from "react-color"
import { detectMob, useInput } from "@src/Helper"
import { useDashboardContext } from "@services/DashboardContext"

export const BasicModal = ({
  show,
  onHide,
  id,
  className,
  title,
  size,
  backdrop,
  children,
  fn,
}) => {
  // JSX
  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop={backdrop}
      id={`${id}Modal`}
      className={className}
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className={`${className}__head`}>
        <Modal.Title id="contained-modal-title-vcenter">
          <span
            role="button"
            tabIndex="0"
            aria-label="Hide Modal Button"
            className={`${className}__close-button mdi mdi-36px mdi-close`}
            onClick={() => {
              onHide()
              if (fn !== undefined) fn()
            }}
            onKeyDown={e => {
              if (e.key === "Enter") {
                onHide()
              }
            }}
          ></span>
          <h2 className={`${className}--heading-5`}>
            <em>{title}</em>
          </h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={`${className}__body`}>{children}</Modal.Body>
    </Modal>
  )
}

export const ColorPickerModal = ({
  show,
  onHide,
  id,
  className,
  title,
  size,
  valueOne,
  valueTwo,
  isGradient,
  fn,
}) => {
  // Hooks
  const colorOne = useInput(valueOne ?? "#ffffff")
  const colorTwo = useInput(valueTwo ?? "#ffffff")
  const { currentStylingValue } = useDashboardContext()

  useEffect(() => {
    colorOne.setValue(valueOne)
    colorTwo.setValue(valueTwo)
  }, [valueOne, valueTwo])

  // Custon Function
  const handleChangeColorOne = e => {
    colorOne.setValue(e.hex)
  }
  const handleChangeColorTwo = e => {
    colorTwo.setValue(e.hex)
  }

  const handleSaveColor = () => {
    isGradient
      ? fn(currentStylingValue, colorOne, colorTwo)
      : fn(currentStylingValue, colorOne)
  }

  // JSX
  return (
    <Modal
      show={show}
      onHide={onHide}
      id={`${id}Modal`}
      className={className}
      backdropClassName={detectMob() ? "modal-z-index" : ""}
      contentClassName={detectMob() ? "mx-3" : ""}
      title={title}
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className={`${className}__head`}>
        <h2 className={`${className}--heading-5`}>{title}</h2>
        <span
          role="button"
          tabIndex="0"
          aria-label="Hide Modal Button"
          id={detectMob() ? "m-closeIcon" : ""}
          className={`mdi mdi-36px mdi-close ${className}__close`}
          onClick={() => onHide()}
          onKeyDown={e => {
            if (e.key === "Enter") {
              onHide()
            }
          }}
        ></span>
      </Modal.Header>
      <Modal.Body className={`${className}__body ${detectMob() ? "px-3" : ""}`}>
        {isGradient ? (
          <div className="flex">
            <ChromePicker
              className="w-100"
              color={colorOne.value}
              disableAlpha={true}
              onChange={e => handleChangeColorOne(e)}
              onChangeComplete={e => handleChangeColorOne(e)}
            />
            <ChromePicker
              className="w-100 ms-3"
              color={colorTwo.value}
              disableAlpha={true}
              onChange={e => handleChangeColorTwo(e)}
              onChangeComplete={e => handleChangeColorTwo(e)}
            />
          </div>
        ) : (
          <ChromePicker
            className="w-100"
            color={colorOne.value}
            disableAlpha={true}
            onChange={e => handleChangeColorOne(e)}
            onChangeComplete={e => handleChangeColorOne(e)}
          />
        )}
        <button
          className={`${className}__button button button-md button-primary`}
          onClick={() => {
            handleSaveColor()
            onHide()
          }}
        >
          Simpan
        </button>
      </Modal.Body>
    </Modal>
  )
}
