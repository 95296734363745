// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-settings-js": () => import("./../../../src/pages/account-settings.js" /* webpackChunkName: "component---src-pages-account-settings-js" */),
  "component---src-pages-analytics-index-js": () => import("./../../../src/pages/analytics/index.js" /* webpackChunkName: "component---src-pages-analytics-index-js" */),
  "component---src-pages-analytics-media-post-index-js": () => import("./../../../src/pages/analytics/media-post/index.js" /* webpackChunkName: "component---src-pages-analytics-media-post-index-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-dashboard-index-js": () => import("./../../../src/pages/dashboard/index.js" /* webpackChunkName: "component---src-pages-dashboard-index-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-new-features-js": () => import("./../../../src/pages/new-features.js" /* webpackChunkName: "component---src-pages-new-features-js" */),
  "component---src-pages-our-features-js": () => import("./../../../src/pages/our-features.js" /* webpackChunkName: "component---src-pages-our-features-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-verify-change-email-js": () => import("./../../../src/pages/verify-change-email.js" /* webpackChunkName: "component---src-pages-verify-change-email-js" */),
  "component---src-pages-verify-email-js": () => import("./../../../src/pages/verify-email.js" /* webpackChunkName: "component---src-pages-verify-email-js" */)
}

