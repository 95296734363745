import React, { useRef, useEffect, createContext, useContext } from "react"
import moment from "moment"
import { useInput } from "@src/Helper"
import { getDashboard, getInitialData } from "@http/DashboardRepository"

const DashboardContext = createContext(null)

const DashboardContextProvider = ({ children }) => {
  // Hooks
  const isMounted = useRef(false)
  const dashboardData = useInput({})
  const personalize = useInput({})
  const vanillaLinks = useInput([])
  const socialMediaLinks = useInput([])
  const feedCategories = useInput([
    {
      id: 0,
      name: "Semua Post",
      is_active: true,
    },
  ])
  const postCount = useInput(0)
  const postList = useInput({
    posts: [],
    filteredPosts: [],
    isLoading: false,
  })
  const types = useInput([])
  const themes = useInput([])
  const coverImages = useInput([])
  const isLoadingDashboard = useInput(false)
  const isLoadingFeed = useInput(false)
  const isLoadingPost = useInput(false)
  const isLoadingInitialData = useInput(false)
  const isFetchInitialData = useInput(false)
  const solidColor = useInput("#CD3232")
  const gradientColorOne = useInput("#CD3232")
  const gradientColorTwo = useInput("#3244CD")
  const currentSelection = useInput()
  const currentPersonalizationValue = useInput({
    title: null,
    description: null,
    working_hour_start_at: null,
    working_hour_end_at: null,
    settings: {
      will_show_working_hour: false,
    },
    pipehome_type_id: null,
  })
  const currentCreatePostValue = useInput({
    categories: [
      {
        id: 0,
        name: "Tidak Ada",
        is_active: true,
      },
    ],
    links: [
      {
        label: "",
        url: "",
      },
    ],
    name: "",
    caption: "",
    imageInput: "",
    pictureUrl: "",
    selectedCategory: 0,
    isHighlight: false,
  })
  const currentEditPostValue = useInput({
    categories: [
      {
        id: 0,
        name: "Tidak Ada",
        is_active: true,
      },
    ],
    links: [],
    id: 0,
    name: "",
    caption: "",
    imageInput: "",
    pictureUrl: "",
    selectedCategory: 0,
    isHighlight: false,
  })
  const currentStylingValue = useInput({
    settings: {
      font: {
        color: null,
      },
      post: {
        background_color: null,
        foreground_color: null,
      },
      theme: {
        name: "default",
        solid_color: {
          value: null,
        },
        gradient_color: {
          value: [],
        },
        background_type: null,
        background_image: {
          value: null,
        },
      },
      button: {
        type: "solid",
        shape: "rounded",
        background_color: null,
        foreground_color: null,
      },
    },
  })

  useEffect(() => {
    handleGetDashboard()
  }, [])

  useEffect(() => {
    if (isMounted.current) {
      if (isFetchInitialData.value) {
        handleGetInitialData()
      }
    } else isMounted.current = true
  }, [isFetchInitialData.value])

  // Custom Functions(
  const handleGetDashboard = () => {
    getDashboard()
      .then(res => {
        dashboardData.setValue(res.data)
        personalize.setValue(res.data.page)
        vanillaLinks.setValue(res.data.links.vanilla ?? [])
        socialMediaLinks.setValue(res.data.links.social ?? [])
        feedCategories.setValue(prevState => {
          return [...prevState, ...res.data.categories]
        })
        postCount.setValue(res.data.post_count)
        currentSelection.setValue(res.data.page.pipehome_cover_image_id)
        currentCreatePostValue.setValue(prevState => {
          return {
            ...prevState,
            categories: [...prevState.categories, ...res.data.categories],
          }
        })
        currentPersonalizationValue.setValue({
          title: res.data.page.title,
          feed_title: res.data.page.feed_title,
          description: res.data.page.description,
          working_hour_start_at: moment.unix(
            res.data.page.working_hour_start_at
          ),
          working_hour_end_at: moment.unix(res.data.page.working_hour_end_at),
          settings: {
            will_show_working_hour:
              res.data.page.settings.will_show_working_hour,
          },
          pipehome_type_id: res.data.page.pipehome_type_id,
        })
        currentStylingValue.setValue({
          settings: {
            font: {
              color: res.data.page.settings.font.color,
            },
            post: {
              background_color: res.data.page.settings.post.background_color,
              foreground_color: res.data.page.settings.post.foreground_color,
            },
            theme: {
              name: res.data.page.settings.theme.name,
              solid_color: {
                value: res.data.page.settings.theme.solid_color.value,
              },
              gradient_color: {
                value: res.data.page.settings.theme.gradient_color.value,
              },
              background_type: res.data.page.settings.theme.background_type,
              background_image: {
                value: res.data.page.settings.theme.background_image.value,
              },
            },
            button: {
              type: res.data.page.settings.button.type,
              shape: res.data.page.settings.button.shape,
              background_color: res.data.page.settings.button.background_color,
              foreground_color: res.data.page.settings.button.foreground_color,
            },
          },
        })
      })
      .catch(err => console.log(err.response))
  }

  const handleGetInitialData = () => {
    isLoadingInitialData.setValue(true)
    getInitialData(personalize.value.id)
      .then(res => {
        types.setValue(res.data.pipehome_types)
        themes.setValue(res.data.themes)
        coverImages.setValue(res.data.cover_images)
        isLoadingInitialData.setValue(false)
      })
      .catch(err => {
        console.log(err.response)
        isLoadingInitialData.setValue(false)
      })
  }

  return (
    <DashboardContext.Provider
      value={{
        dashboardData,
        personalize,
        vanillaLinks,
        socialMediaLinks,
        feedCategories,
        postCount,
        postList,
        types,
        themes,
        coverImages,
        isLoadingDashboard,
        isLoadingFeed,
        isLoadingPost,
        isLoadingInitialData,
        isFetchInitialData,
        solidColor,
        gradientColorOne,
        gradientColorTwo,
        currentSelection,
        currentPersonalizationValue,
        currentCreatePostValue,
        currentEditPostValue,
        currentStylingValue,
      }}
    >
      {children}
    </DashboardContext.Provider>
  )
}

const useDashboardContext = () => {
  return useContext(DashboardContext)
}

export { DashboardContextProvider, useDashboardContext }
