import React, { useRef } from "react"
import ChangeEmailInputEmail from "./ChangeEmailInputEmail"
import { useInput } from "@src/Helper"
import { changeEmail } from "@http/EmailPasswordRepository"
import { AlertSuccess } from "@components/utils/Alert"

const ChangeEmail = ({ currentEmail }) => {
  // Hooks
  const emailRef = useRef(null)
  const email = useInput("")
  const error = useInput("")
  const isLoading = useInput(false)
  const isSuccess = useInput(false)

  // Custom Function
  const handleChangeEmail = () => {
    isLoading.setValue(true)
    changeEmail(email.value)
      .then(() => {
        isLoading.setValue(false)
        isSuccess.setValue(true)
        email.setValue("")
        error.setValue("")
      })
      .catch(err => {
        isLoading.setValue(false)
        isSuccess.setValue(false)
        if (err.response.data.status_code === 422) {
          error.setValue(err.response.data.errors)
        }
      })
  }

  // JSX
  return (
    <div className="account-settings__layout__email">
      {isSuccess.value && (
        <AlertSuccess
          className="flex mx-0"
          text={
            <p className="flex-a-center body mb-0">
              Kami telah mengirimkan e-mail ke alamat e-mail baru kamu. Mohon
              konfirmasi untuk menyelesaikan perubahan.
            </p>
          }
        />
      )}
      <h2 className="account-settings__layout__email__title heading-5">
        Ubah Alamat E-mail
      </h2>
      <form
        className="account-settings__layout__email__form"
        onSubmit={e => {
          e.preventDefault()
          handleChangeEmail()
        }}
      >
        <div className="row">
          <ChangeEmailInputEmail
            emailRef={emailRef}
            email={email}
            error={error}
          />
        </div>
        <p className="account-settings__layout__email__form__current-email__info caption">
          Alamat E-mail Sekarang: {currentEmail.value}
        </p>
        <button
          type="submit"
          className="account-settings__layout__email__form__new-email__button button button-md button-primary"
          disabled={isLoading.value}
        >
          {isLoading.value ? (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            <>Ubah Alamat E-mail</>
          )}
        </button>
      </form>
    </div>
  )
}

export default ChangeEmail
