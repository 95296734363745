import React, { useState } from "react"
import { useDashboardContext } from "@services/DashboardContext"
import { detectMob } from "@src/Helper"
import HelpTooltip from "@components/utils/HelpTooltip"
import { ColorPickerModal } from "@components/utils/Modal"

const StylingButton = () => {
  // Hooks
  const { currentStylingValue } = useDashboardContext()
  const [isFgColorModal, setIsFgColorModal] = useState(false)
  const [isBgColorModal, setIsBgColorModal] = useState(false)

  // Variables
  const buttons = {
    types: [
      {
        name: "solid",
        shapes: ["square", "rounded", "capsule"],
      },
      {
        name: "hard-shadow",
        shapes: ["square", "rounded", "capsule"],
      },
      {
        name: "soft-shadow",
        shapes: ["square", "rounded", "capsule"],
      },
      {
        name: "outline",
        shapes: ["square", "rounded", "capsule"],
      },
      {
        name: "double-outline",
        shapes: ["square", "rounded", "capsule"],
      },
    ],
  }

  // Custom Functions
  const handleSelectButton = (type, shape) => {
    currentStylingValue.setValue(prevState => {
      return {
        ...prevState,
        settings: {
          ...prevState.settings,
          button: {
            ...prevState.settings.button,
            type: type,
            shape: shape,
          },
        },
      }
    })
  }

  const handleDeleteButtonColor = key => {
    currentStylingValue.setValue(prevState => {
      return {
        ...prevState,
        settings: {
          ...prevState.settings,
          button: {
            ...prevState.settings.button,
            [key]: null,
          },
        },
      }
    })
  }

  // JSX
  return (
    <>
      <ColorPickerModal
        show={isFgColorModal}
        onHide={() => setIsFgColorModal(false)}
        id={detectMob() ? "m-buttonFg" : "buttonFg"}
        className={`${detectMob() ? "modal-z-index" : ""} color-picker-modal`}
        title="Warna Font Button"
        size="sm"
        valueOne={
          currentStylingValue.value.settings.button?.foreground_color ??
          "#ffffff"
        }
        isGradient={false}
        fn={(currentStylingValue, color) => {
          currentStylingValue.setValue(prevState => {
            return {
              ...prevState,
              settings: {
                ...prevState.settings,
                button: {
                  ...prevState.settings.button,
                  foreground_color: color.value,
                },
              },
            }
          })
        }}
      />
      <ColorPickerModal
        show={isBgColorModal}
        onHide={() => setIsBgColorModal(false)}
        id={detectMob() ? "m-buttonBg" : "buttonBg"}
        className={`${detectMob() ? "modal-z-index" : ""} color-picker-modal`}
        title="Warna Button"
        size="sm"
        valueOne={
          currentStylingValue.value.settings.button?.background_color ??
          "#ffffff"
        }
        isGradient={false}
        fn={(currentStylingValue, color) => {
          currentStylingValue.setValue(prevState => {
            return {
              ...prevState,
              settings: {
                ...prevState.settings,
                button: {
                  ...prevState.settings.button,
                  background_color: color.value,
                },
              },
            }
          })
        }}
      />
      <section className={`styling__button ${detectMob() ? "px-0" : ""}`}>
        <h2 className="button--heading-6">
          <em>Button</em>
          <HelpTooltip>
            Ubah tampilan bentuk, warna background dan warna tulisan button
            bawaan dari Tema yang kamu pilih.
          </HelpTooltip>
        </h2>
        <div className="button__list row" role="list">
          {buttons.types.map((type, key) => (
            <>
              <h3 key={key} className="button__type--caption">
                {type.name.split("-").join(" ")}
              </h3>
              {type.shapes.map((shape, key) => (
                <div key={key} className="button__item" role="listitem">
                  <div
                    className={`button__border button__border--${type.name} ${
                      currentStylingValue.value.settings.button?.type ===
                        type.name &&
                      currentStylingValue.value.settings.button?.shape === shape
                        ? ""
                        : "d-none"
                    }`}
                  ></div>
                  <div
                    className={`button__item-container button__item-container--${
                      type.name
                    } button__item-container--${shape} ${
                      currentStylingValue.value.settings.button?.type ===
                        type.name &&
                      currentStylingValue.value.settings.button?.shape === shape
                        ? "selected-button"
                        : ""
                    }`}
                    role="button"
                    tabIndex="0"
                    onClick={() => handleSelectButton(type.name, shape)}
                    onKeyDown={e => {
                      if (e.key === "Enter") {
                        handleSelectButton(type.name, shape)
                      }
                    }}
                  >
                    <div
                      className={`${
                        currentStylingValue.value.settings.button?.type ===
                          type.name &&
                        currentStylingValue.value.settings.button?.shape ===
                          shape
                          ? "selected-button"
                          : ""
                      }`}
                    ></div>
                  </div>
                </div>
              ))}
            </>
          ))}
        </div>
        <div className={`button__container ${detectMob() ? "flex-col" : ""}`}>
          <div className="button__fg">
            <h3 className="button__fg--caption">
              Warna <em>Font Button</em>
            </h3>
            <div className="flex mt-2">
              <div
                className={`button__fg-selector ${
                  currentStylingValue.value.settings.button
                    ?.foreground_color === null
                    ? "border-dash__button"
                    : ""
                }`}
                onClick={() => setIsFgColorModal(true)}
              >
                {currentStylingValue.value.settings.button?.foreground_color ? (
                  <div
                    className="button__fg-placeholder"
                    style={{
                      background: currentStylingValue.value.settings.button
                        ?.foreground_color
                        ? currentStylingValue.value.settings.button
                            .foreground_color
                        : "",
                      border:
                        currentStylingValue.value.settings.button
                          ?.foreground_color === "#ffffff"
                          ? "1px solid #b9b9b9"
                          : "",
                    }}
                  ></div>
                ) : (
                  <span className="mdi mdi-palette text-black-30"></span>
                )}
              </div>
              <div
                className="button__fg-value--caption"
                onClick={() => setIsFgColorModal(true)}
              >
                {currentStylingValue.value.settings.button?.foreground_color
                  ? currentStylingValue.value.settings.button.foreground_color
                  : "Warna default"}
              </div>
              <button
                className="button__fg-delete-button"
                onClick={e => {
                  e.preventDefault()
                  handleDeleteButtonColor("foreground_color")
                }}
              >
                <span className="mdi mdi-trash-can mdi-24px"></span>
              </button>
            </div>
          </div>
          <div className={`button__bg ${detectMob() ? "ms-0 mt-3" : ""}`}>
            <h3 className="button__bg--caption">
              Warna <em>Button</em>
            </h3>
            <div className="flex mt-2">
              <div
                className={`button__bg-selector ${
                  currentStylingValue.value.settings.button
                    ?.background_color === null
                    ? "border-dash__button"
                    : ""
                }`}
                onClick={() => setIsBgColorModal(true)}
              >
                {currentStylingValue.value.settings.button?.background_color ? (
                  <div
                    className="button__bg-placeholder"
                    style={{
                      background: currentStylingValue.value.settings.button
                        ?.background_color
                        ? currentStylingValue.value.settings.button
                            .background_color
                        : "",
                      border:
                        currentStylingValue.value.settings.button
                          ?.background_color === "#ffffff"
                          ? "1px solid #b9b9b9"
                          : "",
                    }}
                  ></div>
                ) : (
                  <span className="mdi mdi-palette text-black-30"></span>
                )}
              </div>
              <div
                className="button__bg-value--caption"
                onClick={() => setIsBgColorModal(true)}
              >
                {currentStylingValue.value.settings.button?.background_color
                  ? currentStylingValue.value.settings.button.background_color
                  : "Warna default"}
              </div>
              <button
                className="button__bg-delete-button"
                onClick={e => {
                  e.preventDefault()
                  handleDeleteButtonColor("background_color")
                }}
              >
                <span className="mdi mdi-trash-can mdi-24px"></span>
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default StylingButton
