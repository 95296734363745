import { httpClient as client, getToken } from "./client"

export const changeEmail = email => {
  return new Promise((resolve, reject) => {
    client
      .post(
        "change-email",
        {
          email,
        },
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      )
      .then(res => resolve(res))
      .catch(err => reject(err))
  })
}

export const changePassword = (
  current_password,
  new_password,
  new_password_confirmation
) => {
  return new Promise((resolve, reject) => {
    client
      .post(
        "change-password",
        { current_password, new_password, new_password_confirmation },
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      )
      .then(res => resolve(res))
      .catch(err => reject(err))
  })
}

export const resendVerifyEmail = () => {
  return new Promise((resolve, reject) => {
    client
      .post(
        "resend-verify-email",
        {},
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      )
      .then(res => resolve(res))
      .catch(err => reject(err))
  })
}

export const verifyChangeEmail = token => {
  return new Promise((resolve, reject) => {
    client
      .post("verify-change-email", {
        token,
      })
      .then(res => resolve(res))
      .catch(err => reject(err))
  })
}

export const verifySignUpEmail = token => {
  return new Promise((resolve, reject) => {
    client
      .post("verify-sign-up-email", {
        token,
      })
      .then(res => resolve(res))
      .catch(err => reject(err))
  })
}
