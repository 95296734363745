import React, { useRef } from "react"
import { useDashboardContext } from "@services/DashboardContext"
import { toast } from "react-toastify"
import { detectMob } from "@src/Helper"
import Logo from "@images/mini-sociopipe.svg"

const MyPage = () => {
  // Hooks
  const textRef = useRef(null)
  const { dashboardData } = useDashboardContext()

  // Custom Functions
  const handleCopyToClipboard = () => {
    const clipboard = navigator.clipboard
    const text = `${process.env.PIPEHOME_URL}/${dashboardData.value.page?.username}`
    clipboard.writeText(text).then(() => toast.info("Tautan telah tersalin!"))
  }

  // JSX
  if (detectMob()) {
    return (
      <section id="m-myPage" className="my-page">
        <div className="my-page__browser">
          <div className="my-page__tab">
            <img src={Logo} width={15} alt="Browser Logo" />
            <p className="my-page__username--caption">
              {dashboardData.value.page?.username} | Pipehome
            </p>
          </div>
        </div>
        <div className="my-page__container">
          <div className="my-page__link">
            <a
              className="my-page__url--body"
              href={`${process.env.PIPEHOME_URL}/${dashboardData.value.page?.username}`}
              target="_blank"
              rel="noopener noreferrer"
              ref={textRef}
            >
              {process.env.PIPEHOME_URL}/{dashboardData.value.page?.username}
            </a>
            <span
              className="my-page__share-icon mdi mdi-share-variant"
              role="button"
              aria-label="Share Page"
              tabIndex="0"
              onClick={() => handleCopyToClipboard()}
              onKeyDown={e => {
                if (e.key === "Enter") handleCopyToClipboard()
              }}
            ></span>
          </div>
        </div>
      </section>
    )
  }

  return (
    <section className="my-page">
      <div className="my-page__browser">
        <div className="my-page__tab">
          <img src={Logo} width={15} alt="Browser Logo" />
          <p className="my-page__username--caption">
            {dashboardData.value.page?.username} | Pipehome
          </p>
        </div>
      </div>
      <div className="my-page__container">
        <div className="my-page__link">
          <a
            className="my-page__url--body"
            href={`${process.env.PIPEHOME_URL}/${dashboardData.value.page?.username}`}
            target="_blank"
            rel="noopener noreferrer"
            ref={textRef}
          >
            {process.env.PIPEHOME_URL}/{dashboardData.value.page?.username}
          </a>
        </div>
        <button
          className="my-page__share"
          type="button"
          onClick={() => handleCopyToClipboard()}
        >
          <span className="my-page__share-icon mdi mdi-share-variant"></span>{" "}
          Salin Link
        </button>
      </div>
    </section>
  )
}

export default MyPage
