import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { getDashboard } from "@http/DashboardRepository"

export const isSSR = typeof window == "undefined"

export const isProduction =
  process.env.NODE_ENV && process.env.NODE_ENV === "production"

export const detectMob = () => {
  if (!isSSR) {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
      // navigator.userAgent.match(/iPad/i) ||
    ) {
      return true
    } else {
      return false
    }
  }
}

export const detectIOS = () => {
  if (!isSSR) {
    if (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/iPad/i)
    ) {
      return true
    } else {
      return false
    }
  }
}

export const toggleStickyBottomNav = (stickyRef, positionY) => {
  if (stickyRef.current !== null) {
    if (window.pageYOffset > positionY) {
      stickyRef.current.classList.add("sticky-bottom")
    } else {
      stickyRef.current.classList.remove("sticky-bottom")
    }
  }
}

export const handleOpenSidenav = (ref, className, fn) => {
  const body = document.querySelector("body")
  const mobileBackdrop = document.querySelector(".mobile-backdrop")
  const rtf = document.querySelector(".rtf")
  const headerRight = document.querySelectorAll(".layout__header--right")

  if (ref.current) {
    if (detectMob()) {
      if (ref.current.classList.contains(`${className}--slide-down`)) {
        body.classList.remove("overflow-hidden")
        mobileBackdrop.classList.remove("mobile-backdrop--show")
        rtf.classList.add("closed")
        rtf.classList.remove("open")
        headerRight.forEach(header => {
          header.classList.add("layout__header--fixed-bottom")
        })
        ref.current.classList.add(`${className}--slide-up`)
        ref.current.classList.remove(`${className}--slide-down`)
        if (fn !== undefined) fn()
      }
    } else {
      if (ref.current.classList.contains(`${className}--expand`)) {
        ref.current.classList.add(`${className}--expanded`)
        ref.current.classList.remove(`${className}--expand`)
        document.querySelector("body").classList.add("overflow-hidden")
        document.querySelector(".backdrop").classList.add("backdrop--show")
        document.querySelector(".sidenav").style.zIndex = 990
        if (fn !== undefined) fn()
      }
    }
  }
}

export const handleCloseSidenav = (ref, className, fn) => {
  const headerRight = document.querySelectorAll(".layout__header--right")

  if (ref.current) {
    if (detectMob()) {
      if (ref.current.classList.contains(`${className}--slide-up`)) {
        ref.current.classList.add(`${className}--slide-down`)
        ref.current.classList.remove(`${className}--slide-up`)
        headerRight.forEach(header => {
          header.classList.remove("layout__header--fixed-bottom")
        })
        if (fn !== undefined) fn()
      }
    } else {
      if (ref.current.classList.contains(`${className}--expanded`)) {
        ref.current.classList.add(`${className}--expand`)
        ref.current.classList.remove(`${className}--expanded`)
        document.querySelector("body").classList.remove("overflow-hidden")
        document.querySelector(".backdrop").classList.remove("backdrop--show")
        document.querySelector(".sidenav").style.zIndex = 996
        if (fn !== undefined) fn()
      }
    }
  }
}

export const handleToggleMobileBackdrop = () => {
  const body = document.querySelector("body")
  const mobileBackdrop = document.querySelector(".mobile-backdrop")
  const rtf = document.querySelector(".rtf")

  if (body.classList.contains("overflow-hidden")) {
    body.classList.remove("overflow-hidden")
  } else {
    body.classList.add("overflow-hidden")
  }

  if (mobileBackdrop.classList.contains("mobile-backdrop--show")) {
    mobileBackdrop.classList.remove("mobile-backdrop--show")
  } else {
    mobileBackdrop.classList.add("mobile-backdrop--show")
  }

  if (rtf.classList.contains("closed")) {
    rtf.classList.remove("closed")
    rtf.classList.add("open")
  } else {
    rtf.classList.add("closed")
    rtf.classList.remove("open")
  }
}

export const handleSelectCropFile = (e, errorMsg, modalState, upImgState) => {
  const ImgSizeErrorMsg = ({ errorMsg }) => {
    return <>{errorMsg}</>
  }

  if (e.target.files[0]?.size > 5120000) {
    toast.error(<ImgSizeErrorMsg errorMsg={errorMsg} />)
  } else {
    if (e.target.files && e.target.files.length > 0) {
      modalState.setValue(true)
      const reader = new FileReader()
      reader.addEventListener("load", () => upImgState.setValue(reader.result))
      reader.readAsDataURL(e.target.files[0])
    }
  }
}

export const handleLink = (id, data, value, property, state) => {
  let datum = { ...data[id] }
  datum[property] = value
  data[id] = datum
  state.setValue(data)
}

export const handleAddHttpPrefix = (
  id,
  data,
  value,
  property,
  state,
  isSocialLink
) => {
  let willAppendHttp = false
  if (value !== "" && !value.startsWith("http")) {
    willAppendHttp = true
    if (isSocialLink && id === 0) willAppendHttp = false
  }
  if (willAppendHttp) value = `http://${value}`

  let datum = { ...data[id] }
  datum[property] = value
  data[id] = datum
  state.setValue(data)
}

export const handleFocus = ref => {
  ref.current.focus()
}

export const handleGetDashboard = (
  personalize,
  socialMediaLinks,
  vanillaLinks,
  isLoading
) => {
  isLoading.setValue(true)

  getDashboard()
    .then(res => {
      personalize?.value && personalize.setValue(res.data.page)
      socialMediaLinks?.value &&
        socialMediaLinks.setValue(res.data.links.social)
      if (res.data.links?.vanilla) {
        vanillaLinks?.value && vanillaLinks.setValue(res.data.links.vanilla)
      }
      isLoading.setValue(false)
    })
    .catch(err => {
      console.log(err.response)
      isLoading.setValue(false)
    })
}

export const isEmptyObject = (state, stateKey) => {
  if (
    stateKey === null ||
    (Object.keys(state).length === 0 && state.constructor === Object)
  ) {
    return true
  } else {
    return false
  }
}

export const isInputEmpty = input => {
  if (input.current.value.length !== 0) {
    input.current.classList.add("filled")
  } else {
    input.current.classList.remove("filled")
  }
}

export const isPasswordVisible = (password, eye) => {
  if (password.current.type === "password") {
    password.current.type = "text"
    eye.current.classList.remove("mdi-eye-off")
    eye.current.classList.add("mdi-eye")
  } else if (password.current.type === "text") {
    password.current.type = "password"
    eye.current.classList.remove("mdi-eye")
    eye.current.classList.add("mdi-eye-off")
  }
}

export const useInput = defaultValue => {
  const [value, setValue] = useState(defaultValue)

  const handleInput = val => {
    setValue(val)
  }

  return {
    value,
    setValue: handleInput,
  }
}

export const useIsClient = () => {
  const [isClient, setIsClient] = useState(false)
  const key = isClient ? "client" : "server"

  useEffect(() => {
    setIsClient(true)
  }, [])

  return { isClient, key }
}

export const ClientOnly = ({ children }) => {
  const [hasMounted, setHasMounted] = useState(false)
  useEffect(() => {
    setHasMounted(true)
  }, [])

  if (!hasMounted) {
    return null
  }
  return <>{children}</>
}
