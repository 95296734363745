import React, { useState, useCallback, useRef } from "react"
import ReactCrop from "react-image-crop"

const Cropper = ({
  upImg,
  handleOnClick,
  aspectRatio,
  isCircularCrop,
  isDisabled,
}) => {
  const imgRef = useRef(null)
  const [crop, setCrop] = useState({
    unit: "%",
    width: 15,
    aspect: aspectRatio,
  })
  const [croppedImageUrl, setCroppedImageUrl] = useState(null)
  const [uploadFile, setUploadFile] = useState(null)

  const onLoad = useCallback(img => {
    imgRef.current = img
  }, [])

  const onCropComplete = crop => {
    makeClientCrop(crop)
  }

  const makeClientCrop = async crop => {
    if (imgRef.current && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(
        imgRef.current,
        crop,
        "newFile.jpeg"
      )
      setCroppedImageUrl(croppedImageUrl)
    }
  }

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement("canvas")
    const pixelRatio = window.devicePixelRatio
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const ctx = canvas.getContext("2d")

    canvas.width = crop.width * pixelRatio * scaleX
    canvas.height = crop.height * pixelRatio * scaleY

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.imageSmoothingQuality = "high"

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    )

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        blob => {
          if (!blob) {
            console.error("Canvas is empty")
            return
          }
          blob.name = fileName
          window.URL.revokeObjectURL(blob.fileUrl)
          blob.fileUrl = window.URL.createObjectURL(blob)
          const newImage = new File([blob], fileName, {
            type: blob.type,
          })
          setUploadFile(newImage)
          resolve(blob.fileUrl)
        },
        "image/jpeg",
        1
      )
    })
  }

  return (
    <>
      <div className="image-modal__crop-container">
        <ReactCrop
          src={upImg}
          onImageLoaded={onLoad}
          crop={crop}
          circularCrop={isCircularCrop}
          onChange={c => setCrop(c)}
          onComplete={c => onCropComplete(c)}
        />
      </div>
      <div className="image-modal__button-container">
        <button
          className="image-modal__button button button-lg button-primary"
          disabled={isDisabled}
          onClick={() => handleOnClick(uploadFile)}
        >
          {isDisabled ? (
            <span
              className="spinner-border spinner-border-sm ms-1"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            <>Simpan</>
          )}
        </button>
      </div>
    </>
  )
}

export default Cropper
