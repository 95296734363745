import React, { useEffect, useRef } from "react"
import { useDashboardContext } from "@services/DashboardContext"
import { getPosts } from "@http/DashboardRepository"
import { detectMob, handleCloseSidenav } from "@src/Helper"
import { DashboardSkeletonLoader } from "@components/utils/SkeletonLoader"
import CreatePostLayout from "./Post/CreatePostLayout"
import EditPostLayout from "./Post/EditPostLayout"
import FeedTitle from "./FeedTitle"
import FeedCategory from "./FeedCategory"
import FeedPost from "./FeedPost"
import IconAddPost from "@images/dashboard/icons/add-collection-icon.svg"

const FeedLayout = React.forwardRef((props, ref) => {
  // Hooks
  const {
    personalize,
    feedCategories,
    isLoadingFeed,
    postCount,
    postList,
    currentCreatePostValue,
    currentEditPostValue,
  } = useDashboardContext()
  const isMounted = useRef(false)
  const createPostLayoutRef = useRef(null)
  const editPostLayoutRef = useRef(null)
  const feedBackdrop = useRef(null)

  useEffect(() => {
    const data = feedCategories.value.map(category => {
      if (category.id !== 0) {
        category.is_active = false
      } else {
        category.is_active = true
      }
      return category
    })
    feedCategories.setValue(data)
    handleGetPosts(0)
  }, [])

  useEffect(() => {
    if (isMounted.current) {
      if (isLoadingFeed.value) {
        handleGetPosts(0)
      }
    } else isMounted.current = true
  }, [isLoadingFeed.value])

  // Variable
  const postCategory = feedCategories.value.filter(
    category => category.is_active === true
  )

  // Custom Function
  const handleGetPosts = id => {
    postList.setValue(prevState => {
      return {
        ...prevState,
        isLoading: true,
      }
    })
    getPosts(personalize.value.id, id)
      .then(res => {
        postList.setValue(prevState => {
          return {
            ...prevState,
            posts: res.data.posts,
            filteredPosts: res.data.posts,
            isLoading: false,
          }
        })
        postCount.setValue(res.data.posts.length)
        isLoadingFeed.setValue(false)
      })
      .catch(err => {
        console.log(err.response)
        isLoadingFeed.setValue(false)
      })
  }

  const handleSetActive = idx => {
    const data = feedCategories.value.map(category => {
      category.is_active = false
      return category
    })
    data[idx].is_active = true
    feedCategories.setValue([...data])
  }

  const handleOpenPostLayout = (postRef, className) => {
    if (postRef.current && feedBackdrop.current) {
      if (detectMob()) {
        if (
          postRef.current.classList.contains(`${className}__layout--slide-down`)
        ) {
          postRef.current.classList.add(`${className}__layout--slide-up`)
          postRef.current.classList.remove(`${className}__layout--slide-down`)
          document
            .querySelector(`.${className}__header--right`)
            .classList.add("layout__header--fixed-bottom")
        }
      } else {
        if (
          postRef.current.classList.contains(`${className}__layout--expand`)
        ) {
          postRef.current.classList.add(`${className}__layout--expanded`)
          postRef.current.classList.remove(`${className}__layout--expand`)
          ref.current.classList.add(
            "feed__layout--double-expanded",
            "black-90-bg"
          )
          feedBackdrop.current.classList.add("backdrop--show")
        }
      }
    }
  }

  const handleCloseBackdrop = layoutName => {
    currentCreatePostValue.setValue(prevState => {
      return {
        ...prevState,
        links: [
          {
            label: "",
            url: "",
          },
        ],
        name: "",
        caption: "",
        imageInput: "",
        pictureUrl: "",
        selectedCategory: 0,
        isHighlight: false,
      }
    })
    currentEditPostValue.setValue(prevState => {
      return {
        ...prevState,
        categories: [
          {
            id: 0,
            name: "Tidak Ada",
            is_active: true,
          },
        ],
        links: [],
        id: 0,
        name: "",
        caption: "",
        imageInput: "",
        pictureUrl: "",
        selectedCategory: 0,
        isHighlight: false,
      }
    })
    if (feedBackdrop.current && ref.current && editPostLayoutRef.current) {
      if (feedBackdrop.current.classList.contains("backdrop--show")) {
        feedBackdrop.current.classList.remove("backdrop--show")
        document
          .querySelector(".create-post__image-placeholder")
          .classList.remove("image-filled")
        layoutName.map(layout => {
          const name = document.querySelector(`.${layout}__layout`)
          name.classList.add(`${layout}__layout--expand`)
          name.classList.remove(`${layout}__layout--expanded`)
          ref.current.classList.remove(
            "feed__layout--double-expanded",
            "black-90-bg"
          )
          feedBackdrop.current.classList.remove("backdrop--show")
        })
      }
    }
  }

  // JSX
  return (
    <>
      <div
        ref={ref}
        className={`customization__layout feed__layout ${
          detectMob() ? "feed__layout--slide-down" : "feed__layout--expand"
        }`}
      >
        <div
          ref={feedBackdrop}
          className="backdrop"
          onClick={() => handleCloseBackdrop(["create-post", "edit-post"])}
        ></div>
        <div className="feed__header">
          <div className="feed__header--left">
            <span
              className="mdi mdi-close mdi-24px"
              onClick={() => handleCloseSidenav(ref, "feed__layout")}
            ></span>
            <h2 className="feed__header-title--heading-5">
              <em>Feed</em>
            </h2>
          </div>
        </div>
        <div className="feed__body">
          <div className="container">
            <FeedTitle />
            <div
              className={`category-list ${detectMob() ? "px-0" : ""}`}
              role="list"
            >
              {feedCategories.value?.map((categoryItem, key) => {
                return (
                  <FeedCategory
                    categoryItem={categoryItem}
                    idx={key}
                    handleSetActive={handleSetActive}
                  />
                )
              })}
            </div>
            <h3
              className={`post__category-title--heading-5 ${
                detectMob() ? "px-0" : ""
              }`}
            >
              {postCategory.map(category => category.name)}
            </h3>
            <div
              className={`post-list ${detectMob() ? "px-0" : ""}`}
              role="list"
            >
              <div
                className="post__add--caption"
                role="button"
                tabIndex="0"
                onClick={() =>
                  handleOpenPostLayout(createPostLayoutRef, "create-post")
                }
              >
                <img
                  className="post__add-icon"
                  src={IconAddPost}
                  alt="Add New Collection"
                />
                Tambah
                <br /> Post Baru
              </div>
              {postList.value.isLoading ? (
                <DashboardSkeletonLoader
                  height={detectMob() ? 130 : 163}
                  quantities={detectMob() ? 5 : 3}
                  col={3}
                  className={`skeleton-post-container ${
                    detectMob() ? "mt-2" : "mt-3 me-3"
                  }`}
                  style={{
                    width: detectMob() ? "47.5%" : "20%",
                    marginRight: detectMob() && "2.5%",
                  }}
                />
              ) : (
                postList.value.filteredPosts?.map((post, key) => (
                  <FeedPost
                    post={post}
                    idx={key}
                    handleOpenPostLayout={handleOpenPostLayout}
                    editPostLayoutRef={editPostLayoutRef}
                  />
                ))
              )}
            </div>
          </div>
        </div>
        <CreatePostLayout postLayoutRef={createPostLayoutRef} />
        <EditPostLayout postLayoutRef={editPostLayoutRef} />
      </div>
    </>
  )
})

export default FeedLayout
