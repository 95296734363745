import React from "react"
import { detectMob, useInput } from "@src/Helper"
import HelpTooltip from "@components/utils/HelpTooltip"
import AnalyticsLinkItem from "../AnalyticsLinkItem"
import { LinksLoader } from "@components/utils/SkeletonLoader"

const VanillaLink = ({ links, isLoadingSkeleton }) => {
  // Hooks
  const isShowMore = useInput(false)

  // JSX
  if (detectMob()) {
    return (
      <div className="analytics__layout__link">
        <div className="analytics__layout__link__header">
          <h3 className="analytics__layout__link__title heading-5">
            Total Klik Link{" "}
            <HelpTooltip>
              Menunjukkan total klik pada link di pipehome kamu dalam rentang
              waktu 24 jam.
            </HelpTooltip>
          </h3>
        </div>

        {(!links.value.vanilla || links.value.vanilla?.length === 0) && (
          <p className="body mb-0 text-black-30">
            Data analitik tidak ditemukan pada tanggal yang ditentukan.
          </p>
        )}

        {isShowMore.value
          ? links.value.vanilla?.map((link, key) => {
              return (
                <AnalyticsLinkItem
                  label={link.label}
                  url={link.url}
                  clickCount={link.analytics_count}
                />
              )
            })
          : links.value.vanilla?.slice(0, 3).map((link, key) => {
              return (
                <AnalyticsLinkItem
                  label={link.label}
                  url={link.url}
                  clickCount={link.analytics_count}
                />
              )
            })}

        {links.value.vanilla?.length > 3 &&
          (isShowMore.value ? (
            <span
              role="button"
              tabIndex="0"
              className="analytics__layout__link__view-less"
              onClick={() => isShowMore.setValue(false)}
              onKeyDown={e => e.key === "Enter" && isShowMore.setValue(false)}
            >
              Sembunyikan
            </span>
          ) : (
            <span
              role="button"
              tabIndex="0"
              className="analytics__layout__link__view-more"
              onClick={() => isShowMore.setValue(true)}
              onKeyDown={e => e.key === "Enter" && isShowMore.setValue(true)}
            >
              Lihat Semua
            </span>
          ))}
      </div>
    )
  }

  return (
    <div className="analytics__layout__link">
      <div className="analytics__layout__link__header">
        <h3 className="analytics__layout__link__title heading-5">
          Total Klik Link{" "}
          <HelpTooltip>
            Menunjukkan total klik pada link di pipehome kamu dalam rentang
            waktu 24 jam.
          </HelpTooltip>
        </h3>
        {links.value.vanilla?.length > 3 &&
          (isShowMore.value ? (
            <span
              role="button"
              tabIndex="0"
              className="analytics__layout__link__view-less"
              onClick={() => isShowMore.setValue(false)}
              onKeyDown={e => e.key === "Enter" && isShowMore.setValue(false)}
            >
              Sembunyikan
            </span>
          ) : (
            <span
              role="button"
              tabIndex="0"
              className="analytics__layout__link__view-more"
              onClick={() => isShowMore.setValue(true)}
              onKeyDown={e => e.key === "Enter" && isShowMore.setValue(true)}
            >
              Lihat Semua
            </span>
          ))}
      </div>

      {((!isLoadingSkeleton.value && !links.value.vanilla) ||
        links.value.vanilla?.length === 0) && (
        <p className="body mb-0 text-black-30">
          Data analitik tidak ditemukan pada tanggal yang ditentukan.
        </p>
      )}

      {isLoadingSkeleton.value ? (
        <LinksLoader className={"mt-2 mb-1"} quantities={3} />
      ) : isShowMore.value ? (
        links.value.vanilla?.map((link, key) => {
          return (
            <AnalyticsLinkItem
              label={link.label}
              url={link.url}
              clickCount={link.analytics_count}
            />
          )
        })
      ) : (
        links.value.vanilla?.slice(0, 3).map((link, key) => {
          return (
            <AnalyticsLinkItem
              label={link.label}
              url={link.url}
              clickCount={link.analytics_count}
            />
          )
        })
      )}
    </div>
  )
}

export default VanillaLink
