import React, { createContext, useContext } from "react"
import { useInput } from "@src/Helper"

const AnalyticsContext = createContext(null)

const AnalyticsContextProvider = ({ children }) => {
  const startDate = useInput(new Date(Date.now() - 6 * 24 * 60 * 60 * 1000))
  const endDate = useInput(new Date())

  return (
    <AnalyticsContext.Provider value={{ startDate, endDate }}>
      {children}
    </AnalyticsContext.Provider>
  )
}

const useAnalyticsContext = () => {
  return useContext(AnalyticsContext)
}

export { AnalyticsContextProvider, useAnalyticsContext }
