import React, { useRef } from "react"
import { Link, navigate } from "gatsby"
import { signOut } from "@http/AuthRepository"
import { removeToken, removeUserData } from "@http/client"
import { useInput } from "@src/Helper"

const Sidenav = () => {
  // Hooks
  const isLoading = useInput(false)
  const sidenav = useRef(null)

  // Custom Functions
  const handleSignOut = () => {
    isLoading.setValue(true)
    signOut()
      .then(() => {
        removeToken()
        removeUserData()
        handleExpandSidenav()
        navigate("/")
      })
      .catch(err => {
        console.log(err)
      })
  }

  const handleExpandSidenav = () => {
    if (sidenav.current) {
      if (sidenav.current.classList.contains("sidenav--expanded")) {
        sidenav.current.classList.add("sidenav--expand")
        sidenav.current.classList.remove("sidenav--expanded")
        document.querySelector("body").classList.remove("overflow-hidden")
        document.querySelector(".backdrop").classList.remove("backdrop--show")
      } else {
        sidenav.current.classList.add("sidenav--expanded")
        sidenav.current.classList.remove("sidenav--expand")
        document.querySelector("body").classList.add("overflow-hidden")
        document.querySelector(".backdrop").classList.add("backdrop--show")
      }
    }
  }

  // JSX
  return (
    <section ref={sidenav} className="sidenav sidenav--expand">
      <nav className="sidenav__navigation">
        <ul className="sidenav__list">
          <li className="sidenav__item">
            <Link
              className="sidenav__link--body"
              activeClassName="sidenav__link--active"
              onClick={() => handleExpandSidenav()}
              partiallyActive={true}
              to="/dashboard"
            >
              <span className="mdi mdi-24px mdi-home"></span> Dasbor
            </Link>
          </li>
          <li className="sidenav__item">
            <Link
              className="sidenav__link--body"
              activeClassName="sidenav__link--active"
              onClick={() => handleExpandSidenav()}
              to="/analytics"
            >
              <span className="mdi mdi-24px mdi-google-analytics"></span>{" "}
              Analitik
            </Link>
          </li>
          <li className="sidenav__item">
            <Link
              className="sidenav__link--body"
              activeClassName="sidenav__link--active"
              onClick={() => handleExpandSidenav()}
              to="/account-settings"
            >
              <span className="mdi mdi-24px mdi-account-cog"></span> Pengaturan
              Akun
            </Link>
          </li>
          <div className="sidenav--seperator"></div>
          <li className="sidenav__item">
            <Link
              className="sidenav__link--body"
              activeClassName="sidenav__link--active"
              onClick={() => handleExpandSidenav()}
              to="/new-features"
            >
              <span className="mdi mdi-24px mdi-update"></span> What's New
            </Link>
          </li>
          <li className="sidenav__item">
            <Link
              className="sidenav__link--body"
              activeClassName="sidenav__link--active"
              onClick={() => handleExpandSidenav()}
              to="/our-features"
            >
              <span className="mdi mdi-24px mdi-information"></span> Apa itu
              Pipehome?
            </Link>
          </li>
          <li className="sidenav__item">
            <Link
              className="sidenav__link--body"
              activeClassName="sidenav__link--active"
              onClick={() => handleExpandSidenav()}
              to="/contact-us"
            >
              <span className="mdi mdi-24px mdi-help-circle"></span> Hubungi
              Kami
            </Link>
          </li>
          <li className="sidenav__item">
            <Link
              className="sidenav__link--body"
              activeClassName="sidenav__link--active"
              onClick={() => handleExpandSidenav()}
              to="/privacy-policy"
            >
              <span className="mdi mdi-24px mdi-file-document"></span> Kebijakan
              Privasi
            </Link>
          </li>
          <li className="sidenav__item">
            <Link
              className="sidenav__link--body"
              activeClassName="sidenav__link--active"
              onClick={() => handleExpandSidenav()}
              to="/terms-and-conditions"
            >
              <span className="mdi mdi-24px mdi-file-document"></span> Syarat
              &amp; Ketentuan
            </Link>
          </li>
          <li className="sidenav__item">
            <div
              role="button"
              tabIndex="0"
              className="sidenav__link--body"
              to="/"
              onClick={() => {
                handleSignOut()
              }}
              onKeyDown={e => {
                if (e.key === "Enter") {
                  handleSignOut()
                }
              }}
            >
              {isLoading.value ? (
                <span className="spinner-border spinner-border-sm ms-1 border-2"></span>
              ) : (
                <span className="mdi mdi-24px mdi-logout d-flex"></span>
              )}
              Keluar
            </div>
          </li>
        </ul>
      </nav>
      <div className="sidenav__button-container">
        <button
          className="sidenav__button"
          onClick={() => handleExpandSidenav()}
        >
          <span className="mdi mdi-24px mdi-chevron-right"></span>
        </button>
      </div>
    </section>
  )
}

export default Sidenav
