import React, { useRef } from "react"
import { createUseStyles } from "react-jss"
import isDarkColor from "is-dark-color"
import { LightenDarkenColor } from "lighten-darken-color"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import tz from "dayjs/plugin/timezone"
import advanced from "dayjs/plugin/advancedFormat"
import { useDashboardContext } from "@services/DashboardContext"
import {
  useInput,
  handleSelectCropFile,
  handleOpenSidenav,
  detectMob,
} from "@src/Helper"
import PersonalizationLayout from "./PersonalizationLayout"
import ProfilePictureModal from "./ProfilePictureCropper"
import DefaultPP from "@images/dashboard/img/default-profile-picture.png"

const useStyles = createUseStyles({
  buttonStyle: props => {
    const isMonochrome =
      props.button.foreground_color === props.button.background_color // to check if button has the same color as its text
    const isCustom =
      props.button.foreground_color !== null &&
      props.button.background_color !== null // to check if foreground color and background color is customized or not
    const isDark = isCustom
      ? isDarkColor(props.button.foreground_color) // for light/dark color detection
      : null
    const colorConstant = 150 // to determine how dark/light the color should be darken/lighten
    const bgColorConstant = 30 // to determine how dark/light the background color should be darken/lighten
    const ifBackgroundNull = props.button.foreground_color // to check if foreground color of a button is customized or following the theme
      ? LightenDarkenColor(
          props.button.foreground_color,
          isDark ? bgColorConstant : bgColorConstant * -1
        )
      : ""
    const ifForegroundNull = props.button.background_color // to check if background color of a button is customized or following the theme
      ? LightenDarkenColor(
          props.button.background_color,
          isDark ? bgColorConstant : bgColorConstant * -1
        )
      : ""

    if (!isMonochrome) {
      if (props.button?.background_color && props.button?.foreground_color) {
        return {
          borderColor: [[props.button.foreground_color], "!important"],
          background: [props.button.foreground_color, "!important"],
          color: [props.button.background_color, "!important"],
          "&:hover": {
            borderColor: `${
              props.button.background_color || ifBackgroundNull
            } !important`,
            background: `${
              props.button.background_color || ifBackgroundNull
            } !important`,
            color: `${
              props.button.foreground_color || ifForegroundNull
            } !important`,
          },
        }
      } else if (props.button?.background_color) {
        return {
          borderColor: "initial",
          background: "initial",
          color: [props.button.background_color, "!important"],
          "&:hover": {
            borderColor: `${
              props.button.background_color || ifBackgroundNull
            } !important`,
            background: `${
              props.button.background_color || ifBackgroundNull
            } !important`,
            color: `${
              props.button.foreground_color || ifForegroundNull
            } !important`,
          },
        }
      } else if (props.button?.foreground_color) {
        return {
          borderColor: [[props.button.foreground_color], "!important"],
          background: [props.button.foreground_color, "!important"],
          color: "initial",
          "&:hover": {
            borderColor: `${
              props.button.background_color || ifBackgroundNull
            } !important`,
            background: `${
              props.button.background_color || ifBackgroundNull
            } !important`,
            color: `${
              props.button.foreground_color || ifForegroundNull
            } !important`,
          },
        }
      }
    } else if (
      props.button.background_color === null &&
      props.button.foreground_color === null
    ) {
      return {
        borderColor: "initial",
        background: "initial",
        color: "initial",
        "&:hover": {
          borderColor: "initial",
          background: "initial",
          color: "initial",
        },
      }
    } else {
      return {
        borderColor: [[props.button.background_color], "!important"],
        background: [props.button.background_color, "!important"],
        color: props.button.foreground_color && [
          `${LightenDarkenColor(
            props.button.foreground_color,
            isDark ? colorConstant : colorConstant * -1
          )}`,
          "!important",
        ],
        "&:hover": {
          background: props.button.background_color && [
            `${LightenDarkenColor(
              props.button.background_color,
              isDark ? bgColorConstant : bgColorConstant * -1
            )}`,
            "!important",
          ],
          color: props.button.foreground_color && [
            `${LightenDarkenColor(
              props.button.foreground_color,
              isDark ? colorConstant : colorConstant * -1
            )}`,
            "!important",
          ],
        },
      }
    }
  },
  textStyle: {
    color: props => [`${props.font.color}`, "!important"],
  },
  activeHourStyle: props => {
    const isDark = props.font.color
      ? isDarkColor(props.font.color) // for light/dark color detection
      : null
    const bgColorConstant = 150

    return {
      backgroundColor: [`${props.font.color}`, "!important"],
      borderColor: props.font.color && [
        [
          `${LightenDarkenColor(
            props.font.color,
            isDark ? bgColorConstant : bgColorConstant * -0.5
          )}`,
        ],
        "!important",
      ],
      color: props.font.color && [
        `${LightenDarkenColor(
          props.font.color,
          isDark ? bgColorConstant : bgColorConstant * -0.5
        )}`,
        "!important",
      ],
    }
  },
})

const Personalization = React.forwardRef((props, ref) => {
  dayjs.extend(utc)
  dayjs.extend(tz)
  dayjs.extend(advanced)

  // Hooks
  const { personalize, types, isFetchInitialData } = useDashboardContext()
  const inputFile = useRef(null)
  const upImg = useInput()
  const isModalOpen = useInput(false)

  const classes = useStyles(personalize.value.settings)

  // Variables
  const startHour = dayjs
    .unix(personalize?.value.working_hour_start_at)
    .format("HH")
  const startMinute = dayjs
    .unix(personalize?.value.working_hour_start_at)
    .format("mm")
  const endHour = dayjs
    .unix(personalize?.value.working_hour_end_at)
    .format("HH")
  const endMinute = dayjs
    .unix(personalize?.value.working_hour_end_at)
    .format("mm")
  const timeZone = dayjs.tz.guess()
  const timeZoneAbbr = dayjs().tz(timeZone).format("z")

  // Custom Function
  const checkIsFetchInitialData = () => {
    if (types.value.length === 0) {
      isFetchInitialData.setValue(true)
    }
  }

  const handleTriggerInput = () => {
    inputFile.current.click()
  }

  // JSX
  return (
    <section
      id={detectMob() ? "m-personalization" : ""}
      className={`personalization ${detectMob() ? "container" : ""}`}
    >
      <div className="profile-picture">
        <ProfilePictureModal
          show={isModalOpen.value}
          onHide={() => isModalOpen.setValue(false)}
          upImg={upImg.value}
        />
        <span
          className="profile-picture__edit-icon mdi mdi-camera"
          role="button"
          tabIndex="0"
          onClick={() => handleTriggerInput()}
        ></span>
        <input
          className="profile-picture__edit-input"
          ref={inputFile}
          type="file"
          accept="image/png, image/jpeg"
          onChange={e =>
            handleSelectCropFile(
              e,
              ["Maksimum ukuran gambar profil adalah 5MB."],
              isModalOpen,
              upImg
            )
          }
          onClick={e => (e.target.value = null)}
        />
        <div className="profile-picture__placeholder-container">
          <img
            className="profile-picture__placeholder"
            src={
              personalize?.value.profile_picture_url
                ? personalize.value.profile_picture_url
                : DefaultPP
            }
            alt={personalize?.value.username}
            onError={e => {
              e.target.onerror = null
              e.target.src = DefaultPP
            }}
          />
        </div>
      </div>
      <button
        className={`ph-button profile-share ${classes.buttonStyle}`}
        type="button"
      >
        <span className="profile-share__icon mdi mdi-share-variant"></span>{" "}
        Share
      </button>
      <div className="profile-about">
        <div className="profile-title">
          <h1
            className={`profile-title--heading-5 ${
              personalize.value.settings.font?.color ? classes.textStyle : ""
            }`}
          >
            {personalize?.value.title
              ? personalize.value.title
              : "Pipehome Title"}
          </h1>
        </div>
        <div className="profile-bio">
          <h2
            className={`profile-bio--body ${
              personalize.value.settings.font?.color ? classes.textStyle : ""
            }`}
          >
            {personalize?.value.description
              ? personalize.value.description
              : "Pipehome Bio"}
          </h2>
        </div>
        {!detectMob() && (
          <button
            className="profile-about__button"
            type="button"
            onClick={() =>
              handleOpenSidenav(
                ref,
                "personalization__layout",
                checkIsFetchInitialData
              )
            }
          >
            <span className="mdi mdi-pencil mdi-18px"></span>
          </button>
        )}
      </div>
      {personalize?.value.settings.will_show_working_hour && (
        <div
          className={`profile-active-hour ${
            personalize.value.settings.font?.color
              ? classes.activeHourStyle
              : ""
          }`}
        >
          <h2 className="profile-active-hour--body">
            Jam Aktif: {startHour}:{startMinute} - {endHour}:{endMinute}{" "}
            {timeZoneAbbr}
          </h2>
        </div>
      )}
      <PersonalizationLayout ref={ref} />
    </section>
  )
})

export default Personalization
