import React, { useEffect } from "react"
import { format } from "date-fns"
import id from "date-fns/locale/id"
import { detectMob, useInput } from "@src/Helper"
import { getAnalytics } from "@http/AnalyticsRepositories"
import { getDashboard } from "@http/DashboardRepository"
import { AlertInfo } from "@components/utils/Alert"
import Loading from "@components/utils/Loading"
import TotalVisit from "./TotalVisit/TotalVisit"
import MediaPostTopThree from "./MediaPost/MediaPostTopThree"
import VanillaLink from "./Link/VanillaLink"
import SocialLink from "./Link/SocialLink"
import AnalyticsFilterDate from "./AnalyticsFilterDate"
import { useAnalyticsContext } from "@services/AnalyticsContext"

const AnalyticsLayout = () => {
  // Hooks
  const pageId = useInput(null)
  const visitCount = useInput(0)
  const postsAnalytics = useInput([])
  const linksAnalytics = useInput({})
  const isPageLoading = useInput(true)
  const isButtonLoading = useInput(false)
  const isShowCalendar = useInput(false)
  const isLoadingSkeleton = useInput(false)
  const { startDate, endDate } = useAnalyticsContext()

  useEffect(() => {
    handleGetDashboard()
  }, [])

  useEffect(() => {
    handleGetAnalytics()
  }, [pageId.value])

  // Custom Functions
  const handleGetDashboard = () => {
    getDashboard()
      .then(res => {
        pageId.setValue(res.data.page.id)
      })
      .catch(err => console.log(err.response))
  }

  const handleGetAnalytics = () => {
    isButtonLoading.setValue(true)
    isLoadingSkeleton.setValue(true)
    if (pageId.value === null) return

    getAnalytics(pageId.value, startDate.value, endDate.value)
      .then(res => {
        visitCount.setValue(res.data.visit_count)
        postsAnalytics.setValue(res.data.posts)
        linksAnalytics.setValue(res.data.links)
        isPageLoading.setValue(false)
        isButtonLoading.setValue(false)
        isLoadingSkeleton.setValue(false)
        isShowCalendar.setValue(false)
      })
      .catch(err => {
        isPageLoading.setValue(false)
        isButtonLoading.setValue(false)
        isLoadingSkeleton.setValue(false)
        isShowCalendar.setValue(false)
        console.log(err)
      })
  }

  // JSX
  if (isPageLoading.value) {
    return <Loading />
  } else {
    if (detectMob()) {
      return (
        <section className="analytics__layout">
          <div className="row mx-0">
            {isShowCalendar.value ? (
              <div className="analytics__filter col-12">
                <AnalyticsFilterDate isShowCalendar={isShowCalendar} />
                <button
                  className="analytics__filter__button"
                  onClick={() => handleGetAnalytics()}
                  disabled={isButtonLoading.value}
                >
                  {isButtonLoading.value ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    <>Filter Analitik</>
                  )}
                </button>
              </div>
            ) : (
              <div className="analytics__layout__content col-12">
                <AlertInfo
                  className="mx-0 flex-a-center"
                  text={
                    <p className="body mb-0">
                      Kamu melihat laporan dari tanggal{" "}
                      <strong>
                        {format(startDate.value, "dd/MM/yyyy", { locale: id })}{" "}
                        - {format(endDate.value, "dd/MM/yyyy", { locale: id })}
                      </strong>
                      .
                    </p>
                  }
                />
                <button
                  className="button-calendar button button-md button-secondary"
                  type="button"
                  onClick={e => {
                    e.preventDefault()
                    isShowCalendar.setValue(true)
                  }}
                >
                  <span className="mdi mdi-18px mdi-calendar-month"></span>{" "}
                  Filter Analitik
                </button>
                <div className="section-divider"></div>

                <TotalVisit visitCount={visitCount} />
                <div className="section-divider"></div>

                <MediaPostTopThree posts={postsAnalytics} />
                <div className="section-divider"></div>

                <VanillaLink links={linksAnalytics} />
                <div className="section-divider"></div>

                <SocialLink links={linksAnalytics} />
              </div>
            )}
          </div>
        </section>
      )
    }

    return (
      <section className="analytics__layout">
        <div className="row">
          <div className="analytics__layout__content col-8">
            <TotalVisit visitCount={visitCount} />
            <div className="section-divider"></div>

            <MediaPostTopThree
              posts={postsAnalytics}
              isLoadingSkeleton={isLoadingSkeleton}
            />
            <div className="section-divider"></div>

            <VanillaLink
              links={linksAnalytics}
              isLoadingSkeleton={isLoadingSkeleton}
            />
            <div className="section-divider"></div>

            <SocialLink
              links={linksAnalytics}
              isLoadingSkeleton={isLoadingSkeleton}
            />
          </div>
          <div className="analytics__filter col-4">
            <AnalyticsFilterDate />

            <button
              className="analytics__filter__button"
              onClick={() => handleGetAnalytics()}
              disabled={isButtonLoading.value}
            >
              {isButtonLoading.value ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                <>Filter Analitik</>
              )}
            </button>
          </div>
        </div>
      </section>
    )
  }
}

export default AnalyticsLayout
