import React from "react"
import { useDashboardContext } from "@services/DashboardContext"
import { handleOpenSidenav, detectMob } from "@src/Helper"
import CoverImageLayout from "./CoverImageLayout"

const CoverImage = React.forwardRef((props, ref) => {
  // Hooks
  const { personalize, coverImages, isFetchInitialData } = useDashboardContext()

  // Custom Function
  const checkIsFetchInitialData = () => {
    if (coverImages.value.length === 0) {
      isFetchInitialData.setValue(true)
    }
  }

  // JSX
  return (
    <section
      id={detectMob() ? "m-coverImage" : ""}
      className="cover-image"
      style={{
        background: personalize?.value.cover_image
          ? `url(${personalize.value.cover_image.url}) no-repeat center center/cover`
          : "",
        height: `${personalize?.value.cover_image ? "" : "140px"}`,
      }}
    >
      {!detectMob() && !personalize?.value.cover_image && (
        <div className="d-flex justify-content-center">
          <button
            className="cover-image__button--no-cover"
            type="button"
            onClick={() =>
              handleOpenSidenav(
                ref,
                "cover-image__layout",
                checkIsFetchInitialData
              )
            }
          >
            <span>Add Cover Image</span>
          </button>
        </div>
      )}
      {!detectMob() && personalize?.value.cover_image && (
        <button
          className="cover-image__button"
          type="button"
          onClick={() =>
            handleOpenSidenav(
              ref,
              "cover-image__layout",
              checkIsFetchInitialData
            )
          }
        >
          <span className="mdi mdi-pencil mdi-18px"></span>
        </button>
      )}
      <CoverImageLayout ref={ref} />
    </section>
  )
})

export default CoverImage
