import React from "react"
import { useLocation } from "@reach/router"
import { Link } from "gatsby"
import _ from "lodash"

const MediaPostGridItem = ({ post, number, col }) => {
  const location = useLocation()

  return (
    <Link
      to={`/analytics/media-post/${post.id}`}
      className={`analytics__media-post__item col-${col}`}
      state={{ prevPath: location.pathname, post }}
    >
      <img
        className="analytics__media-post__item__image"
        src={post.media_url}
        alt={`Post ${number}`}
      />
      <p className="analytics__media-post__item__click-count body">
        {post.analytics_count} Klik
      </p>
      <p className="analytics__media-post__item__title caption">
        #{number} - {_.truncate(post.name, { length: 18 })}
      </p>
    </Link>
  )
}

export default MediaPostGridItem
