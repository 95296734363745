import React, { forwardRef, useRef } from "react"
import DatePicker, { registerLocale } from "react-datepicker"
import { compareAsc, format } from "date-fns"
import { useAnalyticsContext } from "@services/AnalyticsContext"
import id from "date-fns/locale/id"
import { detectMob } from "@src/Helper"

registerLocale("id", id)
const AnalyticsFilterDate = ({ isShowCalendar }) => {
  // Hooks
  const startDateRef = useRef(null)
  const endDateRef = useRef(null)
  const { startDate, endDate } = useAnalyticsContext()

  // Constant
  const today = new Date()

  // Custom Function
  const onChange = dates => {
    const [start, end] = dates
    startDate.setValue(start)
    endDate.setValue(end)
  }

  const calenderHeaderNavigation = ({
    monthDate,
    decreaseMonth,
    increaseMonth,
  }) => (
    <div className="react-datepicker__header--custom__navigation">
      <button onClick={decreaseMonth} className="ms-2">
        <span className="mdi mdi-18px mdi-arrow-left"></span>
      </button>
      <div className="react-datepicker__current-month">
        {format(monthDate, "LLLL Y", { locale: id })}
      </div>
      <button onClick={increaseMonth} className="me-2">
        <span className="mdi mdi-18px mdi-arrow-right"></span>
      </button>
    </div>
  )

  const CalendarStartInput = forwardRef(({ value, onClick }, ref) => (
    <div className="row mx-0">
      <input
        id="inputStartDate"
        className="analytics__filter__start-date__input input-md filled col-10"
        onClick={onClick}
        ref={ref}
        value={value}
      />
      <span className="analytics__filter__start-date__icon mdi mdi-calendar-month col-2"></span>
    </div>
  ))

  const CalendarEndInput = forwardRef(({ value, onClick }, ref) => (
    <div className="row mx-0">
      <input
        id="inputEndDate"
        className="analytics__filter__end-date__input input-md filled col-10"
        onClick={onClick}
        ref={ref}
        value={value}
      />
      <span className="analytics__filter__end-date__icon mdi mdi-calendar-month col-2"></span>
    </div>
  ))

  // JSX
  if (detectMob()) {
    return (
      <>
        <div
          className="analytics__filter__back"
          onClick={() => isShowCalendar.setValue(false)}
        >
          <span className="analytics__filter__back-icon mdi mdi-18px mdi-arrow-left"></span>
          Kembali
        </div>
        <div className="analytics__filter__start-date">
          <label
            htmlFor="inputStartDate"
            className="analytics__filter__start-date__label label-sm"
          >
            Dari Tanggal
          </label>
          <DatePicker
            id="inputStartDate"
            locale="id"
            selected={startDate.value}
            showPopperArrow={false}
            calendarClassName="analytics__filter__calendar"
            dateFormat="MMMM, dd-MM-yyyy"
            startDate={startDate.value}
            endDate={endDate.value}
            maxDate={today}
            ref={startDateRef}
            onChange={date => {
              const isStartAfterEnd = compareAsc(date, endDate.value)
              startDate.setValue(date)
              if (isStartAfterEnd === 1) {
                endDate.setValue(date)
              }
            }}
            renderCustomHeader={calenderHeaderNavigation}
            customInput={<CalendarStartInput />}
            withPortal
            selectsStart
          />
        </div>
        <div className="analytics__filter__end-date">
          <label
            htmlFor="inputEndDate"
            className="analytics__filter__end-date__label label-sm"
          >
            Sampai Tanggal
          </label>
          <DatePicker
            id="inputEndDate"
            locale="id"
            selected={endDate.value}
            showPopperArrow={false}
            calendarClassName="analytics__filter__calendar"
            dateFormat="MMMM, dd-MM-yyyy"
            startDate={startDate.value}
            endDate={endDate.value}
            minDate={startDate.value}
            maxDate={today}
            ref={endDateRef}
            onChange={date => endDate.setValue(date)}
            renderCustomHeader={calenderHeaderNavigation}
            customInput={<CalendarEndInput />}
            withPortal
            selectsEnd
          />
        </div>
        <div className="analytics__filter__inline-calendar">
          <DatePicker
            locale="id"
            selected={startDate.value}
            showPopperArrow={false}
            onChange={onChange}
            startDate={startDate.value}
            endDate={endDate.value}
            maxDate={today}
            calendarClassName="analytics__filter__calendar"
            selectsRange
            inline
            renderCustomHeader={calenderHeaderNavigation}
          />
        </div>
      </>
    )
  }

  return (
    <>
      <div className="analytics__filter__start-date">
        <label
          htmlFor="inputStartDate"
          className="analytics__filter__start-date__label label-sm"
        >
          Dari Tanggal
        </label>
        <DatePicker
          id="inputStartDate"
          locale="id"
          selected={startDate.value}
          showPopperArrow={false}
          calendarClassName="analytics__filter__calendar"
          dateFormat="MMMM, dd-MM-yyyy"
          startDate={startDate.value}
          endDate={endDate.value}
          maxDate={today}
          ref={startDateRef}
          onChange={date => {
            const isStartAfterEnd = compareAsc(date, endDate.value)
            startDate.setValue(date)
            if (isStartAfterEnd === 1) {
              endDate.setValue(date)
            }
          }}
          renderCustomHeader={calenderHeaderNavigation}
          customInput={<CalendarStartInput />}
          withPortal
          selectsStart
        />
      </div>
      <div className="analytics__filter__end-date">
        <label
          htmlFor="inputEndDate"
          className="analytics__filter__end-date__label label-sm"
        >
          Sampai Tanggal
        </label>
        <DatePicker
          id="inputEndDate"
          locale="id"
          selected={endDate.value}
          showPopperArrow={false}
          calendarClassName="analytics__filter__calendar"
          dateFormat="MMMM, dd-MM-yyyy"
          startDate={startDate.value}
          endDate={endDate.value}
          minDate={startDate.value}
          maxDate={today}
          ref={endDateRef}
          onChange={date => endDate.setValue(date)}
          renderCustomHeader={calenderHeaderNavigation}
          customInput={<CalendarEndInput />}
          withPortal
          selectsEnd
        />
      </div>
      <div className="analytics__filter__inline-calendar">
        <DatePicker
          locale="id"
          selected={startDate.value}
          showPopperArrow={false}
          onChange={onChange}
          startDate={startDate.value}
          endDate={endDate.value}
          maxDate={today}
          calendarClassName="analytics__filter__calendar"
          selectsRange
          inline
          renderCustomHeader={calenderHeaderNavigation}
        />
      </div>
    </>
  )
}

export default AnalyticsFilterDate
