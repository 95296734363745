import React from "react"
import { detectMob } from "@src/Helper"
import HelpTooltip from "@components/utils/HelpTooltip"

const TotalVisit = ({ visitCount }) => {
  // JSX
  if (detectMob()) {
    return (
      <div className="analytics__layout__total-visit">
        <h1 className="analytics__layout__total-visit__title subhead my-0">
          Total Kunjungan{" "}
          <HelpTooltip>
            Menunjukkan berapa banyak kunjungan yang diterima <em>pipehome</em>{" "}
            kamu dalam rentang waktu 24 jam.
          </HelpTooltip>
        </h1>
        <h3 className="analytics__layout__total-visit__value subhead my-0">
          {visitCount.value}
        </h3>
      </div>
    )
  }

  return (
    <div className="analytics__layout__total-visit">
      <h1 className="analytics__layout__total-visit__title heading-5 my-0">
        Total Kunjungan{" "}
        <HelpTooltip>
          Menunjukkan berapa banyak kunjungan yang diterima <em>pipehome</em>{" "}
          kamu dalam rentang waktu 24 jam.
        </HelpTooltip>
      </h1>
      <h3 className="analytics__layout__total-visit__value heading-5 my-0">
        {visitCount.value}
      </h3>
    </div>
  )
}

export default TotalVisit
