import React, { useRef } from "react"
import { detectMob, ClientOnly } from "@src/Helper"
import Seo from "@components/seo"
import Sidenav from "@components/utils/Sidenav"
import AnalyticsLayout from "@components/Analytics/AnalyticsLayout"

const Analytics = () => {
  // Hooks
  const backdrop = useRef(null)

  // Custom Function
  const handleCloseBackdrop = () => {
    if (backdrop.current) {
      if (backdrop.current.classList.contains("backdrop--show")) {
        const sidenav = document.querySelector(".sidenav")

        backdrop.current.classList.remove("backdrop--show")
        sidenav.classList.add("sidenav--expand")
        sidenav.classList.remove("sidenav--expanded")

        document.querySelector("body").classList.remove("overflow-hidden")
        document.querySelector(".sidenav").style.zIndex = 996
      }
    }
  }

  // JSX
  if (detectMob()) {
    return (
      <>
        <Seo title="Analytics" />
        <div id="m-analytics" className="analytics pad-t-90">
          <div className="row mx-0">
            <div className="col-12">
              <div className="analytics__container p-0">
                <ClientOnly>
                  <AnalyticsLayout />
                </ClientOnly>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <Seo title="Analytics" />
      <div className="analytics">
        <div
          ref={backdrop}
          className="backdrop"
          onClick={() => handleCloseBackdrop()}
        ></div>
        <Sidenav />
        <div className="analytics__container">
          <ClientOnly>
            <AnalyticsLayout />
          </ClientOnly>
        </div>
      </div>
    </>
  )
}

export default Analytics
