import React, { useRef } from "react"
import { detectMob, ClientOnly } from "@src/Helper"
import Seo from "@components/seo"
import Sidenav from "@components/utils/Sidenav"
import MediaPostLayout from "@components/Analytics/MediaPost/MediaPostLayout"

const AnalyticsMediaPost = () => {
  // Hooks
  const backdrop = useRef(null)

  // Custom Function
  const handleCloseBackdrop = () => {
    if (backdrop.current) {
      if (backdrop.current.classList.contains("backdrop--show")) {
        const sidenav = document.querySelector(".sidenav")

        backdrop.current.classList.remove("backdrop--show")
        sidenav.classList.add("sidenav--expand")
        sidenav.classList.remove("sidenav--expanded")

        document.querySelector("body").classList.remove("overflow-hidden")
        document.querySelector(".sidenav").style.zIndex = 996
      }
    }
  }

  // JSX
  if (detectMob()) {
    return (
      <>
        <Seo title="Analytics - Media Posts" />
        <div
          id="m-analyticsMediaPostGrid"
          className="analytics-media-post-grid pad-t-90"
        >
          <div className="row">
            <div className="col-12">
              <div className="analytics-media-post-grid__container">
                <ClientOnly>
                  <MediaPostLayout />
                </ClientOnly>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <Seo title="Analytics - Media Posts" />
      <div className="analytics-media-post-grid">
        <div
          ref={backdrop}
          className="backdrop"
          onClick={() => handleCloseBackdrop()}
        ></div>
        <Sidenav />
        <div className="analytics-media-post-grid__container">
          <ClientOnly>
            <MediaPostLayout />
          </ClientOnly>
        </div>
      </div>
    </>
  )
}

export default AnalyticsMediaPost
