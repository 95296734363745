import React, { useRef, useEffect } from "react"
import { Link } from "gatsby"
import { detectMob, isSSR } from "@src/Helper"
import Logo from "@images/sociopipe-main-logo.svg"
import BlackLogo from "@images/sociopipe-main-logo.svg"

const HeaderUnauthenticated = ({ location }) => {
  // Hooks
  const menu = useRef(null)
  const navbar = useRef(null)

  useEffect(() => {
    if (!isSSR) {
      window.addEventListener("scroll", detectScroll)
      return () => {
        window.removeEventListener("scroll", detectScroll)
      }
    }
  }, [])

  // Variable
  let prevScrollPos
  if (!isSSR) {
    prevScrollPos = window.pageYOffset
  }

  // Custom Function
  const detectScroll = () => {
    let currentScrollPos = window.pageYOffset
    let scrollDownLimit = 75
    if (detectMob()) scrollDownLimit = 20

    if (navbar.current) {
      if (
        prevScrollPos < currentScrollPos &&
        window.pageYOffset >= scrollDownLimit
      ) {
        navbar.current.classList.add("scroll-down")
      } else if (window.pageYOffset == 0) {
        navbar.current.classList.remove("scroll-down")
      }
    }
    prevScrollPos = currentScrollPos
  }

  if (detectMob()) {
    // Custom Functions
    const handleCloseMenu = () => {
      menu.current.classList.remove("expand")
    }
    const handleOpenMenu = () => {
      menu.current.classList.add("expand")
    }

    // JSX
    return (
      <header id="m-header" className="header" ref={navbar}>
        <div className="container">
          <nav className="header__nav">
            <ul className="header__nav__ul flex-a-center jc-between">
              <li className="header__nav__ul__list">
                <Link className="header__nav__ul__list__link" to="/">
                  <img
                    className="header__nav__ul__list__link__logo"
                    src={BlackLogo}
                    alt="Sociopipe Logo"
                    width={130}
                    height={27}
                  />
                </Link>
              </li>
              <li className="header__nav__ul__list">
                <span
                  role="button"
                  aria-label="Open Menu"
                  tabIndex="0"
                  className="header__nav__ul__list__hamburger mdi mdi-menu mdi-24px"
                  onClick={() => handleOpenMenu()}
                  onKeyDown={e => {
                    if (e.key === "Enter") handleOpenMenu()
                  }}
                ></span>
              </li>
            </ul>
          </nav>
          <div className="header__menu" ref={menu}>
            <nav className="header__menu__nav container">
              <ul className="header__menu__nav__top flex-a-center jc-between">
                <li className="header__menu__nav__top__list">
                  <Link
                    className="header__menu__nav__top__list__link"
                    to="/"
                    onClick={() => handleCloseMenu()}
                  >
                    <img
                      className="header__menu__nav__top__list__link__logo"
                      src={BlackLogo}
                      alt="Sociopipe Logo"
                      width={130}
                      height={27}
                    />
                  </Link>
                </li>
                <li className="header__menu__nav__top__list">
                  <span
                    role="button"
                    aria-label="Close Menu"
                    tabIndex="0"
                    className="header__menu__nav__top__list__close mdi mdi-close mdi-24px"
                    onClick={() => handleCloseMenu()}
                    onKeyDown={e => {
                      if (e.key === "Enter") handleCloseMenu()
                    }}
                  ></span>
                </li>
              </ul>
              <ul className="header__menu__nav__bottom">
                <li
                  className={`header__menu__nav__bottom__list ${
                    location.pathname === "/" ? "active" : ""
                  }`}
                >
                  <Link
                    className="header__menu__nav__bottom__list__link subhead"
                    to="/"
                    onClick={() => handleCloseMenu()}
                  >
                    Home
                  </Link>
                </li>
                <li
                  className={`header__menu__nav__bottom__list ${
                    location.pathname === "/our-features" ? "active" : ""
                  }`}
                >
                  <Link
                    className="header__menu__nav__bottom__list__link subhead"
                    to="/our-features"
                    onClick={() => handleCloseMenu()}
                  >
                    Apa itu Pipehome?
                  </Link>
                </li>
                <li
                  className={`header__menu__nav__bottom__list ${
                    location.pathname === "/login" ? "active" : ""
                  }`}
                >
                  <Link
                    className="header__menu__nav__bottom__list__link subhead"
                    to="/login"
                    onClick={() => handleCloseMenu()}
                  >
                    Masuk
                  </Link>
                </li>
                <li
                  className={`header__menu__nav__bottom__list ${
                    location.pathname === "/register" ? "active" : ""
                  }`}
                >
                  <Link
                    className="header__menu__nav__bottom__list__link subhead"
                    to="/register"
                    onClick={() => handleCloseMenu()}
                  >
                    Daftar
                  </Link>
                </li>
                <li
                  className={`header__menu__nav__bottom__list ${
                    location.pathname === "/contact-us" ? "active" : ""
                  }`}
                >
                  <Link
                    className="header__menu__nav__bottom__list__link subhead"
                    to="/contact-us"
                    onClick={() => handleCloseMenu()}
                  >
                    Hubungi Kami
                  </Link>
                </li>
                <hr className="my-3" />
                <li
                  className={`header__menu__nav__bottom__list ${
                    location.pathname === "/privacy-policy" ? "active" : ""
                  }`}
                >
                  <Link
                    className="header__menu__nav__bottom__list__link subhead"
                    to="/privacy-policy"
                    onClick={() => handleCloseMenu()}
                  >
                    Kebijakan Privasi
                  </Link>
                </li>
                <li
                  className={`header__menu__nav__bottom__list ${
                    location.pathname === "/terms-conditions" ? "active" : ""
                  }`}
                >
                  <Link
                    className="header__menu__nav__bottom__list__link subhead"
                    to="/terms-and-conditions"
                    onClick={() => handleCloseMenu()}
                  >
                    Syarat & Ketentuan
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
    )
  }

  // JSX
  return (
    <header className="header px-0" ref={navbar}>
      <div className="container">
        <nav className="header__nav flex-a-center">
          <ul className="header__nav__left flex-a-center">
            <li className="header__nav__left__list">
              <Link
                className="header__nav__left__list__link header__nav__left__list__company-logo"
                to="/"
              >
                <img
                  className="header__nav__left__list__link__logo"
                  src={Logo}
                  alt="Sociopipe Logo"
                  width={177}
                  height={36}
                />
              </Link>
            </li>
            <li className="header__nav__left__list">
              <Link
                className={`header__nav__left__list__link body ${
                  location.pathname === "/our-features" ? "active" : ""
                }`}
                to="/our-features"
              >
                Apa itu Pipehome?
              </Link>
            </li>
            <li className="header__nav__left__list">
              <Link
                className={`header__nav__left__list__link body ${
                  location.pathname === "/contact-us" ? "active" : ""
                }`}
                to="/contact-us"
              >
                Hubungi Kami
              </Link>
            </li>
          </ul>
          <ul className="header__nav__right flex-a-center">
            <li className="header__nav__right__list">
              <Link
                className={`header__nav__right__list__link body ${
                  location.pathname === "/login" ? "active" : ""
                }`}
                to="/login"
              >
                Masuk
              </Link>
            </li>
            <li className="header__nav__right__list">
              <Link
                className="header__nav__right__list__link button button-md button-primary"
                to="/register"
              >
                Daftar
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  )
}

export default HeaderUnauthenticated
