import React, { useState } from "react"
import { useDashboardContext } from "@services/DashboardContext"
import { detectMob } from "@src/Helper"
import HelpTooltip from "@components/utils/HelpTooltip"
import { ColorPickerModal } from "@components/utils/Modal"

const StylingFont = () => {
  // Hooks
  const { currentStylingValue } = useDashboardContext()
  const [isFgColorModal, setIsFgColorModal] = useState(false)

  // Custom Functions
  const handleDeleteFontColor = () => {
    currentStylingValue.setValue(prevState => {
      return {
        ...prevState,
        settings: {
          ...prevState.settings,
          font: {
            color: null,
          },
        },
      }
    })
  }

  // JSX
  return (
    <>
      <ColorPickerModal
        show={isFgColorModal}
        onHide={() => setIsFgColorModal(false)}
        id={detectMob() ? "m-fontFg" : "fontFg"}
        className={`${detectMob() ? "modal-z-index" : ""} color-picker-modal`}
        title="Warna Font"
        size="sm"
        valueOne={currentStylingValue.value.settings.font?.color ?? "#ffffff"}
        isGradient={false}
        fn={(currentStylingValue, color) => {
          currentStylingValue.setValue(prevState => {
            return {
              ...prevState,
              settings: {
                ...prevState.settings,
                font: {
                  color: color.value,
                },
              },
            }
          })
        }}
      />
      <section className={`styling__font ${detectMob() ? "px-0" : ""}`}>
        <h2 className="font--heading-6">
          <em>Font</em>
          <HelpTooltip>
            Warna tulisan yang akan diubah berupa nama, bio, jam aktif dan
            deskripsi post pada feed.
          </HelpTooltip>
        </h2>
        <div className="font__container">
          <div className="font__fg">
            <h3 className="font__fg--caption">
              Warna <em>Font</em>
            </h3>
            <div className="flex mt-2">
              <div
                className={`font__fg-selector ${
                  currentStylingValue.value.settings.font?.color === null
                    ? "border-dash__button"
                    : ""
                }`}
                onClick={() => setIsFgColorModal(true)}
              >
                {currentStylingValue.value.settings.font?.color ? (
                  <div
                    className="font__fg-placeholder"
                    style={{
                      background: currentStylingValue.value.settings.font?.color
                        ? currentStylingValue.value.settings.font.color
                        : "",
                      border:
                        currentStylingValue.value.settings.font?.color ===
                        "#ffffff"
                          ? "1px solid #b9b9b9"
                          : "",
                    }}
                  ></div>
                ) : (
                  <span className="mdi mdi-palette text-black-30"></span>
                )}
              </div>
              <div
                className="font__fg-value--caption"
                onClick={() => setIsFgColorModal(true)}
              >
                {currentStylingValue.value.settings.font?.color
                  ? currentStylingValue.value.settings.font.color
                  : "Warna default"}
              </div>
              <button
                className="font__fg-delete-button"
                onClick={e => {
                  e.preventDefault()
                  handleDeleteFontColor()
                }}
              >
                <span className="mdi mdi-trash-can mdi-24px"></span>
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default StylingFont
