import React, { useRef, useEffect } from "react"
import _ from "lodash"
import { toast } from "react-toastify"
import { useDashboardContext } from "@services/DashboardContext"
import { detectMob, useInput, isInputEmpty } from "@src/Helper"
import { updatePost, deletePost } from "@http/DashboardRepository"
import Loading from "@components/utils/Loading"
import { AlertError } from "@components/utils/Alert"
import HelpTooltip from "@components/utils/HelpTooltip"
import CreateCategoryModal from "./Modal/CreateCategoryModal"
import ImageUpload from "./PostImageUpload"
import PostLink from "./PostLink"
import PostCategory from "./PostCategory"

const EditPostLayout = ({ postLayoutRef }) => {
  // Hooks
  const { personalize, currentEditPostValue, isLoadingPost, isLoadingFeed } =
    useDashboardContext()
  const nameRef = useRef(null)
  const captionRef = useRef(null)
  const isUpdateLoading = useInput({
    is_spin: false,
    is_disabled: false,
  })
  const isDeleteLoading = useInput({
    is_spin: false,
    is_disabled: false,
  })
  const isModalShow = useInput(false)
  const errors = useInput({})
  const linkError = useInput({})

  const post = currentEditPostValue.value

  useEffect(() => {
    const data = post.categories?.map(category => {
      if (category.id !== 0) {
        category.is_active = false
      } else {
        category.is_active = true
      }
      return category
    })
    handleUpdateCurrentPost("categories", [...data])
  }, [])

  useEffect(() => {
    if (nameRef.current && captionRef.current) {
      isInputEmpty(nameRef)
      isInputEmpty(captionRef)
    }
  }, [currentEditPostValue.value])

  // Variable
  let data = [...post.links]

  // Custom Functions
  const handleEditPost = () => {
    errors.setValue({ ...errors.value })

    const formData = new FormData()
    if (post.selectedCategory !== 0) {
      formData.append("pipehome_category_id", post.selectedCategory)
    }
    formData.append("name", post.name)
    formData.append("pipehome_post_id", post.id)
    if (typeof post.pictureUrl === "object") {
      formData.append("media", post.pictureUrl.file)
    }
    formData.append("caption", post.caption)
    formData.append("is_highlight", post.isHighlight ? 1 : 0)
    post.links.map((link, key) => {
      formData.append(`post_links[${key}][url]`, link.url)
      formData.append(`post_links[${key}][label]`, link.label)
    })
    formData.append("_method", "PATCH")

    isUpdateLoading.setValue({
      is_spin: true,
      is_disabled: true,
    })
    isDeleteLoading.setValue(prevState => {
      return {
        ...prevState,
        is_disabled: true,
      }
    })
    updatePost(personalize.value.id, formData)
      .then(() => {
        errors.setValue({})
        linkError.setValue({})
        isLoadingFeed.setValue(true)
        handleClosePostLayout()
        toast.success("Berhasil menyimpan perubahan!")
      })
      .catch(err => {
        if (err.response.status === 422) {
          errors.setValue({
            ...errors.value,
            name: err.response.data.errors.name
              ? err.response.data.errors.name
              : null,
            media: err.response.data.errors.media
              ? err.response.data.errors.media[0]
              : null,
            caption: err.response.data.errors.caption
              ? err.response.data.errors.caption[0]
              : null,
          })
          linkError.setValue(err.response.data.errors)
        }
        console.log(err.response)
      })
      .finally(() => {
        isUpdateLoading.setValue({
          is_spin: false,
          is_disabled: false,
        })
        isDeleteLoading.setValue(prevState => {
          return {
            ...prevState,
            is_disabled: false,
          }
        })
      })
  }

  const handleDeletePost = () => {
    isDeleteLoading.setValue({
      is_spin: true,
      is_disabled: true,
    })
    isUpdateLoading.setValue(prevState => {
      return {
        ...prevState,
        is_disabled: true,
      }
    })
    deletePost(personalize.value.id, post.id)
      .then(() => {
        isLoadingFeed.setValue(true)
        handleClosePostLayout()
        toast.success("Berhasil menyimpan perubahan!")
      })
      .catch(err => {
        console.log(err.response)
      })
      .finally(() => {
        isDeleteLoading.setValue({
          is_spin: false,
          is_disabled: false,
        })
        isUpdateLoading.setValue(prevState => {
          return {
            ...prevState,
            is_disabled: false,
          }
        })
      })
  }

  const handleUpdateCurrentPost = (key, value) => {
    currentEditPostValue.setValue(prevState => {
      return {
        ...prevState,
        [key]: value,
      }
    })
  }

  const handleAddLink = () => {
    currentEditPostValue.setValue(prevState => {
      return {
        ...prevState,
        links: [...prevState.links, { label: "", url: "" }],
      }
    })
  }

  const handleSetActive = idx => {
    handleUpdateCurrentPost("selectedCategory", idx)
  }

  const handleDeleteLink = idx => {
    data[idx] = null
    data = _.filter(data)
    handleUpdateCurrentPost("links", data)
    linkError.setValue({})
  }

  const handleAddTitle = (key, value) => {
    let datum = { ...data[key] }
    datum.label = value
    data[key] = datum
    handleUpdateCurrentPost("links", data)
  }

  const handleAddUrl = (key, value) => {
    let datum = { ...data[key] }
    datum.url = value
    data[key] = datum
    handleUpdateCurrentPost("links", data)
  }

  const handleClosePostLayout = () => {
    isInputEmpty(nameRef)
    isInputEmpty(captionRef)
    if (postLayoutRef.current) {
      if (detectMob()) {
        if (
          postLayoutRef.current.classList.contains(
            "edit-post__layout--slide-up"
          )
        ) {
          postLayoutRef.current.classList.add("edit-post__layout--slide-down")
          postLayoutRef.current.classList.remove("edit-post__layout--slide-up")
          document
            .querySelector(".edit-post__header--right")
            .classList.remove("layout__header--fixed-bottom")
        }
      } else {
        if (
          postLayoutRef.current.classList.contains(
            "edit-post__layout--expanded"
          )
        ) {
          postLayoutRef.current.classList.add("edit-post__layout--expand")
          postLayoutRef.current.classList.remove("edit-post__layout--expanded")
          document
            .querySelector(".feed__layout")
            .classList.remove("feed__layout--double-expanded", "black-90-bg")
          document
            .querySelector(".feed__layout .backdrop")
            .classList.remove("backdrop--show")
        }
      }
    }
    currentEditPostValue.setValue(prevState => {
      return {
        ...prevState,
        categories: [
          {
            id: 0,
            name: "Tidak Ada",
            is_active: true,
          },
        ],
        links: [],
        id: 0,
        name: "",
        caption: "",
        imageInput: "",
        pictureUrl: "",
        selectedCategory: 0,
        isHighlight: false,
      }
    })
  }

  // JSX
  return (
    <div
      ref={postLayoutRef}
      id={`${detectMob() ? "m-editPost" : ""}`}
      className={`customization__layout edit-post__layout ${
        detectMob()
          ? "edit-post__layout--slide-down"
          : "edit-post__layout--expand"
      }`}
    >
      <CreateCategoryModal
        show={isModalShow.value}
        onHide={() => isModalShow.setValue(false)}
      />
      <form
        onSubmit={e => {
          e.preventDefault()
          handleEditPost()
        }}
      >
        <div className="edit-post__header">
          <div className="edit-post__header--left">
            <span
              className="mdi mdi-close mdi-24px"
              onClick={() => handleClosePostLayout()}
            ></span>
            <h2 className="edit-post__header-title--heading-5">
              <em>Edit Post</em>
            </h2>
          </div>
          <div className="edit-post__header--right">
            <button
              className={`button button-md button-secondary ${
                detectMob() ? "w-100" : ""
              }`}
              type="submit"
              disabled={
                isUpdateLoading.value.is_disabled &&
                isDeleteLoading.value.is_disabled
              }
            >
              {isUpdateLoading.value.is_spin ? (
                <span
                  className="spinner-border spinner-border-sm ms-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                <>Simpan Perubahan</>
              )}
            </button>
          </div>
        </div>
        <div className="edit-post__body">
          <div className="container">
            {errors.value.media && (
              <AlertError
                className={`flex-a-center ${detectMob() ? "" : "mt-3"}`}
                text={<p className="body mb-0">{errors.value.media}</p>}
              />
            )}
            {isLoadingPost.value ? (
              <Loading />
            ) : (
              <>
                <div className="row">
                  <div className={detectMob() ? "col-12" : "col-4"}>
                    <ImageUpload className="edit-post" isEdit={true} />
                  </div>
                  <div className={detectMob() ? "col-12 px-0 mt-3" : "col-8"}>
                    <div className="edit-post__name">
                      <label
                        htmlFor="inputName"
                        className="edit-post__name-label label-sm"
                      >
                        Nama Post
                        <sup>
                          <strong className="text-cherry-50">*</strong>
                        </sup>
                      </label>
                      <input
                        type="text"
                        className={`edit-post__name-input ${
                          detectMob() ? "input-md" : "input-sm"
                        }`}
                        id="inputName"
                        spellCheck={false}
                        placeholder="Masukkan nama post kamu"
                        ref={nameRef}
                        value={post.name}
                        onChange={e => {
                          isInputEmpty(nameRef)
                          handleUpdateCurrentPost("name", e.target.value)
                        }}
                      />
                      {errors.value.name && (
                        <span className="error-message caption mb-1">
                          {errors.value.name}
                        </span>
                      )}
                    </div>
                    <div className="edit-post__category">
                      {post.categories.length > 1 && (
                        <>
                          <label
                            htmlFor="inputCategory"
                            className={`edit-post__category-label label-sm ${
                              detectMob() ? "mb-0" : ""
                            }`}
                          >
                            Pilih 1 Kategori
                          </label>
                          <div className="edit-post__category-list">
                            {post.categories?.map((categoryItem, key) => {
                              return (
                                <PostCategory
                                  className="edit-post"
                                  categoryItem={categoryItem}
                                  idx={key}
                                  isEdit={true}
                                  handleSetActive={handleSetActive}
                                />
                              )
                            })}
                          </div>
                          <label className="label label-sm mt-2">atau</label>
                        </>
                      )}
                      <button
                        className="edit-post__category-add-button flex button-sm"
                        type="button"
                        onClick={() => isModalShow.setValue(true)}
                      >
                        + Tambah Kategori
                      </button>
                    </div>
                    <div className="edit-post__check form-check">
                      <input
                        type="checkbox"
                        className="edit-post__check-input form-check-input"
                        id="inputCheck"
                        checked={post.isHighlight}
                        onChange={() => {
                          handleUpdateCurrentPost(
                            "isHighlight",
                            !post.isHighlight
                          )
                        }}
                      />
                      <label
                        className="edit-post__check-label form-check-label label-sm"
                        htmlFor="inputCheck"
                      >
                        Soroti post ini
                      </label>
                    </div>
                    <p className="edit-post__check-description--caption">
                      Soroti post jika kamu ingin pengunjung <em>pipehome</em>{" "}
                      kamu lebih memperhatikan post ini.
                    </p>
                  </div>
                </div>
                <hr />
                <div className="edit-post__link-list" role="list">
                  {post.links?.map((link, key) => {
                    return (
                      <PostLink
                        className={"edit-post"}
                        link={link}
                        idx={key}
                        linkError={linkError}
                        handleAddTitle={handleAddTitle}
                        handleAddUrl={handleAddUrl}
                        handleDeleteLink={handleDeleteLink}
                      />
                    )
                  })}
                </div>
                {post.links.length >= 6 ? (
                  <></>
                ) : (
                  <button
                    className="edit-post__add-link-button button button-sm button-outline-primary"
                    type="button"
                    onClick={() => handleAddLink()}
                  >
                    <span
                      className={`dashboard__post-layout__form__add-link__button__icon mdi mdi-link-variant ${
                        detectMob() ? "" : "mdi-18px"
                      }`}
                    ></span>{" "}
                    Tambah Link Baru
                  </button>
                )}
                <p className="edit-post__link-description--caption">
                  Note: Link pada Post memungkinkan kamu menghubungkan berbagai
                  platform tempat kamu memajang produk ini ke dalam satu post
                  pipehome.
                </p>
                <div className="edit-post__description">
                  <label
                    htmlFor="inputCaption"
                    className="edit-post__description-label label-sm"
                  >
                    Deskripsi Post{" "}
                    <HelpTooltip>
                      Caption post bertujuan untuk menjelaskan lebih detail
                      tentang post ini.
                    </HelpTooltip>
                  </label>
                  <textarea
                    className="edit-post__description-input textarea-sm"
                    id="inputCaption"
                    rows="4"
                    spellCheck={false}
                    placeholder="Tulis sesuatu yang menarik tentang post ini"
                    ref={captionRef}
                    value={post.caption}
                    onChange={e => {
                      isInputEmpty(captionRef)
                      handleUpdateCurrentPost(
                        "caption",
                        e.target.value.replace(/  +/g, " ").trimStart()
                      )
                    }}
                  />
                  {errors.value.caption && (
                    <span className="error-message caption mb-1">
                      {errors.value.caption}
                    </span>
                  )}
                  <span className="edit-post__description--caption">
                    {post.caption === null ? "0" : post.caption.length}
                    /500 karakter
                  </span>
                </div>
                <hr />
                <button
                  className="edit-post__delete-button button button-md button-danger"
                  type="button"
                  disabled={
                    isDeleteLoading.value.is_disabled &&
                    isUpdateLoading.value.is_disabled
                  }
                  onClick={e => {
                    e.preventDefault()
                    handleDeletePost()
                  }}
                >
                  {isDeleteLoading.value.is_spin ? (
                    <span
                      className="spinner-border spinner-border-sm ms-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    <>Hapus Post</>
                  )}
                </button>
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  )
}

export default EditPostLayout
