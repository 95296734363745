import React, { useRef, useEffect } from "react"
import { useDashboardContext } from "@services/DashboardContext"
import { updateFeedTitle } from "@http/DashboardRepository"
import { useInput, isInputEmpty, detectMob } from "@src/Helper"
import { BasicModal } from "@components/utils/Modal"

const FeedTitle = () => {
  // Hooks
  const { personalize, currentPersonalizationValue } = useDashboardContext()
  const isMounted = useRef(false)
  const nameRef = useRef(null)
  const isModalOpen = useInput(false)
  const isLoading = useInput(false)
  const error = useInput("")

  useEffect(() => {
    if (isMounted.current) {
      isInputEmpty(nameRef)
    } else {
      isMounted.current = true
    }
  }, [isModalOpen.value])

  // Custom Function
  const handleSetCurrentPersonalization = () => {
    currentPersonalizationValue.setValue(prevState => {
      return {
        ...prevState,
        feed_title: personalize.value.feed_title,
      }
    })
  }

  const handleUpdateFeedTitle = () => {
    isLoading.setValue(true)
    updateFeedTitle(
      personalize.value.id,
      currentPersonalizationValue.value.feed_title
    )
      .then(() => {
        error.setValue("")
        personalize.setValue(prevState => {
          return {
            ...prevState,
            feed_title: currentPersonalizationValue.value.feed_title,
          }
        })
        isModalOpen.setValue(false)
      })
      .catch(err => {
        if (err.response.status === 422) {
          error.setValue(err.response.data.errors.feed_title[0])
        }
      })
      .finally(() => isLoading.setValue(false))
  }

  // JSX
  return (
    <>
      <BasicModal
        show={isModalOpen.value}
        onHide={() => {
          handleSetCurrentPersonalization()
          isModalOpen.setValue(false)
        }}
        id="feedTitle"
        className="feed-modal"
        title="Feed Title"
        size="md"
        backdrop={true}
        fn={handleSetCurrentPersonalization}
      >
        <form
          onSubmit={e => {
            e.preventDefault()
            handleUpdateFeedTitle()
          }}
        >
          <input
            className="input-lg feed-modal__input"
            type="text"
            id="name"
            placeholder="My Gallery..."
            ref={nameRef}
            value={currentPersonalizationValue.value.feed_title}
            onChange={e => {
              isInputEmpty(nameRef)
              currentPersonalizationValue.setValue(prevState => {
                return {
                  ...prevState,
                  feed_title: e.target.value,
                }
              })
            }}
          />
          {error.value && (
            <span className="error-message caption mb-1">{error.value}</span>
          )}
          <button
            className="feed-modal__button button button-md button-secondary"
            type="submit"
            disabled={isLoading.value}
          >
            {isLoading.value ? (
              <span
                className="spinner-border spinner-border-sm ms-1"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              <>Simpan Perubahan</>
            )}
          </button>
        </form>
      </BasicModal>
      <div className={`feed__title ${detectMob() ? "px-0" : ""}`}>
        <h4 className="feed__title-text--heading-4">
          {personalize.value.feed_title}
        </h4>
        <span
          className="feed__title-edit-icon mdi mdi-pencil"
          role="button"
          tabIndex="0"
          aria-label="Edit Feed Title"
          onClick={() => {
            isModalOpen.setValue(true)
          }}
          onKeyDown={e => {
            if (e.key === "Enter") {
              isModalOpen.setValue(true)
            }
          }}
        ></span>
      </div>
    </>
  )
}

export default FeedTitle
