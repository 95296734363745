import React, { useEffect } from "react"
import { Link } from "gatsby"
import { format } from "date-fns"
import id from "date-fns/locale/id"
import { getAnalyticsPosts } from "@http/AnalyticsRepositories"
import { getDashboard } from "@http/DashboardRepository"
import { detectMob, useInput } from "@src/Helper"
import { AlertInfo } from "@components/utils/Alert"
import Loading from "@components/utils/Loading"
import HelpTooltip from "@components/utils/HelpTooltip"
import AnalyticsFilterDate from "../AnalyticsFilterDate"
import MediaPostGrid from "./MediaPostGrid"
import MediaPostGridPagination from "./MediaPostGridPagination"
import { useAnalyticsContext } from "@services/AnalyticsContext"

const MediaPostLayout = () => {
  // Hooks
  const pageId = useInput(null)
  const currentPage = useInput(1)
  const posts = useInput([])
  const paginationMeta = useInput({})
  const isPageLoading = useInput(true)
  const isGridLoading = useInput(false)
  const isFilterButtonLoading = useInput(false)
  const isShowCalendar = useInput(false)
  const { startDate, endDate } = useAnalyticsContext()

  useEffect(() => {
    handleGetDashboard()
  }, [])

  useEffect(() => {
    handleGetAnalyticsPosts()
  }, [pageId.value])

  useEffect(() => {
    handleGetAnalyticsPosts()
  }, [currentPage.value])

  // Custom Function
  const handleFilterDate = () => {
    isFilterButtonLoading.setValue(true)
    isGridLoading.setValue(true)
    currentPage.setValue(1)
    handleGetAnalyticsPosts()
  }

  const handleOnPageChange = ({ selected }) => {
    isGridLoading.setValue(true)
    currentPage.setValue(selected + 1)
  }

  const handleGetDashboard = () => {
    getDashboard()
      .then(res => {
        pageId.setValue(res.data.page.id)
      })
      .catch(err => console.log(err.response))
  }

  const handleGetAnalyticsPosts = () => {
    if (pageId.value === null) return

    getAnalyticsPosts(
      pageId.value,
      currentPage.value,
      startDate.value,
      endDate.value
    )
      .then(res => {
        const { data, ...meta } = res.data
        posts.setValue(data)
        paginationMeta.setValue(meta)
        isShowCalendar.setValue(false)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        isPageLoading.setValue(false)
        isGridLoading.setValue(false)
        isFilterButtonLoading.setValue(false)
        isShowCalendar.setValue(false)
      })
  }

  // JSX
  if (isPageLoading.value) {
    return <Loading />
  } else {
    if (detectMob()) {
      return (
        <div className="analytics-media-post-grid__layout">
          <div className="row mx-0">
            {isShowCalendar.value ? (
              <div className="analytics__filter col-12">
                <AnalyticsFilterDate isShowCalendar={isShowCalendar} />

                <button
                  className="analytics__filter__button"
                  onClick={handleFilterDate}
                  disabled={isFilterButtonLoading.value}
                >
                  {isFilterButtonLoading.value ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    <>Filter Analitik</>
                  )}
                </button>
              </div>
            ) : (
              <div className="analytics-media-post-grid__content col-12">
                <div className="analytics-media-post-grid__header">
                  <Link
                    className="analytics-media-post-grid__back"
                    to="/analytics"
                  >
                    <span className="mdi mdi-arrow-left"></span> Kembali
                  </Link>
                  <AlertInfo
                    className="mx-0 mt-3 flex-a-center"
                    text={
                      <p className="body mb-0">
                        Kamu melihat laporan dari tanggal{" "}
                        <strong>
                          {format(startDate.value, "dd/MM/yyyy", {
                            locale: id,
                          })}{" "}
                          -{" "}
                          {format(endDate.value, "dd/MM/yyyy", { locale: id })}
                        </strong>
                        .
                      </p>
                    }
                  />
                  <button
                    className="button-calendar button button-md button-secondary"
                    type="button"
                    onClick={e => {
                      e.preventDefault()
                      isShowCalendar.setValue(true)
                    }}
                  >
                    <span className="mdi mdi-18px mdi-calendar-month"></span>{" "}
                    Filter Analitik
                  </button>
                  <h3 className="analytics-media-post-grid__title heading-5">
                    Analitik Feed{" "}
                    <HelpTooltip>
                      Menunjukkan berapa total jumlah klik yang diterima oleh
                      link-link dari post pada feed.
                    </HelpTooltip>
                  </h3>
                </div>

                <MediaPostGrid
                  posts={posts}
                  startNumber={paginationMeta.value.from}
                  isLoading={isGridLoading}
                />

                <div className="section-divider"></div>

                <MediaPostGridPagination
                  page={currentPage.value - 1}
                  pageCount={paginationMeta.value.last_page}
                  totalItem={paginationMeta.value.total}
                  pageRangeDisplayed={4}
                  marginPagesDisplayed={1}
                  handleOnPageChange={handleOnPageChange}
                />
              </div>
            )}
          </div>
        </div>
      )
    }

    return (
      <div className="analytics-media-post-grid__layout">
        <div className="row">
          <div className="analytics-media-post-grid__content col-8">
            <div className="analytics-media-post-grid__header">
              <Link className="analytics-media-post-grid__back" to="/analytics">
                <span className="mdi mdi-arrow-left"></span> Kembali
              </Link>
              <h3 className="analytics-media-post-grid__title heading-5">
                Analitik Feed{" "}
                <HelpTooltip>
                  Menunjukkan berapa total jumlah klik yang diterima oleh
                  link-link dari post pada feed.
                </HelpTooltip>
              </h3>
            </div>

            <div className="section-divider"></div>

            <MediaPostGrid
              posts={posts}
              startNumber={paginationMeta.value.from}
              isLoading={isGridLoading}
            />

            <div className="section-divider"></div>

            <MediaPostGridPagination
              page={currentPage.value - 1}
              pageCount={paginationMeta.value.last_page}
              totalItem={paginationMeta.value.total}
              pageRangeDisplayed={4}
              marginPagesDisplayed={1}
              handleOnPageChange={handleOnPageChange}
            />
          </div>
          <div className="analytics__filter col-4">
            <AnalyticsFilterDate />

            <button
              className="analytics__filter__button"
              onClick={handleFilterDate}
              disabled={isFilterButtonLoading.value}
            >
              {isFilterButtonLoading.value ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                <>Filter Analitik</>
              )}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default MediaPostLayout
