import React, { useState } from "react"
import Modal from "react-bootstrap/Modal"
import { useDashboardContext } from "@services/DashboardContext"
import { detectMob } from "@src/Helper"
import { DashboardSkeletonLoader } from "@components/utils/SkeletonLoader"
import HelpTooltip from "@components/utils/HelpTooltip"

const StylingTheme = () => {
  // Hooks
  const { personalize, themes, currentStylingValue, isLoadingInitialData } =
    useDashboardContext()
  const [isModalShow, setIsModalShow] = useState(false)

  const handleSelectTheme = theme => {
    if (!theme.is_locked) {
      currentStylingValue.setValue(prevState => {
        return {
          ...prevState,
          settings: {
            ...prevState.settings,
            theme: {
              ...prevState.settings.theme,
              name: theme.name,
            },
          },
        }
      })
    }
  }

  // JSX
  return (
    <>
      <Modal
        show={isModalShow}
        onHide={() => setIsModalShow(false)}
        id="lockedThemeModal"
        className="theme-modal"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="theme-modal__head">
          <Modal.Title id="contained-modal-title-vcenter">
            <span
              role="button"
              tabIndex="0"
              aria-label="Hide Modal Button"
              className="mdi mdi-36px mdi-close theme-modal__close"
              onClick={() => setIsModalShow(false)}
              onKeyDown={e => {
                if (e.key === "Enter") {
                  setIsModalShow(false)
                }
              }}
            ></span>
            <h2 className="theme-modal--heading-5">Selangkah lagi...</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="theme-modal__body">
          <div>
            <p className="theme-modal--body">
              Kamu bakal dapetin tema ini dengan dengan mengisi survei kepuasan
              pelanggan berikut.
              <br />
              <small className="text-cherry-50">
                (Tema akan terbuka otomatis dalam waktu 24 jam setelah survei
                terisi)
              </small>
            </p>
            <a
              className="theme-modal__button button button-md button-primary"
              href="https://forms.gle/TitoM9AV4ARWyWGW9"
              target="_blank"
              rel="noopener noreferrer"
            >
              Isi survei
            </a>
          </div>
        </Modal.Body>
      </Modal>
      <section className={`styling__theme ${detectMob() ? "px-0" : ""}`}>
        <h2 className="theme--heading-6">
          Tema
          <HelpTooltip>
            Pilihan tema ini dapat membuat tampilan <em>pipehome</em> menjadi
            lebih menarik. Pilih salah satu untuk mengaplikasikan.
          </HelpTooltip>
        </h2>
        <div className="theme__list row" role="list">
          {isLoadingInitialData.value ? (
            <DashboardSkeletonLoader
              className={"mt-3"}
              height={230}
              quantities={detectMob() ? 2 : 6}
              col={detectMob() ? 6 : 2}
            />
          ) : (
            themes.value.map((theme, key) => (
              <div key={key} className="theme__item" role="listitem">
                <div
                  className={`${
                    currentStylingValue.value.settings.theme.name === theme.name
                      ? "selected-theme"
                      : ""
                  } ${theme.is_locked ? "theme--locked" : ""}`}
                  role="button"
                  tabIndex="0"
                  onClick={() => handleSelectTheme(theme)}
                  onKeyDown={e => {
                    if (e.key === "Enter") {
                      handleSelectTheme(theme)
                    }
                  }}
                >
                  <img
                    className={`theme__image ${
                      theme.is_locked && "theme__image--locked"
                    }`}
                    src={theme.url}
                    alt={`Theme ${theme.name}`}
                    loading="lazy"
                  />
                  {theme.is_locked && (
                    <div className="theme__container--locked">
                      <span className="theme__icon--locked mdi mdi-lock mdi-36px"></span>
                      <button
                        className="theme__button--locked button button-sm button-secondary"
                        onClick={() => setIsModalShow(true)}
                        type="button"
                      >
                        Dapatkan Tema
                      </button>
                    </div>
                  )}
                </div>
                <h3 className="theme__name--caption">
                  {theme.name.split("-").join(" ")}
                </h3>
              </div>
            ))
          )}
        </div>
      </section>
    </>
  )
}

export default StylingTheme
