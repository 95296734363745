import React from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { detectMob } from "@src/Helper"

const HelpTooltip = ({ children }) => {
  const tooltip = props => {
    return (
      <Tooltip {...props}>
        <p className="text-start p-1 mb-0">{children}</p>
      </Tooltip>
    )
  }

  return (
    <OverlayTrigger
      placement={`${detectMob() ? "bottom" : "right"}`}
      overlay={tooltip}
    >
      <span className="helper-tooltip mdi mdi-18px mdi-help-circle"></span>
    </OverlayTrigger>
  )
}

export default HelpTooltip
