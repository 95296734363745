import React, { useRef } from "react"
import { createUseStyles } from "react-jss"
import isDarkColor from "is-dark-color"
import { LightenDarkenColor } from "lighten-darken-color"
import { useDashboardContext } from "@services/DashboardContext"
import { handleOpenSidenav, handleGetDashboard, detectMob } from "@src/Helper"
import VanillaLinkLayout from "./VanillaLinkLayout"

const useStyles = createUseStyles({
  buttonStyle: props => {
    const isMonochrome =
      props.button.foreground_color === props.button.background_color // to check if button has the same color as its text
    const isCustom =
      props.button.foreground_color !== null &&
      props.button.background_color !== null // to check if foreground color and background color is customized or not
    const isDark = isCustom ? isDarkColor(props.button.foreground_color) : null // for light/dark color detection
    const colorConstant = 150 // to determine how dark/light the color should be darken/lighten
    const bgColorConstant = 30 // to determine how dark/light the background color should be darken/lighten
    const ifForegroundNull = props.button.background_color // to check if foreground color of a button is customized or following the theme
      ? LightenDarkenColor(
          props.button.background_color,
          isDark ? bgColorConstant : bgColorConstant * -1
        )
      : ""
    const ifBackgroundNull = props.button.foreground_color // to check if background color of a button is customized or following the theme
      ? LightenDarkenColor(
          props.button.foreground_color,
          isDark ? bgColorConstant : bgColorConstant * -1
        )
      : ""

    switch (props?.button.type) {
      case "outline":
        if (!isMonochrome) {
          if (
            props.button?.background_color &&
            props.button?.foreground_color
          ) {
            return {
              borderColor: [[props.button.background_color], "!important"],
              color: [props.button.foreground_color, "!important"],
              "&:hover": {
                background: [props.button.background_color, "!important"],
              },
            }
          } else if (props.button?.background_color) {
            return {
              borderColor: [[props.button.background_color], "!important"],
              color: "initial",
              "&:hover": {
                background: [props.button.background_color, "!important"],
              },
            }
          } else if (props.button?.foreground_color) {
            return {
              borderColor: "initial",
              color: [props.button.foreground_color, "!important"],
              "&:hover": {
                background: "initial",
              },
            }
          }
        } else if (
          props.button.background_color === null &&
          props.button.foreground_color === null
        ) {
          return {
            borderColor: "initial",
            color: "initial",
            "&:hover": {
              background: "initial",
            },
          }
        } else {
          return {
            borderColor: [[props.button.background_color], "!important"],
            color: [props.button.foreground_color, "!important"],
            "&:hover": {
              background: [props.button.background_color, "!important"],
              color: props.button.foreground_color && [
                `${LightenDarkenColor(
                  props.button.foreground_color,
                  isDark ? colorConstant : colorConstant * -1
                )}`,
                "!important",
              ],
            },
          }
        }
      case "double-outline":
        if (!isMonochrome) {
          if (
            props.button?.background_color &&
            props.button?.foreground_color
          ) {
            return {
              borderColor: [[props.button.background_color], "!important"],
              color: [props.button.foreground_color, "!important"],
              "&:before": {
                borderColor: [[props.button.background_color], "!important"],
                outlineWidth: ["0.5px", "!important"],
              },
              "&:hover": {
                background: [props.button.background_color, "!important"],
                color: [props.button.foreground_color, "!important"],
              },
            }
          } else if (props.button?.background_color) {
            return {
              borderColor: [[props.button.background_color], "!important"],
              color: "initial",
              "&:before": {
                borderColor: [[props.button.background_color], "!important"],
                outlineWidth: ["0.5px", "!important"],
              },
              "&:hover": {
                background: [props.button.background_color, "!important"],
                color: "initital",
              },
            }
          } else if (props.button?.foreground_color) {
            return {
              borderColor: "initial",
              color: [props.button.foreground_color, "!important"],
              "&:before": {
                borderColor: "initial",
                outlineWidth: "initial",
              },
              "&:hover": {
                background: "initial",
                color: [props.button.foreground_color, "!important"],
              },
            }
          }
        } else if (
          props.button.background_color === null &&
          props.button.foreground_color === null
        ) {
          return {
            borderColor: "initial",
            color: "initial",
            "&:before": {
              borderColor: "initial",
              outlineWidth: "initial",
            },
            "&:hover": {
              background: "initial",
              color: "initial",
            },
          }
        } else {
          return {
            borderColor: [[props.button.background_color], "!important"],
            color: [props.button.foreground_color, "!important"],
            "&:before": {
              borderColor: [[props.button.background_color], "!important"],
              outlineWidth: ["0.5px", "!important"],
            },
            "&:hover": {
              background: [props.button.background_color, "!important"],
              color: props.button.foreground_color && [
                `${LightenDarkenColor(
                  props.button.foreground_color,
                  isDark ? colorConstant : colorConstant * -1
                )}`,
                "!important",
              ],
            },
          }
        }
      default:
        if (!isMonochrome) {
          if (
            props.button?.background_color &&
            props.button?.foreground_color
          ) {
            return {
              borderColor: [[props.button.background_color], "!important"],
              background: [props.button.background_color, "!important"],
              color: [props.button.foreground_color, "!important"],
              "&:hover": {
                borderColor: `${
                  props.button.foreground_color || ifForegroundNull
                } !important`,
                background: `${
                  props.button.foreground_color || ifForegroundNull
                } !important`,
                color: `${
                  props.button.background_color || ifBackgroundNull
                } !important`,
              },
            }
          } else if (props.button?.background_color) {
            return {
              borderColor: [[props.button.background_color], "!important"],
              background: [props.button.background_color, "!important"],
              color: "initial",
              "&:hover": {
                borderColor: `${
                  props.button.foreground_color || ifForegroundNull
                } !important`,
                background: `${
                  props.button.foreground_color || ifForegroundNull
                } !important`,
                color: `${
                  props.button.background_color || ifBackgroundNull
                } !important`,
              },
            }
          } else if (props.button?.foreground_color) {
            return {
              borderColor: "initial",
              background: "initial",
              color: [props.button.foreground_color, "!important"],
              "&:hover": {
                borderColor: `${
                  props.button.foreground_color || ifForegroundNull
                } !important`,
                background: `${
                  props.button.foreground_color || ifForegroundNull
                } !important`,
                color: `${
                  props.button.background_color || ifBackgroundNull
                } !important`,
              },
            }
          }
        } else if (
          props.button.background_color === null &&
          props.button.foreground_color === null
        ) {
          return {
            borderColor: "initial",
            background: "initial",
            color: "initial",
            "&:hover": {
              borderColor: "initial",
              background: "initial",
              color: "initial",
            },
          }
        } else {
          return {
            borderColor: [[props.button.background_color], "!important"],
            background: [props.button.background_color, "!important"],
            color: props.button.foreground_color && [
              `${LightenDarkenColor(
                props.button.foreground_color,
                isDark ? colorConstant : colorConstant * -1
              )}`,
              "!important",
            ],
            "&:hover": {
              background: props.button.background_color && [
                `${LightenDarkenColor(
                  props.button.background_color,
                  isDark ? bgColorConstant : bgColorConstant * -1
                )}`,
                "!important",
              ],
            },
          }
        }
    }
  },
})

const VanillaLink = React.forwardRef((props, ref) => {
  // Hooks
  const { vanillaLinks, personalize, isLoadingDashboard } =
    useDashboardContext()
  const filteredVanillaLinks = vanillaLinks.value?.filter(
    vanillaLink => vanillaLink.is_active === true
  )

  const classes = useStyles(personalize.value.settings)

  return (
    <section
      id={detectMob() ? "m-vanillaLink" : ""}
      className={`vanilla-link ${detectMob() ? "container" : ""}`}
    >
      <div
        className={`row ${
          filteredVanillaLinks.length === 1 ? "flex-j-center " : ""
        }`}
      >
        {filteredVanillaLinks.length > 0 ? (
          filteredVanillaLinks.map((link, key) => (
            <div className="col-12 col-md-6" key={key}>
              <a
                className={`vanilla-link__button ph-button ph-button--md ph-button--${personalize.value.settings.button.shape} ph-button--${personalize.value.settings.button.type} ${classes.buttonStyle}`}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className="vanilla-link__button--body">{link.label}</p>
                <span className="mdi mdi-arrow-right mdi-24px"></span>
              </a>
            </div>
          ))
        ) : (
          <>
            <div className="col-12 col-md-6">
              <a
                className={`vanilla-link__button ph-button ph-button--md ph-button--${personalize.value.settings.button.shape} ph-button--${personalize.value.settings.button.type} ${classes.buttonStyle}`}
                href="https://www.google.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className="vanilla-link__button--body">Google</p>
                <span className="mdi mdi-arrow-right mdi-24px"></span>
              </a>
            </div>
            <div className="col-12 col-md-6">
              <a
                className={`vanilla-link__button ph-button ph-button--md ph-button--${personalize.value.settings.button.shape} ph-button--${personalize.value.settings.button.type} ${classes.buttonStyle}`}
                href="https://www.google.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className="vanilla-link__button--body">Google</p>
                <span className="mdi mdi-arrow-right mdi-24px"></span>
              </a>
            </div>
          </>
        )}
        {!detectMob() && (
          <button
            ref={node => {
              if (node) {
                if (filteredVanillaLinks.length === 1) {
                  node.style.setProperty(
                    "right",
                    "calc(20% - 35px)",
                    "important"
                  )
                } else {
                  node.style.removeProperty("right")
                }
              }
            }}
            className="vanilla-link__edit-button"
            type="button"
            onClick={() =>
              handleOpenSidenav(
                ref,
                "vanilla-link__layout",
                handleGetDashboard(
                  undefined,
                  undefined,
                  vanillaLinks,
                  isLoadingDashboard
                )
              )
            }
          >
            <span className="mdi mdi-pencil mdi-18px"></span>
          </button>
        )}
      </div>
      <VanillaLinkLayout ref={ref} />
    </section>
  )
})

export default VanillaLink
