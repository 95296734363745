import React from "react"
import { isInputEmpty, isPasswordVisible } from "@src/Helper"

const ChangePasswordInputReenterPassword = ({
  reenterPasswordRef,
  reenterEyeRef,
  reenterPassword,
}) => {
  return (
    <div className="account-settings__layout__password__form__reenter-password">
      <label
        htmlFor="inputReenterPassword"
        className="account-settings__layout__password__form__reenter-password__label label-sm"
      >
        Konfirmasi Kata Sandi Baru
      </label>
      <div className="position-relative">
        <input
          type="password"
          className="account-settings__layout__password__form__reenter-password__input input-md"
          id="inputReenterPassword"
          spellCheck={false}
          placeholder="Masukkan kembali kata sandi baru"
          ref={reenterPasswordRef}
          value={reenterPassword.value}
          onChange={e => {
            isInputEmpty(reenterPasswordRef)
            reenterPassword.setValue(e.target.value)
          }}
        />
        <span
          role="button"
          tabIndex="0"
          aria-label="Show Password"
          className="account-settings__layout__password__form__reenter-password__show mdi mdi-eye-off"
          ref={reenterEyeRef}
          onClick={() => isPasswordVisible(reenterPasswordRef, reenterEyeRef)}
          onKeyDown={e => {
            if (e.key === "Enter")
              isPasswordVisible(reenterPasswordRef, reenterEyeRef)
          }}
        ></span>
      </div>
    </div>
  )
}

export default ChangePasswordInputReenterPassword
