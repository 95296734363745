import React from "react"
import { isInputEmpty, isPasswordVisible } from "@src/Helper"

const ChangePasswordInputNewPassword = ({
  newPasswordRef,
  newEyeRef,
  newPassword,
  errors,
}) => {
  return (
    <div className="account-settings__layout__password__form__new-password">
      <label
        htmlFor="inputNewPassword"
        className="account-settings__layout__password__form__new-password__label label-sm"
      >
        Kata Sandi Baru
      </label>
      <div className="position-relative">
        <input
          type="password"
          className="account-settings__layout__password__form__new-password__input input-md"
          id="inputNewPassword"
          spellCheck={false}
          placeholder="Minimum 6 karakter"
          ref={newPasswordRef}
          value={newPassword.value}
          onChange={e => {
            isInputEmpty(newPasswordRef)
            newPassword.setValue(e.target.value)
          }}
        />
        <span
          role="button"
          tabIndex="0"
          aria-label="Show Password"
          className="account-settings__layout__password__form__new-password__show mdi mdi-eye-off"
          ref={newEyeRef}
          onClick={() => isPasswordVisible(newPasswordRef, newEyeRef)}
          onKeyDown={e => {
            if (e.key === "Enter") isPasswordVisible(newPasswordRef, newEyeRef)
          }}
        ></span>
      </div>
      {!errors.value.hasOwnProperty("new_password") ? (
        <></>
      ) : (
        <span className="error-message caption mb-2">
          {errors.value.new_password[0]}
        </span>
      )}
    </div>
  )
}

export default ChangePasswordInputNewPassword
