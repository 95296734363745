import React, { useRef } from "react"
import _ from "lodash"
import Modal from "react-bootstrap/Modal"
import { useDashboardContext } from "@services/DashboardContext"
import { createCategory } from "@http/DashboardRepository"
import { useInput, isInputEmpty } from "@src/Helper"

const CreateCategoryModal = ({ show, onHide }) => {
  // Hooks
  const {
    personalize,
    feedCategories,
    currentCreatePostValue,
    currentEditPostValue,
  } = useDashboardContext()
  const nameRef = useRef(null)
  const name = useInput("")
  const isLoading = useInput(false)
  const error = useInput("")

  // Custom Function
  const handleCreateCategory = () => {
    isLoading.setValue(true)
    createCategory(personalize.value.id, name.value)
      .then(res => {
        error.setValue("")
        feedCategories.setValue(prevState => [
          ...prevState,
          {
            id: _.takeRight(res.data.categories)[0].id,
            name: _.takeRight(res.data.categories)[0].name,
          },
        ])
        currentCreatePostValue.setValue(prevState => {
          return {
            ...prevState,
            categories: [
              ...prevState.categories,
              {
                id: _.takeRight(res.data.categories)[0].id,
                name: _.takeRight(res.data.categories)[0].name,
              },
            ],
          }
        })
        currentEditPostValue.setValue(prevState => {
          return {
            ...prevState,
            categories: [
              ...prevState.categories,
              {
                id: _.takeRight(res.data.categories)[0].id,
                name: _.takeRight(res.data.categories)[0].name,
              },
            ],
          }
        })
        isLoading.setValue(false)
        onHide()
      })
      .catch(err => {
        if (err.response.status === 422) {
          error.setValue(err.response.data.errors.name[0])
        }
        isLoading.setValue(false)
      })
  }

  // JSX
  return (
    <Modal
      show={show}
      onHide={onHide}
      id="createCategoryModal"
      className="category-modal"
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="category-modal__head">
        <Modal.Title id="contained-modal-title-vcenter">
          <span
            role="button"
            tabIndex="0"
            aria-label="Hide Modal Button"
            className="mdi mdi-36px mdi-close category-modal__close"
            onClick={() => onHide()}
            onKeyDown={e => {
              if (e.key === "Enter") {
                onHide()
              }
            }}
          ></span>
          <h2 className="category-modal--heading-5">Kategori Baru</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="category-modal__body">
        <form
          onSubmit={e => {
            e.preventDefault()
            handleCreateCategory()
          }}
        >
          <div>
            <label className="label-lg category-modal__label" htmlFor="name">
              Nama Kategori
            </label>
            <input
              className="input-lg category-modal__input"
              type="text"
              id="name"
              placeholder="Masukkan nama kategori kamu"
              ref={nameRef}
              onChange={e => {
                isInputEmpty(nameRef)
                name.setValue(e.target.value)
              }}
            />
            {error.value && (
              <span className="error-message caption mb-1">{error.value}</span>
            )}
            <button
              className="category-modal__button button button-md button-primary"
              type="submit"
              disabled={isLoading.value}
            >
              {isLoading.value ? (
                <span
                  className="spinner-border spinner-border-sm ms-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                <>Tambah Kategori</>
              )}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default CreateCategoryModal
