import React, { useEffect, createContext, useContext } from "react"
import { getMe } from "@http/UserRepository"
import { getToken, setUserData } from "@http/client"
import { useInput } from "@src/Helper"

const AppContext = createContext(null)

const AppContextProvider = ({ children }) => {
  // Hooks
  const userData = useInput(null)
  const isLoadingUser = useInput(true)

  useEffect(() => {
    if (getToken()) {
      handleGetMe()
    } else isLoadingUser.setValue(false)
  }, [])

  // Custom Functions
  const handleGetMe = () => {
    getMe()
      .then(res => {
        setUserData(res.data)
        isLoadingUser.setValue(false)
      })
      .catch(err => {
        console.log(err.response)
        isLoadingUser.setValue(false)
      })
  }

  return (
    <AppContext.Provider
      value={{
        userData,
        isLoadingUser,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

const useAppContext = () => {
  return useContext(AppContext)
}

export { AppContextProvider, useAppContext }
