import React from "react"
import { detectMob, isInputEmpty } from "@src/Helper"

const ChangeEmailInputEmail = ({ emailRef, email, error }) => {
  return (
    <div
      className={`account-settings__layout__email__form__new-email ${
        detectMob() ? "col-12" : "col-5"
      }`}
    >
      <label
        htmlFor="inputEmail"
        className="account-settings__layout__email__form__new-email__label label-sm"
      >
        Alamat E-mail Baru
      </label>
      <input
        type="email"
        className="account-settings__layout__email__form__new-email__input input-md"
        id="inputEmail"
        spellCheck={false}
        placeholder="sociopipe@mail.com"
        ref={emailRef}
        value={email.value}
        onChange={e => {
          isInputEmpty(emailRef)
          email.setValue(e.target.value)
        }}
      />
      {error.value === "" ? (
        <></>
      ) : (
        <span className="error-message caption mb-2">
          {error.value.email[0]}
        </span>
      )}
    </div>
  )
}

export default ChangeEmailInputEmail
