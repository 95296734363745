import axios from "axios"
import { navigate } from "gatsby"
import { isSSR } from "../Helper"

const baseURL = process.env.BASE_URL

const httpClient = axios.create({
  baseURL: `${baseURL}/api/sociopipe/`,
})

httpClient.interceptors.response.use(
  response => {
    return response
  },
  error => {
    console.log(error)
    if ([401, 403].includes(error.response.status)) {
      removeToken()
      removeUserData()
      navigate("/login")
    }
    return Promise.reject(error)
  }
)

const getToken = () => !isSSR && localStorage.getItem("access_token")

const setToken = token => !isSSR && localStorage.setItem("access_token", token)

const removeToken = () => !isSSR && localStorage.removeItem("access_token")

// User Data
const getUserData = () =>
  !isSSR && JSON.parse(localStorage.getItem("user_data"))

const setUserData = data =>
  !isSSR && localStorage.setItem("user_data", JSON.stringify(data))

const removeUserData = () => !isSSR && localStorage.removeItem("user_data")

export {
  baseURL,
  httpClient,
  getToken,
  setToken,
  removeToken,
  getUserData,
  setUserData,
  removeUserData,
}
