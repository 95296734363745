import React from "react"
import { isInputEmpty, isPasswordVisible } from "@src/Helper"

const ChangePasswordInputCurrentPassword = ({
  currentPasswordRef,
  currentEyeRef,
  currentPassword,
  errors,
}) => {
  return (
    <div className="account-settings__layout__password__form__current-password">
      <label
        htmlFor="inputCurrentPassword"
        className="account-settings__layout__password__form__current-password__label label-sm"
      >
        Kata Sandi Sekarang
      </label>
      <div className="position-relative">
        <input
          type="password"
          className="account-settings__layout__password__form__current-password__input input-md"
          id="inputCurrentPassword"
          spellCheck={false}
          placeholder="Masukkan kata sandi kamu"
          ref={currentPasswordRef}
          value={currentPassword.value}
          onChange={e => {
            isInputEmpty(currentPasswordRef)
            currentPassword.setValue(e.target.value)
          }}
        />
        <span
          role="button"
          tabIndex="0"
          aria-label="Show Password"
          className="account-settings__layout__password__form__current-password__show mdi mdi-eye-off"
          ref={currentEyeRef}
          onClick={() => isPasswordVisible(currentPasswordRef, currentEyeRef)}
          onKeyDown={e => {
            if (e.key === "Enter")
              isPasswordVisible(currentPasswordRef, currentEyeRef)
          }}
        ></span>
      </div>
      {!errors.value.hasOwnProperty("current_password") ? (
        <></>
      ) : (
        <span className="error-message caption mb-2">
          {errors.value.current_password[0]}
        </span>
      )}
    </div>
  )
}

export default ChangePasswordInputCurrentPassword
