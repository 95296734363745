import React from "react"
import Switch from "react-switch"

const SwitchButton = ({ checked, handleToggle, idx }) => {
  return (
    <Switch
      className="react-switch"
      onChange={() => handleToggle(idx, checked)}
      checked={checked}
      uncheckedIcon={false}
      checkedIcon={false}
      onColor="#fe7a20"
      offColor="#d0d0d0"
      activeBoxShadow={null}
      height={18}
      width={34}
      handleDiameter={16}
      aria-label="Toggle on-off link"
      tabIndex="0"
    />
  )
}

export default SwitchButton
