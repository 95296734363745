import React from "react"
import { Link } from "gatsby"
import { detectMob } from "@src/Helper"
import HelpTooltip from "@components/utils/HelpTooltip"
import { AnalyticsSkeletonLoader } from "@components/utils/SkeletonLoader"
import MediaPostGridItem from "./MediaPostGridItem"

const MediaPostTopThree = ({ posts, isLoadingSkeleton }) => {
  // JSX
  if (detectMob()) {
    return (
      <div className="analytics__layout__media-post">
        <div className="analytics__layout__media-post__header">
          <h3 className="analytics__layout__media-post__title heading-5">
            Analitik Feed{" "}
            <HelpTooltip>
              Menunjukkan berapa total jumlah klik yang diterima oleh link-link
              dari post pada feed.
            </HelpTooltip>
          </h3>
        </div>

        {posts.value.length === 0 && (
          <p className="body mb-0 text-black-30">
            Data analitik tidak ditemukan pada tanggal yang ditentukan.
          </p>
        )}

        <div
          className="analytics__layout__media-post__list row gx-3"
          role="list"
        >
          {posts.value.map((post, key) => {
            return (
              <MediaPostGridItem
                post={post}
                key={key}
                number={key + 1}
                col={4}
              />
            )
          })}
        </div>
        {posts.value?.length !== 0 && (
          <Link
            className="analytics__layout__media-post__view-details"
            activeClassName="active"
            to="media-post"
          >
            Lihat Detail
          </Link>
        )}
      </div>
    )
  }

  return (
    <div className="analytics__layout__media-post">
      <div className="analytics__layout__media-post__header">
        <h3 className="analytics__layout__media-post__title heading-5">
          Analitik Feed{" "}
          <HelpTooltip>
            Menunjukkan berapa total jumlah klik yang diterima oleh link-link
            dari post pada feed.
          </HelpTooltip>
        </h3>

        {posts.value?.length !== 0 && (
          <Link
            className="analytics__layout__media-post__view-details"
            activeClassName="active"
            to="media-post"
          >
            Lihat Detail
          </Link>
        )}
      </div>

      {!isLoadingSkeleton.value && posts.value.length === 0 && (
        <p className="body mb-0 text-black-30">
          Data analitik tidak ditemukan pada tanggal yang ditentukan.
        </p>
      )}

      <div className="analytics__layout__media-post__list row gx-3" role="list">
        {isLoadingSkeleton.value ? (
          <AnalyticsSkeletonLoader
            className={"mt-2 mb-1"}
            quantities={3}
            col={4}
          />
        ) : (
          posts.value.map((post, key) => {
            return (
              <MediaPostGridItem
                post={post}
                key={key}
                number={key + 1}
                col={4}
              />
            )
          })
        )}
      </div>
    </div>
  )
}

export default MediaPostTopThree
