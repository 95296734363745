import React, { useRef, useEffect } from "react"
import { toast } from "react-toastify"
import { useDashboardContext } from "@services/DashboardContext"
import {
  detectMob,
  useInput,
  handleCloseSidenav,
  handleSelectCropFile,
  handleGetDashboard,
} from "@src/Helper"
import { updateCoverImage } from "@http/DashboardRepository"
import { DashboardSkeletonLoader } from "@components/utils/SkeletonLoader"
import HelpTooltip from "@components/utils/HelpTooltip"
import CoverImageModal from "./CoverImageCropper"

const CoverImageLayout = React.forwardRef((props, ref) => {
  // Hooks
  const {
    personalize,
    coverImages,
    isLoadingInitialData,
    isLoadingDashboard,
    currentSelection,
  } = useDashboardContext()
  const upImg = useInput()
  const isSubmitLoading = useInput(false)
  const isModalOpen = useInput(false)
  const inputFile = useRef(null)

  useEffect(() => {
    handleCloseSidenav(ref, "cover-image__layout", handleSetCurrentSelection)
  }, [personalize.value])

  // Custom Function
  const handleSetCurrentSelection = () => {
    currentSelection.setValue(personalize.value.pipehome_cover_image_id)
  }

  const handleSelectCoverImage = coverImage => {
    currentSelection.setValue(coverImage)
  }

  const handleUpdateCoverImage = () => {
    isSubmitLoading.setValue(true)
    updateCoverImage(personalize.value.id, currentSelection.value)
      .then(() => {
        personalize.setValue(prevState => {
          return {
            ...prevState,
            pipehome_cover_image_id: currentSelection.value,
          }
        })
        toast.success("Berhasil menyimpan perubahan!")
        handleGetDashboard(
          personalize,
          undefined,
          undefined,
          isLoadingDashboard
        )
      })
      .catch(err => console.log(err.response))
      .finally(() => {
        isSubmitLoading.setValue(false)
      })
  }

  const handleTriggerInput = () => {
    inputFile.current.click()
  }

  // JSX
  return (
    <div
      ref={ref}
      className={`customization__layout cover-image__layout ${
        detectMob()
          ? "cover-image__layout--slide-down"
          : "cover-image__layout--expand"
      }`}
    >
      <CoverImageModal
        show={isModalOpen.value}
        onHide={() => isModalOpen.setValue(false)}
        upImg={upImg.value}
      />
      <form
        onSubmit={e => {
          e.preventDefault()
          handleUpdateCoverImage()
        }}
      >
        <div className="cover-image__header">
          <div className="cover-image__header--left">
            <span
              className="mdi mdi-close mdi-24px"
              onClick={() =>
                handleCloseSidenav(
                  ref,
                  "cover-image__layout",
                  handleSetCurrentSelection
                )
              }
            ></span>
            <h2 className="cover-image__header-title--heading-5">
              <em>Cover Image</em>
              <HelpTooltip>
                <em>Cover Image</em> terletak di bagian paling atas dari tema.
                Lengkapi tema kamu dengan salah satu <em>Cover Image</em>{" "}
                menarik dari kami ini.
              </HelpTooltip>
            </h2>
          </div>
          <div className="layout__header--right cover-image__header--right">
            <button
              className={`button button-md button-secondary ${
                detectMob() ? "w-100" : ""
              }`}
              type="submit"
              disabled={isSubmitLoading.value}
            >
              {isSubmitLoading.value ? (
                <span
                  className="spinner-border spinner-border-sm ms-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                <>Simpan Perubahan</>
              )}
            </button>
          </div>
        </div>
        <div className="cover-image__body">
          <div className={`container ${detectMob() ? "pad-b-90 px-3" : ""}`}>
            <div className="cover-image__list row" role="list">
              {isLoadingInitialData.value ? (
                <DashboardSkeletonLoader
                  className={"mb-3"}
                  height={detectMob() ? "88px" : "100%"}
                  quantities={detectMob() ? 10 : 12}
                  col={detectMob() ? 6 : 4}
                />
              ) : (
                <>
                  <div
                    className="cover-image--none col-6 col-xl-4"
                    role="listitem"
                  >
                    <div
                      className={`cover-image__container--none ${
                        currentSelection.value === null
                          ? "cover-image--selected"
                          : ""
                      }`}
                      role="button listitem"
                      tabIndex="0"
                      onClick={() => handleSelectCoverImage(null)}
                      onKeyDown={e => {
                        if (e.key === "Enter") {
                          handleSelectCoverImage(null)
                        }
                      }}
                    >
                      <span className="cover-image__icon--none mdi mdi-image-off-outline"></span>
                      <p className="cover-image--caption">
                        Tidak Ada <em>Cover Image</em>
                      </p>
                    </div>
                  </div>
                  <div
                    className="cover-image__cropper col-6 col-xl-4"
                    role="listitem"
                  >
                    {coverImages.value?.custom ? (
                      <div className="cover-image__container">
                        <span
                          className="cover-image__edit-icon mdi mdi-camera"
                          onClick={() => handleTriggerInput()}
                        ></span>
                        <input
                          className="cover-image__edit-input"
                          ref={inputFile}
                          type="file"
                          accept="image/png, image/jpeg"
                          onChange={e =>
                            handleSelectCropFile(
                              e,
                              [
                                "Maksimum ukuran ",
                                <strong>
                                  <em>Cover Image</em>
                                </strong>,
                                " adalah 5MB.",
                              ],
                              isModalOpen,
                              upImg
                            )
                          }
                          onClick={e => (e.target.value = null)}
                        />
                        <div
                          className="cover-image__placeholder-container"
                          role="button"
                          tabIndex="0"
                          onClick={() =>
                            handleSelectCoverImage(coverImages.value.custom.id)
                          }
                          onKeyDown={e => {
                            if (e.key === "Enter") {
                              handleSelectCoverImage(
                                coverImages.value.custom.id
                              )
                            }
                          }}
                        >
                          <img
                            className={`cover-image__placeholder ${
                              currentSelection.value ===
                              coverImages.value.custom.id
                                ? "cover-image--selected"
                                : ""
                            }`}
                            src={coverImages.value.custom.url}
                            alt="Custom Cover Image"
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="cover-image__border--dashed">
                        <div className={detectMob() ? "flex-col" : "flex"}>
                          <span>Unggah Gambar </span>
                          <span className="mdi mdi-image-outline"></span>
                        </div>
                        <input
                          className="cover-image__input"
                          type="file"
                          accept="image/png, image/jpeg"
                          onChange={e =>
                            handleSelectCropFile(
                              e,
                              [
                                "Maksimum ukuran ",
                                <strong>
                                  <em>Cover Image</em>
                                </strong>,
                                " adalah 5MB.",
                              ],
                              isModalOpen,
                              upImg
                            )
                          }
                          onClick={e => (e.target.value = null)}
                        />
                      </div>
                    )}
                  </div>
                  {coverImages.value.predefined?.map((coverImage, key) => {
                    return (
                      <div
                        key={key}
                        className="cover-image__item col-6 col-xl-4"
                        role="listitem"
                      >
                        <img
                          className={`cover-image__photo ${
                            currentSelection.value === coverImage.id
                              ? "cover-image--selected"
                              : ""
                          }`}
                          src={coverImage.url}
                          alt={`Cover Image ${key}`}
                          role="button"
                          tabIndex="0"
                          onClick={() => handleSelectCoverImage(coverImage.id)}
                          onKeyDown={e => {
                            if (e.key === "Enter") {
                              handleSelectCoverImage(coverImage.id)
                            }
                          }}
                          loading="lazy"
                        />
                      </div>
                    )
                  })}
                </>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  )
})

export default CoverImageLayout
