import { getToken, httpClient as client } from "./client"

export const getAnalytics = (page_id, start_at, end_at) => {
  return new Promise((resolve, reject) => {
    client
      .get(`page/${page_id}/analytic`, {
        params: { start_at, end_at },
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .then(res => resolve(res))
      .catch(err => reject(err))
  })
}

export const getAnalyticsPosts = (page_id, current_page, start_at, end_at) => {
  return new Promise((resolve, reject) => {
    client
      .get(`page/${page_id}/analytic/posts`, {
        params: { start_at, end_at, page: current_page },
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .then(res => resolve(res))
      .catch(err => reject(err))
      .finally(() => resolve())
  })
}

export const getAnalyticsPostLinks = (page_id, post_id, start_at, end_at) => {
  return new Promise((resolve, reject) => {
    client
      .get(`page/${page_id}/analytic/post/${post_id}/links`, {
        params: { start_at, end_at },
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .then(res => resolve(res))
      .catch(err => reject(err))
      .finally(() => resolve())
  })
}
