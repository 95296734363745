import React, { useRef } from "react"
import Masonry from "react-masonry-css"
import { createUseStyles } from "react-jss"
import isDarkColor from "is-dark-color"
import { LightenDarkenColor } from "lighten-darken-color"
import { useDashboardContext } from "@services/DashboardContext"
import { handleOpenSidenav, handleGetDashboard, detectMob } from "@src/Helper"
import FeedLayout from "./FeedLayout"

const useStyles = createUseStyles({
  buttonStyle: props => {
    const isMonochrome =
      props.button.foreground_color === props.button.background_color // to check if button has the same color as its text
    const isCustom =
      props.button.foreground_color !== null &&
      props.button.background_color !== null // to check if foreground color and background color is customized or not
    const isDark = isCustom ? isDarkColor(props.button.background_color) : null // for light/dark color detection
    const colorConstant = 150 // to determine how dark/light the color should be darken/lighten

    if (isCustom) {
      if (!isMonochrome) {
        if (props.button?.background_color && props.button?.foreground_color) {
          return {
            background: [props.button.background_color, "!important"],
            borderColor: [[props.button.background_color], "!important"],
            color: [props.button.foreground_color, "!important"],
            "&:hover": {
              background: [props.button.foreground_color, "!important"],
              borderColor: [[props.button.foreground_color], "!important"],
              color: [props.button.background_color, "!important"],
            },
          }
        }
      } else {
        return {
          background: ["transparent", "!important"],
          borderColor: [[props.button.background_color], "!important"],
          color: [`${props.button.foreground_color}`, "!important"],
          "&:hover": {
            background: [`${props.button.foreground_color}`, "!important"],
            borderColor: [[props.button.foreground_color], "!important"],
            color: props.button.foreground_color && [
              `${LightenDarkenColor(
                props.button.foreground_color,
                isDark ? colorConstant : colorConstant * -1
              )}`,
              "!important",
            ],
          },
        }
      }
    } else {
      return {
        background: "initial",
        borderColor: "initial",
        color: "initial",
        "&:hover": {
          background: "initial",
          borderColor: "initial",
          color: "initial",
        },
      }
    }
    return {}
  },
  activeButton: props => {
    const isMonochrome =
      props.button.foreground_color === props.button.background_color // to check if button has the same color as its text
    const isCustom =
      props.button.foreground_color !== null &&
      props.button.background_color !== null // to check if foreground color and background color is customized or not
    const isDark = isCustom ? isDarkColor(props.button.background_color) : null // for light/dark color detection
    const colorConstant = 150 // to determine how dark/light the color should be darken/lighten

    if (isCustom) {
      if (!isMonochrome) {
        if (props.button?.background_color && props.button?.foreground_color) {
          return {
            backgroundColor: [`${props.button.foreground_color}`, "!important"],
            color: [`${props.button.background_color}`, "!important"],
          }
        } else if (props.button?.background_color) {
          return {
            backgroundColor: "initial",
            color: [`${props.button.background_color}`, "!important"],
          }
        } else if (props.button?.foreground_color) {
          return {
            backgroundColor: [`${props.button.foreground_color}`, "!important"],
            color: "initial",
          }
        }
      } else {
        return {
          backgroundColor: [`${props.button.foreground_color}`, "!important"],
          color: props.button.background_color && [
            `${LightenDarkenColor(
              props.button.background_color,
              isDark ? colorConstant : colorConstant * -1
            )}`,
            "!important",
          ],
        }
      }
    } else {
      return {
        backgroundColor: "initial",
        color: "initial",
      }
    }
  },
  textStyle: {
    color: props => [`${props.font.color}`, "!important"],
  },
  tileStyle: props => {
    const isMonochrome =
      props.post.foreground_color === props.post.background_color // to check if button has the same color as its text
    const isCustom =
      props.post.foreground_color !== null &&
      props.post.background_color !== null // to check if foreground color and background color is customized or not
    const isDark = isCustom ? isDarkColor(props.post.foreground_color) : null // for light/dark color detection
    const colorConstant = 150 // to determine how dark/light the color should be darken/lighten
    const bgColorConstant = 30 // to determine how dark/light the background color should be darken/lighten

    if (!isMonochrome) {
      if (props.post?.background_color && props.post?.foreground_color) {
        return {
          background: [props.post.background_color, "!important"],
          color: [props.post.foreground_color, "!important"],
        }
      } else if (props.post?.background_color) {
        return {
          background: [props.post.background_color, "!important"],
          color: "initial",
        }
      } else if (props.post?.foreground_color) {
        return {
          background: "initial",
          color: [props.post.foreground_color, "!important"],
        }
      } else {
        return null
      }
    } else if (
      props.post.foreground_color === null &&
      props.post.background_color === null
    ) {
      return {
        background: "initial",
        color: "initial",
      }
    } else {
      return {
        background: [props.post.background_color, "!important"],
        color: props.post.foreground_color && [
          LightenDarkenColor(
            props.post.foreground_color,
            isDark ? colorConstant : colorConstant * -1
          ),
          "!important",
        ],
      }
    }
  },
})

const Feed = React.forwardRef((props, ref) => {
  // Hooks
  const { personalize, feedCategories, postList, isLoadingDashboard } =
    useDashboardContext()

  const classes = useStyles(personalize.value.settings)

  // Variable
  const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    280: 1,
  }

  // JSX
  return (
    <section
      id={detectMob() ? "m-feed" : ""}
      className={`feed ${detectMob() ? "container" : ""}`}
    >
      <div className="feed__name-container">
        <h2
          className={`feed__name--heading-4 ${
            personalize.value.settings.font?.color ? classes.textStyle : ""
          }`}
        >
          {personalize.value.feed_title}
        </h2>
        {!detectMob() && (
          <button
            className="feed__edit-button"
            type="button"
            onClick={() =>
              handleOpenSidenav(
                ref,
                "feed__layout",
                handleGetDashboard(
                  undefined,
                  undefined,
                  undefined,
                  isLoadingDashboard
                )
              )
            }
          >
            <span className="mdi mdi-pencil mdi-18px"></span>
          </button>
        )}
      </div>
      <div className="category">
        {feedCategories.value.map((category, key) => (
          <button
            className={`category__button ${classes.buttonStyle} ${
              key !== 0 ? `category__button--outline` : classes.activeButton
            } ph-button ph-button--sm ph-button--capsule`}
            key={key}
          >
            <p className="category__button--caption">{category.name}</p>
          </button>
        ))}
      </div>
      <Masonry breakpointCols={breakpointColumnsObj} className="feed__grid">
        {postList.value.posts?.slice(0, 6).map((post, key) => (
          <div
            className={`card card__tile ${
              post.is_highlight ? "card__tile--highlight" : ""
            }`}
            key={key}
          >
            <img className="card__image" src={post.media_url} alt={post.name} />
            <h3 className={`card__title--caption ${classes.tileStyle}`}>
              {post.name}
            </h3>
          </div>
        ))}
      </Masonry>
      <h4 className="feed__preview--body">
        Preview ini hanya menampilkan maksimal 6 post. Cek{" "}
        <a
          href={`${process.env.PIPEHOME_URL}/${personalize.value.username}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Pipehome
        </a>{" "}
        kamu untuk tampilan lengkapnya.
      </h4>
      <FeedLayout ref={ref} />
    </section>
  )
})

export default Feed
