import React, { useRef } from "react"
import { useInput } from "@src/Helper"
import { changeUsername } from "@http/UserRepository"
import { AlertSuccess } from "@components/utils/Alert"
import ChangeUsernameInputUsername from "./ChangeUsernameInputUsername"

const ChangeUsername = ({ currentUsername, pageId }) => {
  // Hooks
  const usernameRef = useRef(null)
  const username = useInput("")
  const error = useInput("")
  const isLoading = useInput(false)
  const isSuccess = useInput(false)

  // Custom Function
  const handleChangeUsername = () => {
    isLoading.setValue(true)
    changeUsername(username.value, pageId.value)
      .then(() => {
        isLoading.setValue(false)
        isSuccess.setValue(true)
        currentUsername.setValue(username.value)
        username.setValue("")
        error.setValue("")
      })
      .catch(err => {
        isLoading.setValue(false)
        isSuccess.setValue(false)
        if (err.response.data.status_code === 422) {
          error.setValue(err.response.data.errors)
        }
      })
  }

  // JSX
  return (
    <div className="account-settings__layout__username">
      {isSuccess.value && (
        <AlertSuccess
          className="mx-0 flex-a-center"
          text={<p className="body mb-0">Berhasil mengubah username.</p>}
        />
      )}
      <h2 className="account-settings__layout__username__title heading-5">
        Ubah Username
      </h2>
      <form
        className="account-settings__layout__username__form"
        onSubmit={e => {
          e.preventDefault()
          handleChangeUsername()
        }}
      >
        <div className="row">
          <ChangeUsernameInputUsername
            usernameRef={usernameRef}
            username={username}
            currentUsername={currentUsername.value}
            error={error}
          />
        </div>
        <p className="account-settings__layout__username__form__current-username__info caption">
          Username Sekarang: {currentUsername.value}
        </p>
        <button
          type="submit"
          className="account-settings__layout__username__form__new-username__button button button-md button-primary"
          disabled={isLoading.value}
        >
          {isLoading.value ? (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            <>Ubah Username</>
          )}
        </button>
      </form>
    </div>
  )
}

export default ChangeUsername
