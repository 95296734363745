import React, { useEffect } from "react"
import _ from "lodash"
import { toast } from "react-toastify"
import { useDashboardContext } from "@services/DashboardContext"
import {
  detectMob,
  handleLink,
  handleAddHttpPrefix,
  useInput,
  handleCloseSidenav,
} from "@src/Helper"
import { updateSocialLinks } from "@http/DashboardRepository"
import SocialLinkItem from "./SocialLinkItem"
import Loading from "@components/utils/Loading"

const SocialLinkLayout = React.forwardRef((props, ref) => {
  // Hooks
  const { personalize, socialMediaLinks, isLoadingDashboard } =
    useDashboardContext()
  const currentSocialLinks = useInput()
  const isSubmitLoading = useInput(false)
  const errors = useInput({})

  useEffect(() => {
    currentSocialLinks.setValue(socialMediaLinks.value)
  }, [socialMediaLinks])

  // Variable
  let socialLinks = [
    {
      label: "Alamat e-mail",
      social_type: "email",
      url: "",
      placeholder: "e.g.sociopipe@domain.com",
      is_active: true,
    },
    {
      label: "Instagram",
      social_type: "instagram",
      url: "",
      placeholder: "e.g.https://www.instagram.com/sociopipe",
      is_active: true,
    },
    {
      label: "Tiktok",
      social_type: "tiktok",
      url: "",
      placeholder: "e.g.https://www.tiktok.com/@sociopipe",
      is_active: true,
    },
    {
      label: "Facebook",
      social_type: "facebook",
      url: "",
      placeholder: "e.g.https://www.facebook.com/sociopipe",
      is_active: true,
    },
    {
      label: "Twitter",
      social_type: "twitter",
      url: "",
      placeholder: "e.g.https://www.twitter.com/sociopipe",
      is_active: true,
    },
    {
      label: "LinkedIn",
      social_type: "linkedin",
      url: "",
      placeholder: "e.g.https://www.linkedin.com/in/sociopipe",
      is_active: true,
    },
    {
      label: "Youtube",
      social_type: "youtube",
      url: "",
      placeholder: "e.g.https://www.youtube.com/channel/sociopipe",
      is_active: true,
    },
    {
      label: "Twitch",
      social_type: "twitch",
      url: "",
      placeholder: "e.g.https://www.twitch.tv/sociopipe",
      is_active: true,
    },
    {
      label: "SnapChat",
      social_type: "snapchat",
      url: "",
      placeholder: "e.g.https://www.snapchat.com/@sociopipe",
      is_active: true,
    },
    {
      label: "ClubHouse",
      social_type: "clubhouse",
      url: "",
      placeholder: "e.g.https://www.clubhouse.com/@sociopipe",
      is_active: true,
    },
    {
      label: "Pinterest",
      social_type: "pinterest",
      url: "",
      placeholder: "e.g.https://www.pinterest.com/sociopipe",
      is_active: true,
    },
    {
      label: "Medium",
      social_type: "medium",
      url: "",
      placeholder: "e.g.https://www.medium.com/@sociopipe",
      is_active: true,
    },
    {
      label: "Quora",
      social_type: "quora",
      url: "",
      placeholder: "e.g.https://www.quora.com/profile/sociopipe",
      is_active: true,
    },
  ]

  socialLinks = socialLinks.map(link => {
    const filterLinks = _.find(currentSocialLinks.value, [
      "social_type",
      link.social_type,
    ])
    const socialLinksClone = { ...link }
    if (typeof filterLinks != "undefined") {
      link = filterLinks
      link.placeholder = socialLinksClone.placeholder
      link.label = socialLinksClone.label
      link.image = socialLinksClone.image
    }
    return link
  })

  let data = [...socialLinks]

  // Custom Functions
  const handleUpdateSocialLinks = () => {
    let socialLinks = currentSocialLinks.value?.filter(link => {
      return link.url !== ""
    })

    isSubmitLoading.setValue(true)
    updateSocialLinks(personalize.value.id, socialLinks)
      .then(res => {
        socialMediaLinks.setValue(res.data.links)
        isSubmitLoading.setValue(false)
        handleCloseSidenav(ref, "social-link__layout", () =>
          errors.setValue({})
        )
        toast.success("Berhasil menyimpan perubahan!")
      })
      .catch(err => {
        if (err.response.status === 422) {
          const validationError = err.response.data.errors
          let newErrors = {}
          currentSocialLinks.value?.map((link, idx) => {
            const socialLinkIndex = _.findIndex(socialLinks, {
              social_type: link.social_type,
            })

            if (socialLinkIndex !== -1) {
              const errors = _.pickBy(validationError, (ve, key) => {
                return _.startsWith(key, `links.${socialLinkIndex}`)
              })
              if (!_.isEmpty(errors)) {
                for (const key in errors) {
                  const newKey = _.replace(key, socialLinkIndex, idx)
                  newErrors = { ...newErrors, [newKey]: errors[key] }
                }
              }
            }
          })
          errors.setValue(newErrors)
        }
        console.log(err.response)
        isSubmitLoading.setValue(false)
        toast.error("Terdapat kesalahan pengisian data. Silahkan cek kembali.")
      })
  }

  const handleToggleLink = (idx, value) => {
    handleLink(idx, data, !value, "is_active", currentSocialLinks)
  }

  const handleChangeUrl = (idx, value) => {
    handleLink(idx, data, value, "url", currentSocialLinks)
  }

  const handleBlurUrl = (idx, value) => {
    handleAddHttpPrefix(idx, data, value, "url", currentSocialLinks, true)
  }

  // JSX
  return (
    <div
      ref={ref}
      className={`customization__layout social-link__layout ${
        detectMob()
          ? "social-link__layout--slide-down"
          : "social-link__layout--expand"
      }`}
    >
      <form
        onSubmit={e => {
          e.preventDefault()
          handleUpdateSocialLinks()
        }}
      >
        <div className="social-link__header">
          <div className="social-link__header--left">
            <span
              className="mdi mdi-close mdi-24px"
              onClick={() =>
                handleCloseSidenav(ref, "social-link__layout", () =>
                  errors.setValue({})
                )
              }
            ></span>
            <h2 className="social-link__header-title--heading-5">
              <em>Social Media</em>
            </h2>
          </div>
          <div className="layout__header--right social-link__header--right">
            <button
              className={`button button-md button-secondary ${
                detectMob() ? "w-100" : ""
              }`}
              type="submit"
              disabled={isSubmitLoading.value}
            >
              {isSubmitLoading.value ? (
                <span
                  className="spinner-border spinner-border-sm ms-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                <>Simpan Perubahan</>
              )}
            </button>
          </div>
        </div>
        {isLoadingDashboard.value ? (
          <Loading />
        ) : (
          <div className="social-link__body">
            <div className={`container ${detectMob() ? "pad-b-90" : ""}`}>
              <div className="social-link__list" role="list">
                {socialLinks.map((socialLink, key) => {
                  let socialIcon = socialLink.social_type
                  if (socialIcon === "email") {
                    socialIcon = "maildotru"
                  }

                  return (
                    <SocialLinkItem
                      id={key}
                      socialIcon={socialIcon}
                      socialLink={socialLink}
                      handleToggleLink={handleToggleLink}
                      handleChangeUrl={handleChangeUrl}
                      handleBlurUrl={handleBlurUrl}
                      errors={errors}
                    />
                  )
                })}
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  )
})

export default SocialLinkLayout
