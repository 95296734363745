import React, { useRef, useEffect } from "react"
import { toast } from "react-toastify"
import { useDashboardContext } from "@services/DashboardContext"
import DefaultImg from "@images/dashboard/img/upload-gambar.png"

const ImageUpload = ({ className, isEdit }) => {
  // Hooks
  const { currentCreatePostValue, currentEditPostValue } = useDashboardContext()
  const image = useRef()
  const input = useRef()

  const post = isEdit
    ? currentEditPostValue.value
    : currentCreatePostValue.value

  useEffect(() => {
    handleUpdateCurrentPost("pictureUrl", post.imageInput)
  }, [post.imageInput])

  useEffect(() => {
    if (post && post.imageInput === "") {
      handleUpdateCurrentPost("pictureUrl", post.pictureUrl)
    }
  }, [])

  // Custom Function
  const handleUpdateCurrentPost = (key, value) => {
    const currentPost = isEdit ? currentEditPostValue : currentCreatePostValue
    currentPost.setValue(prevState => {
      return {
        ...prevState,
        [key]: value,
      }
    })
  }

  const insertImage = e => {
    if (e.target.files[0]?.size > 5120000) {
      toast.error("Maksimum ukuran gambar post adalah 5MB.")
    } else {
      handleUpdateCurrentPost("imageInput", "")
      image.current.src = ""
      const newData = {
        file: e.target.files[0],
        fileUrl: URL.createObjectURL(e.target.files[0]),
      }
      handleUpdateCurrentPost("imageInput", newData)
      input.current.value = ""
      image.current.classList.add("image-filled")
    }
  }

  return (
    <>
      <input
        ref={input}
        className={`${className}__image-input form-control`}
        type="file"
        accept="image/png, image/jpeg"
        onChange={e => insertImage(e)}
      />
      <div className={`${className}__image-container`}>
        {isEdit ? (
          <img
            ref={image}
            className={`edit-post__image-placeholder ${
              post.pictureUrl !== "" ? "image-filled" : ""
            }`}
            src={
              post.pictureUrl?.fileUrl
                ? post.pictureUrl.fileUrl
                : post.pictureUrl
            }
            alt="Upload Post"
            onError={e => {
              e.target.onerror = null
              e.target.src = DefaultImg
              e.target.classList.remove("image-filled")
            }}
          />
        ) : (
          <img
            ref={image}
            className="create-post__image-placeholder"
            src={
              post.pictureUrl?.fileUrl
                ? post.pictureUrl.fileUrl
                : post?.pictureUrl
                ? post.pictureUrl
                : DefaultImg
            }
            alt="Upload Post"
            onError={e => {
              e.target.onerror = null
              e.target.src = DefaultImg
              e.target.classList.remove("image-filled")
            }}
          />
        )}
      </div>
    </>
  )
}

export default ImageUpload
