import React from "react"
import ReactPaginate from "react-paginate"

const MediaPostGridPagination = ({
  page,
  pageCount,
  totalItem,
  pageRangeDisplayed,
  marginPagesDisplayed,
  handleOnPageChange,
}) => {
  if (totalItem === 0) {
    return <></>
  }

  return (
    <ReactPaginate
      containerClassName="analytics__media-post__pagination"
      pageClassName="analytics__media-post__pagination__page"
      previousClassName="analytics__media-post__pagination__previous"
      nextClassName="analytics__media-post__pagination__next"
      pageCount={pageCount}
      forcePage={page}
      pageRangeDisplayed={pageRangeDisplayed}
      marginPagesDisplayed={marginPagesDisplayed}
      previousLabel={<span className="mdi mdi-chevron-left mdi-24px" />}
      nextLabel={<span className="mdi mdi-chevron-right mdi-24px" />}
      onPageChange={handleOnPageChange}
    />
  )
}

export default MediaPostGridPagination
