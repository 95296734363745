import React from "react"
import _ from "lodash"
import { useDashboardContext } from "@services/DashboardContext"
import { getPost } from "@http/DashboardRepository"
import { detectMob } from "@src/Helper"

const FeedPost = ({ post, idx, handleOpenPostLayout, editPostLayoutRef }) => {
  // Hooks
  const { personalize, feedCategories, currentEditPostValue, isLoadingPost } =
    useDashboardContext()

  // Variables
  const categories = feedCategories.value.slice(1)

  // Custom Function
  const handleGetPost = () => {
    isLoadingPost.setValue(true)
    getPost(personalize.value.id, post.id)
      .then(res => {
        currentEditPostValue.setValue(prevState => {
          return {
            ...prevState,
            id: res.data.post.id,
            name: res.data.post.name,
            caption: res.data.post.caption,
            pictureUrl: res.data.post.media_url,
            isHighlight: res.data.post.is_highlight,
            categories: [...prevState.categories, ...categories],
            links: res.data.post.post_links,
            selectedCategory: res.data.post.category?.id ?? 0,
          }
        })
        isLoadingPost.setValue(false)
      })
      .catch(err => {
        console.log(err.response)
        isLoadingPost.setValue(false)
      })
  }

  // JSX
  return (
    <div
      className="post-item"
      role="button"
      tabIndex={0}
      onClick={() => {
        handleOpenPostLayout(editPostLayoutRef, "edit-post")
        handleGetPost()
      }}
    >
      <img
        className="post-item__image"
        src={post.media_url}
        alt={post.name}
        loading={`${idx === 0 || idx === 1 || idx === 2 ? "eager" : "lazy"}`}
      />
      <span className="post-item__label--caption">
        {post.category?.name ?? "Tidak ada Kategori"}
      </span>
      <p className="post-item__title--body">
        {_.truncate(post.name, { length: detectMob() ? 10 : 18 })}
      </p>
      <div className="post__edit-button" role="button" tabIndex="0">
        <span className="post__edit-icon mdi mdi-24px mdi-pencil"></span>
      </div>
    </div>
  )
}

export default FeedPost
