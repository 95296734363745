import React, { useRef, useEffect } from "react"
import { handleFocus, useInput } from "@src/Helper"
import SwitchButton from "@components/utils/SwitchButton"

const VanillaLinkItem = ({
  id,
  vanillaLink,
  handleDeleteLink,
  handleToggleLink,
  handleChangeName,
  handleChangeUrl,
  handleBlurUrl,
  errors,
}) => {
  // Hooks
  const nameRef = useRef(null)
  const urlRef = useRef(null)
  const active = useInput(vanillaLink.is_active)
  const label = useInput(vanillaLink.label)
  const url = useInput(vanillaLink.url)
  const focusName = useInput(false)
  const focusUrl = useInput(false)

  useEffect(() => {
    active.setValue(vanillaLink.is_active)
    label.setValue(vanillaLink.label)
    url.setValue(vanillaLink.url)
  }, [vanillaLink])

  // JSX
  return (
    <div className="vanilla-link__item" role="listitem" key={id}>
      <div
        className={`vanilla-link__item--left ${
          !active.value ? "vanilla-link--disabled" : ""
        }`}
      >
        <div
          className={`vanilla-link__name-container ${
            errors.value[`links.${id}.label`] ? "mb-0" : "mb-1"
          }`}
        >
          <input
            ref={nameRef}
            className={`vanilla-link__name-input--body input--hidden ${
              focusName.value ? "input-visible" : ""
            }`}
            type="text"
            defaultValue={label.value}
            onChange={e => {
              handleChangeName(id, e.target.value)
            }}
            onBlur={() => focusName.setValue(false)}
          />
          <div
            className={`${focusName.value ? "d-none" : ""}`}
            role="button"
            tabIndex="0"
            onClick={() => {
              handleFocus(nameRef)
              focusName.setValue(true)
            }}
            onKeyDown={e => {
              if (e.key === "Enter") {
                handleFocus(nameRef)
                focusName.setValue(true)
              }
            }}
          >
            <h3
              className={`vanilla-link__name-text--body ${
                label.value !== "" ? "text-700 text-black-90" : ""
              }`}
            >
              {label.value === "" ? "Masukkan Nama Link..." : label.value}
            </h3>
            <span className="vanilla-link__name-edit-icon mdi mdi-pencil"></span>
          </div>
        </div>
        {errors.value[`links.${id}.label`] && (
          <span className="error-message caption mt-0">
            {errors.value[`links.${id}.label`][0]}
          </span>
        )}
        <div className="vanilla-link__url-container">
          <span className="vanilla-link__url-icon mdi mdi-18px mdi-link-variant"></span>
          <input
            ref={urlRef}
            className={`vanilla-link__url-input--body input--hidden ${
              focusUrl.value ? "input-visible" : ""
            }`}
            type="text"
            defaultValue={url.value}
            onChange={e => {
              handleChangeUrl(id, e.target.value)
            }}
            onBlur={e => {
              handleBlurUrl(id, e.target.value)
              focusUrl.setValue(false)
            }}
          />
          <div
            className={`${focusUrl.value ? "d-none" : ""}`}
            role="button"
            tabIndex="0"
            onClick={() => {
              handleFocus(urlRef)
              focusUrl.setValue(true)
            }}
            onKeyDown={e => {
              if (e.key === "Enter") {
                handleFocus(urlRef)
                focusUrl.setValue(true)
              }
            }}
          >
            <h4
              className={`vanilla-link__url-text--body ${
                url.value !== "" ? "text-black-90" : ""
              }`}
            >
              {url.value === "" ? "https://www.sociopipe.com" : url.value}
            </h4>
            <span
              className="vanilla-link__url-edit-icon mdi mdi-pencil"
              role="button"
              tabIndex="0"
              aria-label="Edit URL"
            ></span>
          </div>
        </div>
        {errors.value[`links.${id}.url`] && (
          <span className="error-message caption mt-0">
            {errors.value[`links.${id}.url`][0]}
          </span>
        )}
      </div>
      <div className="vanilla-link__item--right">
        <SwitchButton
          checked={active.value}
          handleToggle={handleToggleLink}
          idx={id}
        />
        <span
          className="vanilla-link__delete-icon mdi mdi-24px mdi-trash-can"
          role="button"
          tabIndex="0"
          onClick={() => handleDeleteLink(id)}
          onKeyDown={e => {
            if (e.key === "Enter") handleDeleteLink(id)
          }}
          aria-label="Delete Link"
        ></span>
      </div>
    </div>
  )
}

export default VanillaLinkItem
