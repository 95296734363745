import React, { useRef } from "react"
import { detectMob, useInput } from "@src/Helper"
import { AlertSuccess } from "@components/utils/Alert"
import { changePassword } from "@http/EmailPasswordRepository"
import ChangePasswordInputCurrentPassword from "./ChangePasswordInputCurrentPassword"
import ChangePasswordInputNewPassword from "./ChangePasswordInputNewPassword"
import ChangePasswordInputReenterPassword from "./ChangePasswordInputReenterPassword"

const ChangePassword = () => {
  // Hooks
  const currentPasswordRef = useRef(null)
  const newPasswordRef = useRef(null)
  const reenterPasswordRef = useRef(null)
  const currentEyeRef = useRef(null)
  const newEyeRef = useRef(null)
  const reenterEyeRef = useRef(null)
  const currentPassword = useInput("")
  const newPassword = useInput("")
  const reenterPassword = useInput("")
  const isLoading = useInput(false)
  const isSuccess = useInput(false)
  const errors = useInput({})

  // Custom Function
  const handleChangePassword = () => {
    isLoading.setValue(true)
    changePassword(
      currentPassword.value,
      newPassword.value,
      reenterPassword.value
    )
      .then(() => {
        isLoading.setValue(false)
        isSuccess.setValue(true)
        currentPassword.setValue("")
        newPassword.setValue("")
        reenterPassword.setValue("")
        errors.setValue({})
      })
      .catch(err => {
        isLoading.setValue(false)
        isSuccess.setValue(false)
        if (err.response.data.status_code === 422) {
          errors.setValue(err.response.data.errors)
        }
      })
  }

  // JSX
  return (
    <div className="account-settings__layout__password">
      {isSuccess.value && (
        <AlertSuccess
          className="mx-0 flex-a-center"
          text={<p className="body mb-0">Berhasil mengubah kata sandi.</p>}
        />
      )}
      <h2 className="account-settings__layout__password__title heading-5">
        Ubah Kata Sandi
      </h2>
      <form
        className="account-settings__layout__password__form row"
        onSubmit={e => {
          e.preventDefault()
          handleChangePassword()
        }}
      >
        <div className={`${detectMob() ? "col-12" : "col-5"}`}>
          <ChangePasswordInputCurrentPassword
            currentPasswordRef={currentPasswordRef}
            currentEyeRef={currentEyeRef}
            currentPassword={currentPassword}
            errors={errors}
          />
          <ChangePasswordInputNewPassword
            newPasswordRef={newPasswordRef}
            newEyeRef={newEyeRef}
            newPassword={newPassword}
            errors={errors}
          />
          <ChangePasswordInputReenterPassword
            reenterPasswordRef={reenterPasswordRef}
            reenterEyeRef={reenterEyeRef}
            reenterPassword={reenterPassword}
          />
          <button
            type="submit"
            className="account-settings__layout__password__form__new-password__button button button-md button-primary"
            disabled={isLoading.value}
          >
            {isLoading.value ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              <>Ubah Kata Sandi</>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export default ChangePassword
