import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { navigate, Router } from "@reach/router"
import { ToastContainer } from "react-toastify"
import { detectMob, useIsClient, useInput } from "@src/Helper"
import { getToken } from "@http/client"
import { AnalyticsContextProvider } from "@services/AnalyticsContext"
import { DashboardContextProvider } from "@services/DashboardContext"
import { AppContextProvider } from "@services/AppContext"
import { Header } from "@components/Header/Header"
import { Footer } from "@components/Footer/Footer"
import SplashScreen from "@components/utils/SplashScreen"
import Dashboard from "../pages/dashboard"
import AccountSettings from "../pages/account-settings"
import Analytics from "../pages/analytics"
import AnalyticsMediaPost from "../pages/analytics/media-post"
import AnalyticsMediaPostLinkPage from "./Analytics/MediaPost/Link/MediaPostLink"
import "./layout.css"

const Layout = ({ location, children }) => {
  // Variables
  const { key } = useIsClient()
  const authClientRoute = [/^\/login/, /^\/register/]
  const protectedClientRoute = [
    /^\/analytics/,
    /^\/dashboard/,
    /^\/account-settings/,
  ]

  const authClientMatch = authClientRoute.filter(cr => {
    return location.pathname.match(RegExp(cr))
  })
  const protectedRouteMatch = protectedClientRoute.filter(cr => {
    return location.pathname.match(RegExp(cr))
  })

  // Hooks
  const token = useInput(getToken())

  useEffect(() => {
    if (token.value && authClientMatch.length > 0) {
      navigate("/dashboard")
    }
  }, [token.value])

  // JSX
  return (
    <AppContextProvider>
      <SplashScreen />
      <div id="layout" key={key}>
        <ToastContainer
          position="top-center"
          theme="colored"
          autoClose={5000}
          icon={true}
          hideProgressBar={true}
          pauseOnHover={false}
          closeButton={false}
          style={{ maxWidth: "750px" }}
        />
        <Header location={location} />
        <main
          className={`${
            detectMob() && protectedRouteMatch.length > 0
              ? "black-10-bg"
              : "pure-white-bg"
          }`}
        >
          {protectedRouteMatch.length > 0 ? (
            <>
              <AnalyticsContextProvider>
                <Router>
                  <Analytics path="analytics" />
                  <AnalyticsMediaPost path="analytics/media-post" />
                  <AnalyticsMediaPostLinkPage path="analytics/media-post/:postId" />
                </Router>
              </AnalyticsContextProvider>
              <DashboardContextProvider>
                <Router>
                  <Dashboard path="dashboard" location={location} />
                </Router>
              </DashboardContextProvider>
              <Router>
                <AccountSettings path="account-settings" />
              </Router>
            </>
          ) : (
            children
          )}
        </main>
        <Footer />
      </div>
    </AppContextProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
