import React, { useEffect, useState } from "react"
import { getDashboard } from "@http/DashboardRepository"
import { getMe } from "@http/UserRepository"
import { useInput } from "@src/Helper"
import Loading from "@components/utils/Loading"
import AccountSettingsTabBar from "./AccountSettingsTabBar"
import ChangeEmail from "./Email/ChangeEmail"
import ChangePassword from "./Password/ChangePassword"
import ChangeUsername from "./Username/ChangeUsername"

const AccountSettingsLayout = () => {
  // Custom Functions
  const handleGetDashboard = () => {
    getDashboard()
      .then(res => {
        currentUsername.setValue(res.data.page.username)
        pageId.setValue(res.data.page.id)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const handleGetMe = () => {
    getMe()
      .then(res => {
        currentEmail.setValue(res.data.data.email)
      })
      .catch(err => {
        console.log(err)
      })
  }

  // Hooks
  const [activeTab, setActiveTab] = useState(0)
  const isLoading = useInput(true)
  const currentUsername = useInput("")
  const currentEmail = useInput("")
  const pageId = useInput("")

  useEffect(() => {
    isLoading.setValue(true)
    handleGetDashboard()
    handleGetMe()
  }, [])

  useEffect(() => {
    if (currentUsername.value !== "" && currentEmail.value !== "") {
      isLoading.setValue(false)
    }
  }, [currentUsername.value, currentEmail.value])

  // JSX
  if (isLoading.value) {
    return <Loading />
  }

  return (
    <section className="account-settings__layout">
      <AccountSettingsTabBar
        setActiveTab={setActiveTab}
        activeTab={activeTab}
      />
      {activeTab === 0 ? (
        <ChangeUsername currentUsername={currentUsername} pageId={pageId} />
      ) : activeTab === 1 ? (
        <ChangeEmail currentEmail={currentEmail} />
      ) : (
        <ChangePassword />
      )}
    </section>
  )
}

export default AccountSettingsLayout
