import React from "react"
import TimePicker from "rc-time-picker"
import { useDashboardContext } from "@services/DashboardContext"

// Global Variable
const format = "HH:mm"

export const TimePickerFrom = () => {
  // Hooks
  const { currentPersonalizationValue } = useDashboardContext()

  // Function
  const handleChangeFrom = value => {
    currentPersonalizationValue.setValue(prevState => {
      return {
        ...prevState,
        working_hour_start_at: value,
      }
    })
  }

  // JSX
  return (
    <TimePicker
      className="personalization__hour-from-input input input-md"
      showSecond={false}
      getPopupContainer={triggerNode => triggerNode.parentNode}
      value={currentPersonalizationValue.value?.working_hour_start_at}
      onChange={handleChangeFrom}
      format={format}
      use12Hours={false}
      minuteStep={5}
      allowEmpty={false}
    />
  )
}

export const TimePickerUntil = () => {
  // Hooks
  const { currentPersonalizationValue } = useDashboardContext()

  // Function
  const handleChangeUntil = value => {
    currentPersonalizationValue.setValue(prevState => {
      return {
        ...prevState,
        working_hour_end_at: value,
      }
    })
  }

  // JSX
  return (
    <TimePicker
      className="personalization__hour-until-input input input-md"
      showSecond={false}
      getPopupContainer={triggerNode => triggerNode.parentNode}
      value={currentPersonalizationValue.value?.working_hour_end_at}
      onChange={handleChangeUntil}
      format={format}
      use12Hours={false}
      minuteStep={5}
      allowEmpty={false}
    />
  )
}
