import React from "react"
import ContentLoader from "react-content-loader"
import { detectMob } from "@src/Helper"

export const DashboardSkeletonLoader = ({
  height,
  quantities,
  col,
  className,
  style,
}) => {
  return [...Array(quantities)].map((qty, key) => (
    <div
      className={`cover-image__skeleton col-${col} ${className}`}
      style={style}
      key={key}
    >
      <ContentLoader
        speed={1}
        backgroundColor="#d0d0d0"
        foregroundColor="#b9b9b9"
        width={"100%"}
        height={height}
      >
        <rect x="0" y="0" rx="10" ry="10" width={"100%"} height={height} />
      </ContentLoader>
    </div>
  ))
}

export const AnalyticsSkeletonLoader = ({ quantities, col, className }) => {
  return [...Array(quantities)].map((qty, key) => (
    <div className={`col-${col} ${className}`}>
      <ContentLoader
        key={key}
        speed={1}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        width={"100%"}
        height={detectMob() ? 150 : 180}
      >
        <rect
          x="0"
          y="0"
          rx="0"
          ry="0"
          width={"100%"}
          height={detectMob() ? 80 : 114}
        />
        <rect
          x="0"
          y={detectMob() ? "90" : "124"}
          rx="5"
          ry="5"
          width={detectMob() ? "50%" : "35%"}
          height={detectMob() ? 18 : 20}
        />
        <rect
          x="0"
          y={detectMob() ? "118" : "154"}
          rx="5"
          ry="5"
          width={detectMob() ? "100%" : "65%"}
          height={detectMob() ? 18 : 20}
        />
      </ContentLoader>
    </div>
  ))
}

export const AnalyticDetailLoader = ({ quantities, className }) => {
  return [...Array(quantities)].map((qty, key) => (
    <>
      <div className={`col-5 ${className}`}>
        <ContentLoader
          key={key}
          speed={1}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          width="100%"
          height={165}
        >
          <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
        </ContentLoader>
      </div>
      <div className={`col-7 ${className}`}>
        <ContentLoader
          key={key}
          speed={1}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          width="100%"
          height={170}
        >
          <rect x="0" y="0" rx="5" ry="5" width="50%" height={32.5} />
          <rect x="0" y="52" rx="5" ry="5" width="97.5%" height={20} />
          <rect x="0" y="82" rx="5" ry="5" width="97.5%" height={20} />
          <rect x="0" y="112" rx="5" ry="5" width="97.5%" height={20} />
          <rect x="0" y="142" rx="5" ry="5" width="65%" height={20} />
        </ContentLoader>
      </div>
    </>
  ))
}

export const LinksLoader = ({ quantities, className }) => {
  return [...Array(quantities)].map((qty, key) => (
    <div className="row mb-2 flex jc-between">
      <div className={`col-8 ${className}`}>
        <ContentLoader
          key={key}
          speed={1}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          width={"100%"}
          height={55}
        >
          <rect x="0" y="0" rx="5" ry="5" width={"40%"} height={20} />
          <rect x="0" y="30" rx="5" ry="5" width={"75%"} height={20} />
        </ContentLoader>
      </div>
      <div className={`col-2 ${className}`}>
        <ContentLoader
          key={key}
          speed={1}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          width={"100%"}
          height={55}
        >
          <rect x="0" y="10" rx="5" ry="5" width={"100%"} height={27.5} />
        </ContentLoader>
      </div>
    </div>
  ))
}

export const TotalClickLoader = ({ quantities }) => {
  return [...Array(quantities)].map((qty, key) => (
    <ContentLoader
      key={key}
      speed={1}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      width={75}
      height={27.5}
    >
      <rect x="0" y="0" rx="5" ry="5" width={"100%"} height={"100%"} />
    </ContentLoader>
  ))
}
