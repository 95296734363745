import React, { useRef, useEffect } from "react"
import { toast } from "react-toastify"
import moment from "moment"
import { useDashboardContext } from "@services/DashboardContext"
import {
  detectMob,
  isInputEmpty,
  useInput,
  handleCloseSidenav,
  handleGetDashboard,
} from "@src/Helper"
import { updateProfile } from "@http/DashboardRepository"
import Loading from "@components/utils/Loading"
import { TimePickerFrom, TimePickerUntil } from "@components/utils/TimePicker"
import HelpTooltip from "@components/utils/HelpTooltip"

const PersonalizationLayout = React.forwardRef((props, ref) => {
  // Hooks
  const {
    personalize,
    types,
    isLoadingInitialData,
    isLoadingDashboard,
    currentPersonalizationValue,
  } = useDashboardContext()
  const bioRef = useRef(null)
  const titleRef = useRef(null)
  const error = useInput({})
  const isSubmitLoading = useInput(false)

  const personalization = currentPersonalizationValue.value

  useEffect(() => {
    isInputEmpty(bioRef)
    isInputEmpty(titleRef)
  }, [personalization.title, personalization.description])

  useEffect(() => {
    handleCloseSidenav(
      ref,
      "personalization__layout",
      handleResetCurrentPersonalization
    )
  }, [personalize.value])

  // Custom Function
  const handleResetCurrentPersonalization = () => {
    currentPersonalizationValue.setValue(prevState => {
      return {
        ...prevState,
        title: personalize.value.title,
        feed_title: personalize.value.feed_title,
        description: personalize.value.description,
        working_hour_start_at: moment.unix(
          personalize.value.working_hour_start_at
        ),
        working_hour_end_at: moment.unix(personalize.value.working_hour_end_at),
        settings: {
          will_show_working_hour:
            personalize.value.settings.will_show_working_hour,
        },
        pipehome_type_id: personalize.value.pipehome_type_id,
      }
    })
    error.setValue({})
  }

  const handleUpdateCurrentPersonalization = (key, value) => {
    currentPersonalizationValue.setValue(prevState => {
      return {
        ...prevState,
        [key]: value,
      }
    })
  }

  const handleUpdatePersonalization = () => {
    error.setValue({ ...error.value })
    isSubmitLoading.setValue(true)

    updateProfile(
      personalize.value.id,
      personalization.title,
      personalization.description,
      moment(personalization.working_hour_start_at, "HH:mm").unix(),
      moment(personalization.working_hour_end_at, "HH:mm").unix(),
      personalization.settings.will_show_working_hour,
      personalization.pipehome_type_id
    )
      .then(() => {
        error.setValue({})
        personalize.setValue(prevState => {
          return {
            ...prevState,
            title: personalization.title,
            description: personalization.description,
            working_hour_start_at: moment(
              personalization.working_hour_start_at
            ).unix(),
            working_hour_end_at: moment(
              personalization.working_hour_end_at
            ).unix(),
            settings: {
              ...prevState.settings,
              will_show_working_hour:
                personalization.settings.will_show_working_hour,
            },
            pipehome_type_id: personalization.pipehome_type_id,
          }
        })
        toast.success("Berhasil menyimpan perubahan!")
        handleGetDashboard(
          personalize,
          undefined,
          undefined,
          isLoadingDashboard
        )
      })
      .catch(err => {
        if (err.response.data.status_code === 422) {
          error.setValue({
            ...error.value,
            title: err.response.data.errors.title
              ? err.response.data.errors.title
              : null,
            description: err.response.data.errors.description
              ? err.response.data.errors.description
              : null,
            pipehome_type_id: err.response.data.errors.pipehome_type_id
              ? err.response.data.errors.pipehome_type_id
              : null,
          })
        }
        console.log(err.response)
      })
      .finally(() => {
        isSubmitLoading.setValue(false)
      })
  }

  // JSX
  return (
    <div
      ref={ref}
      className={`customization__layout personalization__layout ${
        detectMob()
          ? "personalization__layout--slide-down"
          : "personalization__layout--expand"
      }`}
    >
      <form
        onSubmit={e => {
          e.preventDefault()
          handleUpdatePersonalization()
        }}
      >
        <div className="personalization__header">
          <div className="personalization__header--left">
            <span
              className="mdi mdi-close mdi-24px"
              onClick={() =>
                handleCloseSidenav(
                  ref,
                  "personalization__layout",
                  handleResetCurrentPersonalization
                )
              }
            ></span>
            <h2 className="personalization__header-title--heading-5">
              <em>Edit Profile</em>
            </h2>
          </div>
          <div className="layout__header--right personalization__header--right">
            <button
              className={`button button-md button-secondary ${
                detectMob() ? "w-100" : ""
              }`}
              type="submit"
              disabled={isSubmitLoading.value}
            >
              {isSubmitLoading.value ? (
                <span
                  className="spinner-border spinner-border-sm ms-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                <>Simpan Perubahan</>
              )}
            </button>
          </div>
        </div>
        {isLoadingInitialData.value && <Loading />}
        <div
          className={`personalization__body ${
            isLoadingInitialData.value ? "d-none" : ""
          }`}
        >
          <div className="personalization__list row" role="list">
            <div
              className={`personalization__title col-12 ${
                detectMob() ? "px-0" : ""
              }`}
              role="listitem"
            >
              <h3 className="personalization__title--heading-6">Profil Saya</h3>
              <label
                htmlFor="inputTitle"
                className="personalization__title-label label-sm"
              >
                Nama
              </label>
              <input
                type="text"
                className="personalization__title-input input-md"
                id="inputTitle"
                spellCheck={false}
                placeholder={`${
                  (personalization?.title === "" || !personalization?.title) &&
                  "Masukkan nama ..."
                }`}
                ref={titleRef}
                value={personalization?.title ? personalization.title : ""}
                onChange={e => {
                  isInputEmpty(titleRef)
                  handleUpdateCurrentPersonalization("title", e.target.value)
                }}
              />
              {error.value.title && (
                <span className="error-message caption mb-1">
                  {error.value.title}
                </span>
              )}
            </div>
            <div
              className={`personalization__bio col-12 ${
                detectMob() ? "px-0" : ""
              }`}
              role="listitem"
            >
              <label
                htmlFor="inputBio"
                className="personalization__bio-label label-sm"
              >
                Bio Saya
              </label>
              <textarea
                className="personalization__bio-input textarea-md"
                id="inputBio"
                rows={`${detectMob() ? "5" : "3"}`}
                cols="75"
                spellCheck={false}
                placeholder={`${
                  (personalization?.description === "" ||
                    !personalization?.description) &&
                  "Masukkan bio kamu ..."
                }`}
                ref={bioRef}
                value={
                  personalization?.description
                    ? personalization.description
                    : ""
                }
                onChange={e => {
                  isInputEmpty(bioRef)
                  handleUpdateCurrentPersonalization(
                    "description",
                    e.target.value.replace(/  +/g, " ").trimStart()
                  )
                }}
              />
              {error.value.description && (
                <span className="error-message caption mb-1">
                  {error.value.description}
                </span>
              )}
              <span className="personalization__bio--caption">
                {personalization?.description
                  ? personalization.description.length
                  : "0"}
                /150 karakter
              </span>
            </div>
            <div
              className={`personalization__hour col-12 ${
                detectMob() ? "px-0" : ""
              }`}
              role="listitem"
            >
              <h3 className="personalization__hour--heading-6">
                Jam Aktif{" "}
                <HelpTooltip>
                  Jam Aktif menandakan rentang waktu dimana kamu dapat
                  memberikan respon lebih cepat dibandingkan waktu lainnya.
                </HelpTooltip>
              </h3>
              <div className={`flex-a-center mb-2 ${detectMob() ? "row" : ""}`}>
                <div
                  className={`personalization__hour-from ${
                    detectMob() ? "col-6 pe-1" : ""
                  }`}
                >
                  <label
                    htmlFor="inputActiveFrom"
                    className="personalization__hour-from-label label-sm"
                  >
                    Aktif Dari
                  </label>
                  <div className="position-relative">
                    <TimePickerFrom />
                  </div>
                </div>
                <div
                  className={`personalization__hour-until ${
                    detectMob() ? "col-6 ps-1 ms-0" : ""
                  }`}
                >
                  <label
                    htmlFor="inputActiveUntil"
                    className="personalization__hour-until-label label-sm"
                  >
                    Aktif Hingga
                  </label>
                  <div className="position-relative">
                    <TimePickerUntil />
                  </div>
                </div>
              </div>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="personalization__hour-check-input form-check-input"
                  id="inputCheck"
                  checked={personalization?.settings.will_show_working_hour}
                  onChange={() => {
                    handleUpdateCurrentPersonalization("settings", {
                      will_show_working_hour:
                        !personalization?.settings.will_show_working_hour,
                    })
                  }}
                />
                <label
                  className="personalization__hour-check-label--caption"
                  htmlFor="inputCheck"
                >
                  Tampilkan jam aktif di pipehome saya
                </label>
              </div>
            </div>
            <div
              className={`personalization__category col-12 ${
                detectMob() ? "px-0" : ""
              }`}
              role="listitem"
            >
              <h2 className="personalization__category--heading-6">Kategori</h2>
              <select
                id="inputCategory"
                className="personalization__category-input input-md form-select"
                aria-label="Select Pipehome Category"
                value={personalization?.pipehome_type_id}
                onChange={e =>
                  handleUpdateCurrentPersonalization(
                    "pipehome_type_id",
                    e.target.value
                  )
                }
              >
                <option key={-1} value={0}>
                  Pilih Kategori
                </option>
                {types.value?.map((type, key) => (
                  <option key={key} value={type.id}>
                    {type.name}
                  </option>
                ))}
              </select>
              {error.value.pipehome_type_id && (
                <span className="error-message caption mb-1">
                  {error.value.pipehome_type_id}
                </span>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  )
})

export default PersonalizationLayout
