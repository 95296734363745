import React, { useRef, useEffect } from "react"
import { detectMob, isInputEmpty } from "@src/Helper"

const PostLink = ({
  className,
  link,
  idx,
  linkError,
  handleAddTitle,
  handleAddUrl,
  handleDeleteLink,
}) => {
  // Hooks
  const titleRef = useRef(null)
  const urlRef = useRef(null)

  useEffect(() => {
    isInputEmpty(titleRef)
    isInputEmpty(urlRef)
  }, [])

  // JSX
  if (detectMob()) {
    return (
      <div className={`${className}__link-item row mt-0`} role="listitem">
        <div className={`${className}__link-title-container flex-col`}>
          <label
            htmlFor="inputTitle"
            className={`${className}__link-title-label label-sm`}
          >
            Judul Link {idx + 1}
            <sup>
              <strong className="text-cherry-50">*</strong>
            </sup>
          </label>
          <input
            type="text"
            className={`${className}__link-title-input ${
              detectMob() ? "input-md" : "input-sm"
            }`}
            id="inputTitle"
            spellCheck={false}
            placeholder="Kunjungi produk saya"
            ref={titleRef}
            value={link.label}
            onChange={e => {
              isInputEmpty(titleRef)
              handleAddTitle(idx, e.target.value)
            }}
          />
          {linkError.value[`post_links.${idx}.label`] ? (
            <span
              className={`${className}__link-title-error error-message caption mt-0`}
            >
              {linkError.value[`post_links.${idx}.label`][0]}
            </span>
          ) : (
            <span className={`${className}__link-title-error`}></span>
          )}
        </div>
        <div className={`${className}__link-url-container flex-col`}>
          <label
            htmlFor="inputUrl"
            className={`${className}__link-url-label label-sm`}
          >
            Link URL {idx + 1}
            <sup>
              <strong className="text-cherry-50">*</strong>
            </sup>
          </label>
          <input
            type="text"
            className={`${className}__link-url-input ${
              detectMob() ? "input-md" : "input-sm"
            }`}
            id="inputUrl"
            spellCheck={false}
            placeholder="https://www.instagram.com/sociopipe"
            ref={urlRef}
            value={link.url}
            onChange={e => {
              isInputEmpty(urlRef)
              handleAddUrl(idx, e.target.value)
            }}
          />
          {linkError.value[`post_links.${idx}.url`] ? (
            <span
              className={`${className}__link-url-error error-message caption mt-0`}
            >
              {linkError.value[`post_links.${idx}.url`][0]}
            </span>
          ) : (
            <span className={`${className}__link-url-error`}></span>
          )}
        </div>
        {idx !== 0 && (
          <button
            className={`${className}__delete-link-button button button-sm button-danger`}
            type="button"
            onClick={() => {
              handleDeleteLink(idx)
            }}
          >
            <span className="mdi mdi-trash-can me-1"></span> Delete Link
          </button>
        )}
        <hr className="post__link--divider" />
      </div>
    )
  }

  return (
    <div className={`${className}__link-item row`} role="listitem">
      <div className={`${className}__link-label-container`}>
        <label
          htmlFor="inputTitle"
          className={`${className}__link-title-label label-sm`}
        >
          Judul Link {idx + 1}
          <sup>
            <strong className="text-cherry-50">*</strong>
          </sup>
        </label>
        <label
          htmlFor="inputUrl"
          className={`${className}__link-url-label label-sm`}
        >
          Link URL {idx + 1}
          <sup>
            <strong className="text-cherry-50">*</strong>
          </sup>
        </label>
      </div>
      <div className={`${className}__link-input-container`}>
        <input
          type="text"
          className={`${className}__link-title-input input-sm`}
          id="inputTitle"
          spellCheck={false}
          placeholder="Kunjungi produk saya"
          ref={titleRef}
          value={link.label}
          onChange={e => {
            isInputEmpty(titleRef)
            handleAddTitle(idx, e.target.value)
          }}
        />
        <input
          type="text"
          className={`${className}__link-url-input input-sm`}
          id="inputUrl"
          spellCheck={false}
          placeholder="https://www.instagram.com/sociopipe"
          ref={urlRef}
          value={link.url}
          onChange={e => {
            isInputEmpty(urlRef)
            handleAddUrl(idx, e.target.value)
          }}
        />
        {idx !== 0 && (
          <button
            className={`${className}__delete-link-button button button-sm button-danger`}
            type="button"
            onClick={() => {
              handleDeleteLink(idx)
            }}
          >
            <span className="mdi mdi-18px mdi-trash-can me-1"></span> Delete
            Link
          </button>
        )}
      </div>
      {(linkError.value[`post_links.${idx}.label`] ||
        linkError.value[`post_links.${idx}.url`]) && (
        <div className={`${className}__link-error-container`}>
          {linkError.value[`post_links.${idx}.label`] ? (
            <span
              className={`${className}__link-title-error error-message caption mt-0`}
            >
              {linkError.value[`post_links.${idx}.label`][0]}
            </span>
          ) : (
            <span className={`${className}__link-title-error`}></span>
          )}
          {linkError.value[`post_links.${idx}.url`] ? (
            <span
              className={`${className}__link-url-error error-message caption mt-0`}
            >
              {linkError.value[`post_links.${idx}.url`][0]}
            </span>
          ) : (
            <span className={`${className}__link-url-error`}></span>
          )}
        </div>
      )}
    </div>
  )
}

export default PostLink
