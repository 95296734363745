import React, { useEffect } from "react"
import { Link } from "gatsby"
import { format } from "date-fns"
import id from "date-fns/locale/id"
import { getAnalyticsPostLinks } from "@http/AnalyticsRepositories"
import { getDashboard } from "@http/DashboardRepository"
import { useLocation } from "@reach/router"
import { detectMob, useInput } from "@src/Helper"
import { useAnalyticsContext } from "@services/AnalyticsContext"
import { AlertInfo } from "@components/utils/Alert"
import Loading from "@components/utils/Loading"
import HelpTooltip from "@components/utils/HelpTooltip"
import {
  LinksLoader,
  TotalClickLoader,
  AnalyticDetailLoader,
} from "@components/utils/SkeletonLoader"
import AnalyticsFilterDate from "../../AnalyticsFilterDate"
import AnalyticsLinkItem from "../../AnalyticsLinkItem"

const MediaPostLinkLayout = ({ postId }) => {
  // Hooks
  const location = useLocation()
  const isFilterButtonLoading = useInput(false)
  const isContentLoading = useInput(false)
  const pageId = useInput(location.state?.post.pipehome_page_id)
  const post = useInput(location.state?.post)
  const isShowCalendar = useInput(false)
  const { startDate, endDate } = useAnalyticsContext()

  useEffect(() => {
    if (post.value == undefined) {
      isContentLoading.setValue(true)
      handleGetDashboard()
    }
  }, [])

  useEffect(() => {
    handleGetAnalyticsPostLinks()
  }, [pageId.value])

  // Custom Function
  const handleFilterDate = () => {
    isFilterButtonLoading.setValue(true)
    isContentLoading.setValue(true)
    handleGetAnalyticsPostLinks()
  }

  const handleGetDashboard = () => {
    getDashboard()
      .then(res => {
        pageId.setValue(res.data.page.id)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const handleGetAnalyticsPostLinks = () => {
    if (!pageId.value) return
    getAnalyticsPostLinks(pageId.value, postId, startDate.value, endDate.value)
      .then(res => {
        post.setValue(res.data.post)
        isShowCalendar.setValue(false)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        isContentLoading.setValue(false)
        isFilterButtonLoading.setValue(false)
        isShowCalendar.setValue(false)
      })
  }

  // JSX
  if (detectMob()) {
    return (
      <div className="analytics-media-post-link__layout">
        <div className="row mx-0">
          {isShowCalendar.value ? (
            <div className="analytics__filter col-12">
              <AnalyticsFilterDate
                startDate={startDate}
                endDate={endDate}
                isShowCalendar={isShowCalendar}
              />

              <button
                className="analytics__filter__button"
                onClick={handleFilterDate}
                disabled={isFilterButtonLoading.value}
              >
                {isFilterButtonLoading.value ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  <>Filter Analytic</>
                )}
              </button>
            </div>
          ) : (
            <div className="analytics-media-post-link__content col-12">
              <div className="analytics-media-post-link__header mb-3">
                <Link
                  className="analytics-media-post-link__back"
                  to={location.state?.prevPath ?? `/analytics`}
                >
                  <span className="mdi mdi-arrow-left"></span> Kembali
                </Link>
                <AlertInfo
                  className="mx-0 mt-3 flex-a-center"
                  text={
                    <p className="body mb-0">
                      Kamu melihat laporan dari tanggal{" "}
                      <strong>
                        {format(startDate.value, "dd/MM/yyyy", {
                          locale: id,
                        })}{" "}
                        - {format(endDate.value, "dd/MM/yyyy", { locale: id })}
                      </strong>
                      .
                    </p>
                  }
                />
                <button
                  className="button-calendar button button-md button-secondary"
                  type="button"
                  onClick={e => {
                    e.preventDefault()
                    isShowCalendar.setValue(true)
                  }}
                >
                  <span className="mdi mdi-18px mdi-calendar-month"></span>{" "}
                  Filter Analitik
                </button>
              </div>
              {isContentLoading.value ? (
                <Loading />
              ) : (
                <div className="analytics-media-post-link__post">
                  <div className="row">
                    <div className="col-12">
                      <img src={post.value?.media_url} alt={post.value?.name} />
                    </div>
                    <div className="col-12 mt-3">
                      <h1 className="analytics-media-post-link__post__name heading-4">
                        {post.value?.name}
                      </h1>
                      <p className="analytics-media-post-link__post__caption body">
                        {post.value?.caption}
                      </p>
                    </div>
                  </div>
                  <div className="section-divider"></div>
                  <h5 className="heading-5 text-cobalt-80">
                    Total Klik Link{" "}
                    <HelpTooltip>
                      Menunjukkan total klik pada link di <em>pipehome</em> kamu
                      dalam rentang waktu 24 jam.
                    </HelpTooltip>
                  </h5>
                  {post.value?.post_links.map(link => (
                    <AnalyticsLinkItem
                      label={link.label}
                      url={link.url}
                      clickCount={link.analytics_count}
                    />
                  ))}
                  <div className="d-flex justify-content-between mt-3">
                    <p className="subhead text-700">Total Klik</p>
                    <p className="subhead text-700">
                      {post.value?.analytics_count} Klik
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="analytics-media-post-link__layout">
      <div className="row">
        <div className="analytics-media-post-link__content col-8">
          <div className="analytics-media-post-link__header mb-3">
            <Link
              className="analytics-media-post-link__back"
              to={location.state?.prevPath ?? `/analytics`}
            >
              <span className="mdi mdi-arrow-left"></span> Kembali
            </Link>
          </div>
          <div className="analytics-media-post-link__post">
            <div className="row">
              {isContentLoading.value ? (
                <AnalyticDetailLoader quantities={1} />
              ) : (
                <>
                  <div className="col-5">
                    <img src={post.value?.media_url} alt={post.value?.name} />
                  </div>
                  <div className="col-7">
                    <h1 className="analytics-media-post-link__post__name heading-4">
                      {post.value?.name}
                    </h1>
                    <p className="analytics-media-post-link__post__caption body">
                      {post.value?.caption}
                    </p>
                  </div>
                </>
              )}
            </div>
            <div className="section-divider"></div>
            <h5 className="heading-5 text-cobalt-80">
              Total Klik Link{" "}
              <HelpTooltip>
                Menunjukkan total klik pada link di <em>pipehome</em> kamu dalam
                rentang waktu 24 jam.
              </HelpTooltip>
            </h5>
            {isContentLoading.value ? (
              <LinksLoader className={"mt-2 mb-1"} quantities={1} />
            ) : (
              post.value?.post_links.map(link => (
                <AnalyticsLinkItem
                  label={link.label}
                  url={link.url}
                  clickCount={link.analytics_count}
                />
              ))
            )}
            <div className="d-flex justify-content-between mt-3">
              <p className="subhead text-700">Total Klik</p>
              {isContentLoading.value ? (
                <TotalClickLoader />
              ) : (
                <p className="subhead text-700">
                  {post.value?.analytics_count} Klik
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="analytics__filter col-4">
          <AnalyticsFilterDate startDate={startDate} endDate={endDate} />

          <button
            className="analytics__filter__button"
            onClick={handleFilterDate}
            disabled={isFilterButtonLoading.value}
          >
            {isFilterButtonLoading.value ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              <>Filter Analytic</>
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

export default MediaPostLinkLayout
