import React from "react"
import _ from "lodash"
import { detectMob } from "@src/Helper"

const AnalyticsLinkItem = ({ label, url, clickCount }) => {
  // JSX
  if (detectMob()) {
    return (
      <div className="analytics__link__item">
        <div className="analytics__link__item-container">
          <p className="analytics__link__item__title body">
            {_.truncate(label, { length: 50 })}
          </p>
        </div>
        <p className="analytics__link__item__click-count body">
          {clickCount} Klik
        </p>
      </div>
    )
  }

  return (
    <div className="analytics__link__item">
      <div className="analytics__link__item-container">
        <p className="analytics__link__item__title body">
          {_.truncate(label, { length: 50 })}
        </p>
        <p className="analytics__link__item__url body">
          {_.truncate(url, { length: 50 })}
        </p>
      </div>
      <p className="analytics__link__item__click-count subhead">
        {clickCount} Klik
      </p>
    </div>
  )
}

export default AnalyticsLinkItem
