import React from "react"

const Loading = () => {
  return (
    <div className="loading">
      <div className="loading__container">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  )
}

export default Loading
