import React from "react"
import { BasicModal } from "@components/utils/Modal"
import Cropper from "@components/utils/Cropper"
import { useDashboardContext } from "@services/DashboardContext"
import { uploadCoverImage } from "@http/DashboardRepository"

const CoverImageModal = ({ show, onHide, upImg }) => {
  // JSX
  return (
    <BasicModal
      show={show}
      onHide={onHide}
      id="coverImage"
      className="image-modal"
      title="Cover Image"
      size="lg"
      backdrop="static"
    >
      <CoverImageCropper upImg={upImg} onHide={onHide} />
    </BasicModal>
  )
}

const CoverImageCropper = ({ upImg, onHide }) => {
  const { personalize, currentSelection, coverImages, isLoadingInitialData } =
    useDashboardContext()

  const handleUploadCoverImage = uploadFile => {
    const data = new FormData()
    data.append("pipehome_cover_image", uploadFile)
    isLoadingInitialData.setValue(true)
    onHide()
    uploadCoverImage(personalize.value.id, data)
      .then(res => {
        currentSelection.setValue(res.data.data.id)
        coverImages.setValue(prevState => {
          return {
            ...prevState,
            custom: {
              id: res.data.data.id,
              url: res.data.data.url,
            },
          }
        })
      })
      .catch(err => {
        console.log(err.response)
      })
      .finally(() => {
        isLoadingInitialData.setValue(false)
      })
  }

  return (
    <Cropper
      upImg={upImg}
      aspectRatio={97 / 41}
      isCircularCrop={false}
      handleOnClick={handleUploadCoverImage}
    />
  )
}

export default CoverImageModal
