import React, { useState, useRef, useEffect } from "react"
import moment from "moment"
import { useDashboardContext } from "@services/DashboardContext"
import { getMe } from "@http/UserRepository"
import { resendVerifyEmail } from "@http/EmailPasswordRepository.js"
import { getUserData, setUserData } from "@http/client"
import { ClientOnly, detectMob, handleToggleMobileBackdrop } from "@src/Helper"
import { AlertSuccess, AlertWarning } from "@components/utils/Alert"
import Sidenav from "@components/utils/Sidenav"
import Loading from "@components/utils/Loading"
import MyPage from "./MyPage"
import DashboardLayout from "./DashboardLayout"

const Dashboard = ({ location }) => {
  // Hooks
  const { personalize, currentSelection, currentPersonalizationValue } =
    useDashboardContext()
  const [isVerificationSent, setIsVerificationSent] = useState(false)
  const backdrop = useRef(null)

  useEffect(() => {
    handleGetMe()
  }, [])

  // Custom Functions
  const handleGetMe = () => {
    getMe()
      .then(res => setUserData(res.data))
      .catch(err => console.log(err.response))
  }

  const handleResendVerificationEmail = () => {
    resendVerifyEmail()
      .then(() => setIsVerificationSent(true))
      .catch(err => {
        console.log(err.response)
        setIsVerificationSent(false)
      })
  }

  const handleCloseBackdrop = layoutName => {
    if (backdrop.current) {
      if (backdrop.current.classList.contains("backdrop--show")) {
        const sidenav = document.querySelector(".sidenav")

        backdrop.current.classList.remove("backdrop--show")
        sidenav.classList.add("sidenav--expand")
        sidenav.classList.remove("sidenav--expanded")

        layoutName?.map(layout => {
          const name = document.querySelector(`.${layout}__layout`)
          name.classList.add(`${layout}__layout--expand`)
          name.classList.remove(`${layout}__layout--expanded`)
        })

        document.querySelector("body").classList.remove("overflow-hidden")
        document.querySelector(".sidenav").style.zIndex = 996
        currentSelection.setValue(personalize.value.pipehome_cover_image_id)
        currentPersonalizationValue.setValue({
          title: personalize.value.title,
          feed_title: personalize.value.feed_title,
          description: personalize.value.description,
          working_hour_start_at: moment.unix(
            personalize.value.working_hour_start_at
          ),
          working_hour_end_at: moment.unix(
            personalize.value.working_hour_end_at
          ),
          settings: {
            will_show_working_hour:
              personalize.value.settings.will_show_working_hour,
          },
          pipehome_type_id: personalize.value.pipehome_type_id,
        })
      }
    }
  }

  // Variables
  const user = getUserData("user_data")
  const isEmptyObj =
    Object.keys(personalize.value).length === 0 &&
    personalize.value.constructor === Object

  // Custom Component
  const VerifyEmailAddressAlert = ({ mobClassName }) => {
    const hasVerified = user && user.data.email_verified_at === null
    if (hasVerified) {
      return (
        <AlertWarning
          className={`flex mb-2 ${detectMob() ? mobClassName : ""}`}
          text={
            <p className={`body mb-0 ${detectMob() ? "" : "flex-ja-center"}`}>
              Mohon untuk melakukan verifikasi alamat e-mail{" "}
              <strong>{user.data.email}</strong>. Jika kamu tidak menerima
              e-mail verifikasi,
              <span
                role="button"
                tabIndex="0"
                className="alert-link alert-link-warning text-underline mx-1"
                onClick={() => handleResendVerificationEmail()}
                onKeyDown={e => {
                  if (e.key === "Enter") handleResendVerificationEmail()
                }}
              >
                klik disini
              </span>
              untuk mengirim ulang e-mail verifikasi.
            </p>
          }
        />
      )
    }
    return <></>
  }

  // JSX
  if (detectMob()) {
    return (
      <>
        <div id="m-dashboard" className="dashboard">
          <div
            className="mobile-backdrop"
            onClick={() => handleToggleMobileBackdrop()}
          ></div>
          <div className="row mx-0">
            <div className="col-12 px-0">
              {isEmptyObj ? (
                <Loading />
              ) : (
                <>
                  <VerifyEmailAddressAlert mobClassName="mx-3 px-3" />
                  <div className="dashboard__container">
                    {isVerificationSent && (
                      <AlertSuccess
                        className="flex-a-center"
                        text={
                          <p className="body mb-0">
                            Kami telah mengirimkan email verifikasi kamu. Tolong
                            diperiksa sekarang!
                          </p>
                        }
                      />
                    )}
                    <MyPage />
                    <ClientOnly>
                      <DashboardLayout location={location} />
                    </ClientOnly>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className="dashboard">
        <div
          ref={backdrop}
          className="backdrop"
          onClick={() =>
            handleCloseBackdrop([
              "cover-image",
              "personalization",
              "social-link",
              "vanilla-link",
              "feed",
              "styling",
            ])
          }
        ></div>
        <Sidenav />
        {isEmptyObj ? (
          <Loading />
        ) : (
          <>
            <VerifyEmailAddressAlert />
            <div className="dashboard__container">
              {isVerificationSent && (
                <AlertSuccess
                  className="flex-a-center"
                  text={
                    <p className="body mb-0">
                      Kami telah mengirimkan email verifikasi kamu. Tolong
                      diperiksa sekarang!
                    </p>
                  }
                />
              )}
              <MyPage />
              <ClientOnly>
                <DashboardLayout location={location} />
              </ClientOnly>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default Dashboard
