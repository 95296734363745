import React, { useRef } from "react"
import { createUseStyles } from "react-jss"
import isDarkColor from "is-dark-color"
import { LightenDarkenColor } from "lighten-darken-color"
import { Icon } from "@iconify/react"
import { useDashboardContext } from "@services/DashboardContext"
import { handleOpenSidenav, handleGetDashboard, detectMob } from "@src/Helper"
import SocialLinkLayout from "./SocialLinkLayout"

const useStyles = createUseStyles({
  buttonStyle: props => {
    const isMonochrome =
      props.button.foreground_color === props.button.background_color // to check if button has the same color as its text
    const isCustom =
      props.button.foreground_color !== null &&
      props.button.background_color !== null // to check if foreground color and background color is customized or not
    const isDark = isCustom
      ? isDarkColor(props.button.foreground_color) // for light/dark color detection
      : null
    const colorConstant = 150 // to determine how dark/light the color should be darken/lighten
    const bgColorConstant = 30 // to determine how dark/light the background color should be darken/lighten
    const ifBackgroundNull = props.button.foreground_color // to check if foreground color of a button is customized or following the theme
      ? LightenDarkenColor(
          props.button.foreground_color,
          isDark ? bgColorConstant : bgColorConstant * -1
        )
      : ""
    const ifForegroundNull = props.button.background_color // to check if background color of a button is customized or following the theme
      ? LightenDarkenColor(
          props.button.background_color,
          isDark ? bgColorConstant : bgColorConstant * -1
        )
      : ""

    if (!isMonochrome) {
      if (props.button?.background_color && props.button?.foreground_color) {
        return {
          borderColor: [[props.button.foreground_color], "!important"],
          background: [props.button.foreground_color, "!important"],
          color: [props.button.background_color, "!important"],
          "&:hover": {
            borderColor: `${
              props.button.background_color || ifBackgroundNull
            } !important`,
            background: `${
              props.button.background_color || ifBackgroundNull
            } !important`,
            color: `${
              props.button.foreground_color || ifForegroundNull
            } !important`,
          },
        }
      } else if (props.button?.background_color) {
        return {
          borderColor: "initial",
          background: "initial",
          color: [props.button.background_color, "!important"],
          "&:hover": {
            borderColor: `${
              props.button.background_color || ifBackgroundNull
            } !important`,
            background: `${
              props.button.background_color || ifBackgroundNull
            } !important`,
            color: `${
              props.button.foreground_color || ifForegroundNull
            } !important`,
          },
        }
      } else if (props.button?.foreground_color) {
        return {
          borderColor: [[props.button.foreground_color], "!important"],
          background: [props.button.foreground_color, "!important"],
          color: "initial",
          "&:hover": {
            borderColor: `${
              props.button.background_color || ifBackgroundNull
            } !important`,
            background: `${
              props.button.background_color || ifBackgroundNull
            } !important`,
            color: `${
              props.button.foreground_color || ifForegroundNull
            } !important`,
          },
        }
      }
    } else if (
      props.button.background_color === null &&
      props.button.foreground_color === null
    ) {
      return {
        borderColor: "initial",
        background: "initial",
        color: "initial",
        "&:hover": {
          borderColor: "initial",
          background: "initial",
          color: "initial",
        },
      }
    } else {
      return {
        borderColor: [[props.button.background_color], "!important"],
        background: [props.button.background_color, "!important"],
        color: props.button.foreground_color && [
          `${LightenDarkenColor(
            props.button.foreground_color,
            isDark ? colorConstant : colorConstant * -1
          )}`,
          "!important",
        ],
        "&:hover": {
          background: props.button.background_color && [
            `${LightenDarkenColor(
              props.button.background_color,
              isDark ? bgColorConstant : bgColorConstant * -1
            )}`,
            "!important",
          ],
          color: props.button.foreground_color && [
            `${LightenDarkenColor(
              props.button.foreground_color,
              isDark ? colorConstant : colorConstant * -1
            )}`,
            "!important",
          ],
        },
      }
    }
  },
})

const SocialLink = React.forwardRef((props, ref) => {
  // Hooks
  const { personalize, socialMediaLinks, isLoadingDashboard } =
    useDashboardContext()
  const filteredSocialLinks = socialMediaLinks.value?.filter(
    socialLink => socialLink.is_active === true
  )

  const classes = useStyles(personalize.value.settings)

  // JSX
  return (
    <section
      id={detectMob() ? "m-socialLink" : ""}
      className={`social-link ${detectMob ? "container" : ""}`}
      style={{
        top:
          personalize?.value.settings.will_show_working_hour === false
            ? "-15px"
            : "",
      }}
    >
      <div className="social-link__button-container">
        {filteredSocialLinks?.length > 0 ? (
          filteredSocialLinks.map((link, key) => {
            let socialIcon = link.social_type
            if (socialIcon === "email") {
              socialIcon = "maildotru"
            }

            return (
              <a
                className={`social-link__button ${classes.buttonStyle}`}
                key={key}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon
                  icon={`simple-icons:${socialIcon}`}
                  width="18"
                  height="18"
                />
              </a>
            )
          })
        ) : (
          <>
            <a
              className={`social-link__button ${classes.buttonStyle}`}
              href="#"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon icon="simple-icons:maildotru" width="18" height="18" />
            </a>
            <a
              className={`social-link__button ${classes.buttonStyle}`}
              href="#"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon icon="simple-icons:tiktok" width="18" height="18" />
            </a>
            <a
              className={`social-link__button ${classes.buttonStyle}`}
              href="#"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon icon="simple-icons:instagram" width="18" height="18" />
            </a>
          </>
        )}
        {!detectMob() && (
          <button
            className="social-link__edit-button"
            type="button"
            onClick={() =>
              handleOpenSidenav(
                ref,
                "social-link__layout",
                handleGetDashboard(
                  undefined,
                  socialMediaLinks,
                  undefined,
                  isLoadingDashboard
                )
              )
            }
          >
            <span className="mdi mdi-pencil mdi-18px"></span>
          </button>
        )}
      </div>
      <SocialLinkLayout ref={ref} />
    </section>
  )
})

export default SocialLink
