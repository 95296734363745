import { getToken, httpClient as client } from "./client"

export const getDashboard = () => {
  return new Promise((resolve, reject) => {
    client
      .get("dashboard", {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .then(res => resolve(res))
      .catch(err => reject(err))
  })
}

export const getInitialData = page_id => {
  return new Promise((resolve, reject) => {
    client
      .get(`page/${page_id}/initial-data`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .then(res => resolve(res))
      .catch(err => reject(err))
  })
}

export const createCategory = (page_id, name) => {
  return new Promise((resolve, reject) => {
    client
      .post(
        `page/${page_id}/category`,
        { name },
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      )
      .then(res => resolve(res))
      .catch(err => reject(err))
  })
}

export const updateCategory = (page_id, name, id) => {
  return new Promise((resolve, reject) => {
    client
      .patch(
        `page/${page_id}/category`,
        { name, id },
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      )
      .then(res => resolve(res))
      .catch(err => reject(err))
  })
}

export const deleteCategory = (page_id, id) => {
  return new Promise((resolve, reject) => {
    client
      .delete(`page/${page_id}/category`, {
        headers: { Authorization: `Bearer ${getToken()}` },
        data: { id },
      })
      .then(res => resolve(res))
      .catch(err => reject(err))
  })
}

export const getPost = (page_id, post_id) => {
  return new Promise((resolve, reject) => {
    client
      .get(`page/${page_id}/post/${post_id}`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .then(res => resolve(res))
      .catch(err => reject(err))
  })
}

export const getPosts = (page_id, category_id) => {
  return new Promise((resolve, reject) => {
    client
      .get(`page/${page_id}/posts`, {
        params: { category_id },
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .then(res => resolve(res))
      .catch(err => reject(err))
  })
}

export const createPost = (page_id, data) => {
  return new Promise((resolve, reject) => {
    client
      .post(`page/${page_id}/post`, data, {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .then(res => resolve(res))
      .catch(err => reject(err))
      .finally(() => resolve())
  })
}

export const updatePost = (page_id, data) => {
  return new Promise((resolve, reject) => {
    client
      .post(`page/${page_id}/post`, data, {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .then(res => resolve(res))
      .catch(err => reject(err))
      .finally(() => resolve())
  })
}

export const deletePost = (page_id, pipehome_post_id) => {
  return new Promise((resolve, reject) => {
    client
      .delete(`page/${page_id}/post`, {
        headers: { Authorization: `Bearer ${getToken()}` },
        data: { pipehome_post_id },
      })
      .then(res => resolve(res))
      .catch(err => reject(err))
      .finally(() => resolve())
  })
}

export const updateProfile = (
  page_id,
  title,
  description,
  working_hour_start_at,
  working_hour_end_at,
  will_show_working_hour,
  pipehome_type_id
) => {
  return new Promise((resolve, reject) => {
    client
      .patch(
        `page/${page_id}/profile`,
        {
          title,
          description,
          working_hour_start_at,
          working_hour_end_at,
          settings: {
            will_show_working_hour: will_show_working_hour,
          },
          pipehome_type_id,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then(res => resolve(res))
      .catch(err => reject(err))
      .finally(() => resolve())
  })
}

export const uploadProfilePicture = (page_id, data) => {
  return new Promise((resolve, reject) => {
    client
      .post(`page/${page_id}/profile-picture`, data, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then(res => resolve(res))
      .catch(err => reject(err))
      .finally(() => resolve())
  })
}

export const uploadCoverImage = (page_id, data) => {
  return new Promise((resolve, reject) => {
    client
      .post(`page/${page_id}/cover-image`, data, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then(res => resolve(res))
      .catch(err => reject(err))
      .finally(() => resolve())
  })
}

export const updateCoverImage = (page_id, pipehome_cover_image_id) => {
  return new Promise((resolve, reject) => {
    client
      .patch(
        `page/${page_id}/cover-image`,
        { pipehome_cover_image_id },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then(res => resolve(res))
      .catch(err => reject(err))
      .finally(() => resolve())
  })
}

export const updateLinks = (page_id, links) => {
  return new Promise((resolve, reject) => {
    client
      .patch(
        `page/${page_id}/links`,
        {
          links,
        },
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      )
      .then(res => resolve(res))
      .catch(err => reject(err))
  })
}

export const updateSocialLinks = (page_id, links) => {
  return new Promise((resolve, reject) => {
    client
      .patch(
        `page/${page_id}/social-links`,
        {
          links,
        },
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      )
      .then(res => resolve(res))
      .catch(err => reject(err))
  })
}

export const updateFeedTitle = (page_id, feed_title) => {
  return new Promise((resolve, reject) => {
    client
      .patch(
        `page/${page_id}/feed-title`,
        {
          feed_title,
        },
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      )
      .then(res => resolve(res))
      .catch(err => reject(err))
      .finally(() => resolve())
  })
}

export const updateStyling = (page_id, data) => {
  return new Promise((resolve, reject) => {
    client
      .post(`page/${page_id}/settings`, data, {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .then(res => resolve(res))
      .catch(err => reject(err))
      .finally(() => resolve())
  })
}
