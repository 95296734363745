import { httpClient as client, getToken } from "./client"

export const getMe = () => {
  return new Promise((resolve, reject) => {
    client
      .get("me", {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .then(res => resolve(res))
      .catch(err => reject(err))
  })
}

export const isUsernameAvailable = username => {
  return new Promise((resolve, reject) => {
    client
      .get("is-username-available", {
        params: { username },
      })
      .then(res => resolve(res))
      .catch(err => reject(err))
  })
}

export const changeUsername = (username, page_id) => {
  return new Promise((resolve, reject) => {
    client
      .post(
        `page/${page_id}/change-username`,
        {
          username,
        },
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      )
      .then(res => resolve(res))
      .catch(err => reject(err))
  })
}
