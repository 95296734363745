import React from "react"
import Seo from "@components/seo"
import AccountSettings from "@components/AccountSettings/AccountSettings"

const AccountSettingsPage = () => {
  return (
    <>
      <Seo title="Account Settings" />
      <AccountSettings />
    </>
  )
}

export default AccountSettingsPage
