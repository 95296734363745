import React, { useRef } from "react"
import { Fab, Action } from "react-tiny-fab"
import { useDashboardContext } from "@services/DashboardContext"
import {
  handleOpenSidenav,
  handleGetDashboard,
  detectMob,
  handleToggleMobileBackdrop,
} from "@src/Helper"
import CoverImage from "./CoverImage/CoverImage"
import Feed from "./Feed/Feed"
import Personalization from "./Personalization/Personalization"
import SocialLink from "./SocialLink/SocialLink"
import VanillaLink from "./VanillaLink/VanillaLink"
import StylingLayout from "./Styling/StylingLayout"

const DashboardLayout = () => {
  // Hooks
  const {
    personalize,
    socialMediaLinks,
    vanillaLinks,
    isLoadingDashboard,
    themes,
    isFetchInitialData,
  } = useDashboardContext()

  const stylingRef = useRef(null)
  const coverImageRef = useRef(null)
  const personalizationRef = useRef(null)
  const socialRef = useRef(null)
  const vanillaRef = useRef(null)
  const feedRef = useRef(null)

  // Variable
  const snowyThemes = ["classic-christmas", "fancy-christmas"]

  // Custom Function
  const checkIsFetchInitialData = () => {
    if (themes.value.length === 0) {
      isFetchInitialData.setValue(true)
    }
  }

  const compileSnow = () => {
    let snow = []
    for (let i = 0; i < 150; i++) {
      snow.push(<div className="snow" key={i}></div>)
    }
    return snow
  }

  // JSX
  return (
    <div
      ref={node => {
        if (node) {
          switch (personalize.value.settings.theme.background_type) {
            case "background_image":
              return node.style.setProperty(
                "background",
                `url(${
                  personalize.value.settings.theme.background_image.value
                    ?.fileUrl
                    ? personalize.value.settings.theme.background_image.value
                        .fileUrl
                    : personalize.value.settings.theme.background_image.value
                }) no-repeat fixed center center/cover`,
                "important"
              )
            case "solid_color":
              return node.style.setProperty(
                "background",
                personalize.value.settings.theme.solid_color.value,
                "important"
              )
            case "gradient_color":
              return node.style.setProperty(
                "background",
                `linear-gradient(to right, ${personalize.value.settings.theme.gradient_color.value[0]}, ${personalize.value.settings.theme.gradient_color.value[1]})`,
                "important"
              )
            default:
              return node.style.removeProperty("background")
          }
        }
      }}
      className={`dashboard__layout ${personalize.value.settings.theme.name}`}
    >
      {snowyThemes.includes(personalize.value.settings.theme.name) && (
        <div className="snow__container">{compileSnow()}</div>
      )}
      <CoverImage ref={coverImageRef} />
      <Personalization ref={personalizationRef} />
      <SocialLink ref={socialRef} />
      <VanillaLink ref={vanillaRef} />
      <Feed ref={feedRef} />
      {detectMob() ? (
        <Fab
          icon={<span className="mdi mdi-cog mdi-24px"></span>}
          alwaysShowTitle={true}
          onClick={() => handleToggleMobileBackdrop()}
        >
          <Action
            text="Edit Feed"
            onClick={() =>
              handleOpenSidenav(
                feedRef,
                "feed__layout",
                handleGetDashboard(
                  undefined,
                  undefined,
                  undefined,
                  isLoadingDashboard
                )
              )
            }
          >
            <span className="mdi mdi-post"></span>
          </Action>
          <Action
            text="Edit Link"
            onClick={() =>
              handleOpenSidenav(
                vanillaRef,
                "vanilla-link__layout",
                handleGetDashboard(
                  undefined,
                  undefined,
                  vanillaLinks,
                  isLoadingDashboard
                )
              )
            }
          >
            <span className="mdi mdi-link"></span>
          </Action>
          <Action
            text="Edit Social Media"
            onClick={() =>
              handleOpenSidenav(
                socialRef,
                "social-link__layout",
                handleGetDashboard(
                  undefined,
                  socialMediaLinks,
                  undefined,
                  isLoadingDashboard
                )
              )
            }
          >
            <span className="mdi mdi-instagram"></span>
          </Action>
          <Action
            text="Edit Profile"
            onClick={() =>
              handleOpenSidenav(
                personalizationRef,
                "personalization__layout",
                checkIsFetchInitialData
              )
            }
          >
            <span className="mdi mdi-account"></span>
          </Action>
          <Action
            text="Edit Cover Image"
            onClick={() =>
              handleOpenSidenav(
                coverImageRef,
                "cover-image__layout",
                checkIsFetchInitialData
              )
            }
          >
            <span className="mdi mdi-image"></span>
          </Action>
          <Action
            text="Edit Tampilan"
            onClick={() =>
              handleOpenSidenav(
                stylingRef,
                "styling__layout",
                checkIsFetchInitialData
              )
            }
          >
            <span className="mdi mdi-pencil"></span>
          </Action>
        </Fab>
      ) : (
        <button
          className="customization__button"
          type="button"
          onClick={() =>
            handleOpenSidenav(
              stylingRef,
              "styling__layout",
              checkIsFetchInitialData
            )
          }
        >
          <span className="mdi mdi-cog mdi-18px"></span>
        </button>
      )}
      <StylingLayout ref={stylingRef} />
    </div>
  )
}

export default DashboardLayout
