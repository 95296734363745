import React from "react"
import { Link } from "gatsby"

const AccountSettingsTabBar = ({ activeTab, setActiveTab }) => {
  return (
    <div className="account-settings__layout__tab-bar">
      <Link
        className={`account-settings__layout__tab-bar__link body ${
          activeTab === 0 ? "active" : ""
        }`}
        to="/account-settings#username"
        onClick={() => setActiveTab(0)}
      >
        Username
      </Link>
      <Link
        className={`account-settings__layout__tab-bar__link body ${
          activeTab === 1 ? "active" : ""
        }`}
        to="/account-settings#email"
        onClick={() => setActiveTab(1)}
      >
        Alamat E-mail
      </Link>
      <Link
        className={`account-settings__layout__tab-bar__link body ${
          activeTab === 2 ? "active" : ""
        }`}
        to="/account-settings#password"
        onClick={() => setActiveTab(2)}
      >
        Kata Sandi
      </Link>
    </div>
  )
}

export default AccountSettingsTabBar
