import React from "react"
import Seo from "@components/seo"
import Dashboard from "@components/Dashboard/Dashboard"

const DashboardPage = ({ location }) => {
  return (
    <>
      <Seo title="Dashboard" />
      <Dashboard location={location} />
    </>
  )
}

export default DashboardPage
