import React from "react"
import MediaPostGridItem from "./MediaPostGridItem"
import { AnalyticsSkeletonLoader } from "@components/utils/SkeletonLoader"

const MediaPostGrid = ({ posts, startNumber, isLoading }) => {
  return (
    <>
      {!isLoading.value && posts.value.length === 0 && (
        <p className="body mb-0 text-black-30">
          Data analitik tidak ditemukan pada tanggal yang ditentukan.
        </p>
      )}
      <div className="analytics-media-post-grid__list row gx-3" role="list">
        {isLoading.value ? (
          <AnalyticsSkeletonLoader
            className={"mt-2 mb-1"}
            quantities={9}
            col={4}
          />
        ) : (
          posts.value?.map((post, key) => {
            return (
              <MediaPostGridItem
                post={post}
                key={key}
                number={startNumber + key}
                col={4}
              />
            )
          })
        )}
      </div>
    </>
  )
}

export default MediaPostGrid
