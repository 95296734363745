import React, { useEffect } from "react"
import { toast } from "react-toastify"
import { updateStyling } from "@http/DashboardRepository"
import { useDashboardContext } from "@services/DashboardContext"
import { useInput, handleCloseSidenav, detectMob } from "@src/Helper"
import StylingTheme from "./StylingTheme"
import StylingBackground from "./StylingBackground"
import StylingButton from "./StylingButton"
import StylingFont from "./StylingFont"
import StylingFeed from "./StylingFeed"

const StylingLayout = React.forwardRef((props, ref) => {
  // Hooks
  const { personalize, currentStylingValue } = useDashboardContext()
  const isSubmitLoading = useInput(false)

  useEffect(() => {
    handleCloseSidenav(ref, "styling__layout", handleResetCurrentStyling)
  }, [personalize.value])

  // Variable
  const settings = personalize.value?.settings
  const currentSettings = currentStylingValue.value?.settings

  // Custom Functions
  const handleResetCurrentStyling = () => {
    currentStylingValue.setValue(prevState => {
      return {
        ...prevState,
        settings: {
          font: {
            color: settings.font.color,
          },
          post: {
            background_color: settings.post.background_color,
            foreground_color: settings.post.foreground_color,
          },
          theme: {
            name: settings.theme.name,
            solid_color: {
              value: settings.theme.solid_color.value,
            },
            gradient_color: {
              value: settings.theme.gradient_color.value,
            },
            background_type: settings.theme.background_type,
            background_image: {
              value: settings.theme.background_image.value,
            },
          },
          button: {
            type: settings.button.type,
            shape: settings.button.shape,
            background_color: settings.button.background_color,
            foreground_color: settings.button.foreground_color,
          },
        },
      }
    })
  }

  const handleUpdateStyling = () => {
    const data = new FormData()

    data.append(
      "settings[theme][name]",
      currentStylingValue.value.settings.theme.name
    )

    currentStylingValue.value.settings.theme?.background_type
      ? data.append(
          "settings[theme][background_type]",
          currentStylingValue.value.settings.theme.background_type
        )
      : data.append("settings[theme][background_type]", "")

    currentStylingValue.value.settings.theme.solid_color?.value
      ? data.append(
          "settings[theme][solid_color][value]",
          currentStylingValue.value.settings.theme.solid_color.value
        )
      : data.append("settings[theme][solid_color][value]", "")

    if (
      currentStylingValue.value.settings.theme.gradient_color.value.length > 0
    ) {
      for (
        let i = 0;
        i <
        currentStylingValue.value.settings.theme.gradient_color.value.length;
        i++
      ) {
        data.append(
          "settings[theme][gradient_color][value][]",
          currentStylingValue.value.settings.theme.gradient_color.value[i]
        )
      }
    } else {
      data.append("settings[theme][gradient_color][value][]", "")
    }

    if (
      currentStylingValue.value.settings.theme.background_image.value !==
        null &&
      typeof currentStylingValue.value.settings.theme.background_image.value !==
        "string"
    ) {
      data.append(
        "settings[theme][background_image][value]",
        currentStylingValue.value.settings.theme.background_image.value.file
      )
    } else if (
      currentStylingValue.value.settings.theme.background_image.value === null
    ) {
      data.append("settings[theme][background_image][value]", "")
    } else if (
      typeof currentStylingValue.value.settings.theme.background_image.value ===
      "string"
    ) {
      data.append(
        "settings[theme][background_image][value]",
        currentStylingValue.value.settings.theme.background_image.value
      )
      data.append(
        "settings[theme][background_image][file_path]",
        currentStylingValue.value.settings.theme.background_image.file_path
      )
    }

    currentStylingValue.value.settings.button?.type
      ? data.append(
          "settings[button][type]",
          currentStylingValue.value.settings.button.type
        )
      : data.append("settings[button][type]", "")

    currentStylingValue.value.settings.button?.shape
      ? data.append(
          "settings[button][shape]",
          currentStylingValue.value.settings.button.shape
        )
      : data.append("settings[button][shape]", "")

    currentStylingValue.value.settings.button?.foreground_color
      ? data.append(
          "settings[button][foreground_color]",
          currentStylingValue.value.settings.button.foreground_color
        )
      : data.append("settings[button][foreground_color]", "")

    currentStylingValue.value.settings.button?.background_color
      ? data.append(
          "settings[button][background_color]",
          currentStylingValue.value.settings.button.background_color
        )
      : data.append("settings[button][background_color]", "")

    currentStylingValue.value.settings.post?.foreground_color
      ? data.append(
          "settings[post][foreground_color]",
          currentStylingValue.value.settings.post.foreground_color
        )
      : data.append("settings[post][foreground_color]", "")

    currentStylingValue.value.settings.post?.background_color
      ? data.append(
          "settings[post][background_color]",
          currentStylingValue.value.settings.post.background_color
        )
      : data.append("settings[post][background_color]", "")

    currentStylingValue.value.settings.font?.color
      ? data.append(
          "settings[font][color]",
          currentStylingValue.value.settings.font.color
        )
      : data.append("settings[font][color]", "")

    data.append("_method", "PATCH")
    isSubmitLoading.setValue(true)

    updateStyling(personalize.value.id, data)
      .then(() => {
        personalize.setValue(prevState => {
          return {
            ...prevState,
            settings: {
              ...prevState.settings,
              font: {
                color: currentSettings.font.color,
              },
              post: {
                background_color: currentSettings.post.background_color,
                foreground_color: currentSettings.post.foreground_color,
              },
              theme: {
                name: currentSettings.theme.name,
                solid_color: {
                  value: currentSettings.theme.solid_color.value,
                },
                gradient_color: {
                  value: currentSettings.theme.gradient_color.value,
                },
                background_type: currentSettings.theme.background_type,
                background_image: {
                  value: currentSettings.theme.background_image.value,
                },
              },
              button: {
                type: currentSettings.button.type,
                shape: currentSettings.button.shape,
                background_color: currentSettings.button.background_color,
                foreground_color: currentSettings.button.foreground_color,
              },
            },
          }
        })
        toast.success("Berhasil menyimpan perubahan!")
      })
      .catch(err => {
        console.log(err.response)
      })
      .finally(() => isSubmitLoading.setValue(false))
  }

  // JSX
  return (
    <div
      ref={ref}
      className={`customization__layout styling__layout ${
        detectMob() ? "styling__layout--slide-down" : "styling__layout--expand"
      }`}
    >
      <form
        onSubmit={e => {
          e.preventDefault()
          handleUpdateStyling()
        }}
      >
        <div className="styling__header">
          <div className="styling__header--left">
            <span
              className="mdi mdi-close mdi-24px"
              onClick={() =>
                handleCloseSidenav(
                  ref,
                  "styling__layout",
                  handleResetCurrentStyling
                )
              }
            ></span>
            <h2 className="styling__header-title--heading-5">Edit Tampilan</h2>
          </div>
          <div className="layout__header--right styling__header--right">
            <button
              className={`button button-md button-secondary ${
                detectMob() ? "w-100" : ""
              }`}
              type="submit"
              disabled={isSubmitLoading.value}
            >
              {isSubmitLoading.value ? (
                <span
                  className="spinner-border spinner-border-sm ms-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                <>Simpan Perubahan</>
              )}
            </button>
          </div>
        </div>
        <div className="styling__body">
          <div className={`container ${detectMob() ? "pad-b-90" : ""}`}>
            <StylingTheme />
            <StylingBackground />
            <StylingButton />
            <StylingFont />
            <StylingFeed />
          </div>
        </div>
      </form>
    </div>
  )
})

export default StylingLayout
