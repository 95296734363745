import React from "react"
import { useAppContext } from "@services/AppContext"

const SplashScreen = () => {
  // Hooks
  const { isLoadingUser } = useAppContext()

  // JSX
  return (
    <div className={`splash-screen ${isLoadingUser.value === true && "show"}`}>
      <div className="splash-screen__container">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  )
}

export default SplashScreen
