import React from "react"
import { useDashboardContext } from "@services/DashboardContext"
import { useInput } from "@src/Helper"
import EditCategoryModal from "./Modal/EditCategoryModal"

const PostCategory = ({
  className,
  categoryItem,
  idx,
  isEdit,
  handleSetActive,
}) => {
  // Hooks
  const { currentCreatePostValue, currentEditPostValue } = useDashboardContext()
  const isModalShow = useInput(false)

  // JSX
  return (
    <>
      <EditCategoryModal
        show={isModalShow.value}
        onHide={() => isModalShow.setValue(false)}
        categoryItem={categoryItem}
        idx={idx}
      />
      <div className={`${className}__category-item`} role="listitem" key={idx}>
        <button
          className={`${className}__category-button button button-sm ${
            categoryItem.id ===
            (isEdit
              ? currentEditPostValue.value.selectedCategory
              : currentCreatePostValue.value.selectedCategory)
              ? "button-primary"
              : "button-outline-primary"
          }`}
          type="button"
          onClick={() => {
            handleSetActive(categoryItem.id)
          }}
        >
          {categoryItem.name}
          {categoryItem.id ===
            (isEdit
              ? currentEditPostValue.value.selectedCategory
              : currentCreatePostValue.value.selectedCategory) &&
            categoryItem.id !== 0 && (
              <span
                role="button"
                tabIndex="0"
                aria-label="edit category"
                className={`${className}__category-edit mdi mdi-pencil`}
                onClick={() => isModalShow.setValue(true)}
                onKeyDown={e => {
                  if (e.key === "Enter") isModalShow.setValue(true)
                }}
              ></span>
            )}
        </button>
      </div>
    </>
  )
}

export default PostCategory
