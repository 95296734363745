import { httpClient as client, getToken } from "./client"

export const signIn = (email, password) => {
  return new Promise((resolve, reject) => {
    client
      .post("sign-in", {
        email,
        password,
      })
      .then(res => resolve(res))
      .catch(err => reject(err))
  })
}

export const signUp = (username, email, password, terms_and_conditions) => {
  return new Promise((resolve, reject) => {
    client
      .post("sign-up", {
        username,
        email,
        password,
        terms_and_conditions,
      })
      .then(res => resolve(res))
      .catch(err => reject(err))
  })
}

export const signOut = () => {
  return new Promise((resolve, reject) => {
    client
      .post(
        "sign-out",
        {},
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      )
      .then(res => resolve(res))
      .catch(err => reject(err))
  })
}

export const forgotPassword = email => {
  return new Promise((resolve, reject) => {
    client
      .post("forgot-password", {
        email,
      })
      .then(res => resolve(res))
      .catch(err => reject(err))
  })
}

export const resetPassword = (password, password_confirmation, token) => {
  return new Promise((resolve, reject) => {
    client
      .post("reset-password", {
        password,
        password_confirmation,
        token,
      })
      .then(res => resolve(res))
      .catch(err => reject(err))
  })
}
