import React from "react"
import { toast } from "react-toastify"
import { BasicModal } from "@components/utils/Modal"
import Cropper from "@components/utils/Cropper"
import { useDashboardContext } from "@services/DashboardContext"
import { uploadProfilePicture } from "@http/DashboardRepository"
import { useInput } from "@src/Helper"

const ProfilePictureModal = ({ show, onHide, upImg }) => {
  // JSX
  return (
    <BasicModal
      show={show}
      onHide={onHide}
      id="profilePicture"
      className="image-modal"
      title="Profile Picture"
      size="lg"
      backdrop="static"
    >
      <ProfilePictureCropper upImg={upImg} onHide={onHide} />
    </BasicModal>
  )
}

const ProfilePictureCropper = ({ upImg, onHide }) => {
  const { personalize } = useDashboardContext()
  const isSubmitLoading = useInput(false)

  const handleUploadProfilePicture = uploadFile => {
    const data = new FormData()
    data.append("profile_picture", uploadFile)
    isSubmitLoading.setValue(true)
    uploadProfilePicture(personalize.value.id, data)
      .then(res => {
        personalize.setValue(prevState => {
          return {
            ...prevState,
            profile_picture_url: res.data.page.profile_picture_url,
          }
        })
        onHide()
        toast.success("Berhasil menyimpan perubahan!")
      })
      .catch(err => {
        console.log(err.response)
      })
      .finally(() => {
        isSubmitLoading.setValue(false)
      })
  }

  return (
    <Cropper
      upImg={upImg}
      aspectRatio={1 / 1}
      isCircularCrop={true}
      isDisabled={isSubmitLoading.value}
      handleOnClick={handleUploadProfilePicture}
    />
  )
}

export default ProfilePictureModal
