import React, { useRef } from "react"
import { Link, navigate } from "gatsby"
import { signOut } from "@http/AuthRepository"
import { removeToken, removeUserData } from "@http/client"
import { detectMob } from "@src/Helper"
import Logo from "@images/sociopipe-white-logo.svg"
import WhiteLogo from "@images/sociopipe-white-logo.svg"
import BlackLogo from "@images/sociopipe-main-logo.svg"

const HeaderAuthenticated = ({ location }) => {
  // Hooks
  const menu = useRef(null)

  // JSX
  if (detectMob()) {
    // Custom Functions
    const handleSignOut = () => {
      signOut()
        .then(() => {
          removeToken()
          removeUserData()
          navigate("/")
        })
        .catch(err => {
          console.log(err)
        })
    }

    const handleCloseMenu = () => {
      menu.current.classList.remove("expand")
    }
    const handleOpenMenu = () => {
      menu.current.classList.add("expand")
    }

    // JSX
    return (
      <header id="m-authHeader" className="auth-header">
        <div className="container">
          <nav className="auth-header__nav">
            <ul className="auth-header__nav__ul flex-a-center jc-between">
              <li className="auth-header__nav__ul__list">
                <Link className="auth-header__nav__ul__list__link" to="/">
                  <img
                    className="auth-header__nav__ul__list__link__logo"
                    src={WhiteLogo}
                    alt="Sociopipe Logo"
                    width={130}
                    height={27}
                  />
                </Link>
              </li>
              <li className="auth-header__nav__ul__list">
                <span
                  role="button"
                  aria-label="Open Menu"
                  tabIndex="0"
                  className="auth-header__nav__ul__list__hamburger mdi mdi-menu mdi-24px"
                  onClick={() => handleOpenMenu()}
                  onKeyDown={e => {
                    if (e.key === "Enter") handleOpenMenu()
                  }}
                ></span>
              </li>
            </ul>
          </nav>
          <div className="auth-header__menu" ref={menu}>
            <nav className="auth-header__menu__nav container">
              <ul className="auth-header__menu__nav__top flex-a-center jc-between">
                <li className="auth-header__menu__nav__top__list">
                  <Link
                    className="auth-header__menu__nav__top__list__link"
                    to="/"
                    onClick={() => handleCloseMenu()}
                  >
                    <img
                      className="auth-header__menu__nav__top__list__link__logo"
                      src={BlackLogo}
                      alt="Sociopipe Logo"
                      width={130}
                      height={27}
                    />
                  </Link>
                </li>
                <li className="auth-header__menu__nav__top__list">
                  <span
                    role="button"
                    aria-label="Close Menu"
                    tabIndex="0"
                    className="auth-header__menu__nav__top__list__close mdi mdi-close mdi-24px"
                    onClick={() => handleCloseMenu()}
                    onKeyDown={e => {
                      if (e.key === "Enter") handleCloseMenu()
                    }}
                  ></span>
                </li>
              </ul>
              <ul className="auth-header__menu__nav__bottom">
                <li
                  className={`auth-header__menu__nav__bottom__list ${
                    location.pathname === "/dashboard" ? "active" : ""
                  }`}
                >
                  <Link
                    className="auth-header__menu__nav__bottom__list__link subhead"
                    to="/dashboard"
                    onClick={() => handleCloseMenu()}
                  >
                    <span className="mdi mdi-24px mdi-home auth-header__menu__nav__bottom__link-icon"></span>{" "}
                    Dasbor
                  </Link>
                </li>
                <li
                  className={`auth-header__menu__nav__bottom__list ${
                    location.pathname === "/analytics" ? "active" : ""
                  }`}
                >
                  <Link
                    className="auth-header__menu__nav__bottom__list__link subhead"
                    to="/analytics"
                    onClick={() => handleCloseMenu()}
                  >
                    <span className="mdi mdi-24px mdi-google-analytics auth-header__menu__nav__bottom__link-icon"></span>{" "}
                    Analitik
                  </Link>
                </li>
                <li
                  className={`auth-header__menu__nav__bottom__list ${
                    location.pathname === "/account-settings" ? "active" : ""
                  }`}
                >
                  <Link
                    className="auth-header__menu__nav__bottom__list__link subhead"
                    to="/account-settings"
                    onClick={() => handleCloseMenu()}
                  >
                    <span className="mdi mdi-24px mdi-account-cog auth-header__menu__nav__bottom__link-icon"></span>{" "}
                    Pengaturan Akun
                  </Link>
                </li>
                <hr className="my-3" />
                <li
                  className={`auth-header__menu__nav__bottom__list ${
                    location.pathname === "/new-features" ? "active" : ""
                  }`}
                >
                  <Link
                    className="auth-header__menu__nav__bottom__list__link subhead"
                    to="/new-features"
                    onClick={() => handleCloseMenu()}
                  >
                    <span className="mdi mdi-24px mdi-update auth-header__menu__nav__bottom__link-icon"></span>{" "}
                    What's New
                  </Link>
                </li>
                <li
                  className={`auth-header__menu__nav__bottom__list ${
                    location.pathname === "/our-features" ? "active" : ""
                  }`}
                >
                  <Link
                    className="auth-header__menu__nav__bottom__list__link subhead"
                    to="/our-features"
                    onClick={() => handleCloseMenu()}
                  >
                    <span className="mdi mdi-24px mdi-information auth-header__menu__nav__bottom__link-icon"></span>{" "}
                    Apa itu Pipehome?
                  </Link>
                </li>
                <li
                  className={`auth-header__menu__nav__bottom__list ${
                    location.pathname === "/contact-us" ? "active" : ""
                  }`}
                >
                  <Link
                    className="auth-header__menu__nav__bottom__list__link subhead"
                    to="/contact-us"
                    onClick={() => handleCloseMenu()}
                  >
                    <span className="mdi mdi-24px mdi-help-circle auth-header__menu__nav__bottom__link-icon"></span>{" "}
                    Hubungi Kami
                  </Link>
                </li>
                <li
                  className={`auth-header__menu__nav__bottom__list ${
                    location.pathname === "/privacy-policy" ? "active" : ""
                  }`}
                >
                  <Link
                    className="auth-header__menu__nav__bottom__list__link subhead"
                    to="/privacy-policy"
                    onClick={() => handleCloseMenu()}
                  >
                    <span className="mdi mdi-24px mdi-file-document auth-header__menu__nav__bottom__link-icon"></span>{" "}
                    Kebijakan Privasi
                  </Link>
                </li>
                <li
                  className={`auth-header__menu__nav__bottom__list ${
                    location.pathname === "/terms-and-conditions"
                      ? "active"
                      : ""
                  }`}
                >
                  <Link
                    className="auth-header__menu__nav__bottom__list__link subhead"
                    to="/terms-and-conditions"
                    onClick={() => handleCloseMenu()}
                  >
                    <span className="mdi mdi-24px mdi-file-document auth-header__menu__nav__bottom__link-icon"></span>{" "}
                    Syarat & Ketentuan
                  </Link>
                </li>
                <li className="auth-header__menu__nav__bottom__list">
                  <span
                    className="auth-header__menu__nav__bottom__list__link subhead"
                    role="button"
                    tabIndex="0"
                    onClick={() => {
                      handleCloseMenu()
                      handleSignOut()
                    }}
                    onKeyDown={e => {
                      if (e.key === "Enter") {
                        handleCloseMenu()
                        handleSignOut()
                      }
                    }}
                  >
                    <span className="mdi mdi-24px mdi-logout auth-header__menu__nav__bottom__link-icon"></span>{" "}
                    Logout
                  </span>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
    )
  }

  return (
    <header className="auth-header pad-x-50">
      <Link className="auth-header__logo" to="/">
        <img
          className="auth-header__logo__image"
          src={Logo}
          alt="Sociopipe Logo"
          width={177}
          height={36}
        />
      </Link>
      <Link className="button button-md button-secondary" to="/dashboard">
        Kembali ke Dasbor
      </Link>
    </header>
  )
}

export default HeaderAuthenticated
