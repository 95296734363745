import React from "react"

export const AlertSuccess = ({ text, className }) => {
  return (
    <div className={`alert alert-success ${className}`} role="alert">
      <span className="alert-icon mdi mdi-check-circle-outline"></span>
      {text}
    </div>
  )
}

export const AlertInfo = ({ text, className }) => {
  return (
    <div className={`alert alert-info ${className}`} role="alert">
      <span className="alert-icon mdi mdi-information-outline"></span>
      {text}
    </div>
  )
}

export const AlertWarning = ({ text, className }) => {
  return (
    <div className={`alert alert-warning ${className}`} role="alert">
      <span className="alert-icon mdi mdi-alert-outline"></span>
      {text}
    </div>
  )
}

export const AlertError = ({ text, className }) => {
  return (
    <div className={`alert alert-error ${className}`} role="alert">
      <span className="alert-icon mdi mdi-close-circle-outline"></span>
      {text}
    </div>
  )
}
