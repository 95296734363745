import React from "react"
import { useDashboardContext } from "@services/DashboardContext"
import { getPosts } from "@http/DashboardRepository"

const FeedCategory = ({ categoryItem, idx, handleSetActive }) => {
  // Hooks
  const { personalize, postCount, postList } = useDashboardContext()

  // Custom Function
  const handleGetPosts = id => {
    postList.setValue(prevState => {
      return {
        ...prevState,
        isLoading: true,
      }
    })
    getPosts(personalize.value.id, id)
      .then(res => {
        postList.setValue(prevState => {
          return {
            ...prevState,
            filteredPosts: res.data.posts,
            isLoading: false,
          }
        })
        postCount.setValue(res.data.posts.length)
      })
      .catch(err => console.log(err.response))
  }

  // JSX
  return (
    <div className="category-item" role="listitem" key={idx}>
      <button
        className={`category-item__button button button-sm ${
          categoryItem.is_active ? "button-primary" : "button-outline-primary"
        }`}
        onClick={() => {
          handleSetActive(idx)
          handleGetPosts(categoryItem.id)
        }}
      >
        {categoryItem.name}
      </button>
    </div>
  )
}

export default FeedCategory
