import React, { useEffect } from "react"
import { getToken } from "@http/client"
import { useInput } from "@src/Helper"
import HeaderAuthenticated from "./HeaderAuthenticated"
import HeaderUnauthenticated from "./HeaderUnauthenticated"

export const Header = ({ location }) => {
  // Hooks
  const token = useInput(null)

  useEffect(() => {
    token.setValue(getToken())
  }, [getToken()])

  // JSX
  return (
    <>
      {token.value !== null ? (
        <HeaderAuthenticated location={location} />
      ) : (
        <HeaderUnauthenticated location={location} />
      )}
    </>
  )
}
