module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sociopipe","short_name":"Sociopipe","description":"Satu link gratis untuk menampilkan semua produk dan sosial media yang terhubung dari bisnis kamu. Share link gratis sosial media, semuanya bisa sociopipe.","lang":"id","start_url":"/","background_color":"#fe7a20","theme_color":"#fe7a20","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a58b001ba5d59e659061dd5d692b0db6"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
