import React, { useRef, useEffect } from "react"
import _ from "lodash"
import { toast } from "react-toastify"
import { useDashboardContext } from "@services/DashboardContext"
import { detectMob, useInput, isInputEmpty } from "@src/Helper"
import { createPost } from "@http/DashboardRepository"
import { AlertError } from "@components/utils/Alert"
import HelpTooltip from "@components/utils/HelpTooltip"
import CreateCategoryModal from "./Modal/CreateCategoryModal"
import ImageUpload from "./PostImageUpload"
import PostLink from "./PostLink"
import PostCategory from "./PostCategory"

const CreatePostLayout = ({ postLayoutRef }) => {
  // Hooks
  const { personalize, currentCreatePostValue, isLoadingFeed } =
    useDashboardContext()
  const nameRef = useRef(null)
  const captionRef = useRef(null)
  const isSubmitLoading = useInput(false)
  const isModalShow = useInput(false)
  const errors = useInput({})
  const linkError = useInput({})

  const post = currentCreatePostValue.value

  useEffect(() => {
    const data = post.categories?.map(category => {
      if (category.id !== 0) {
        category.is_active = false
      } else {
        category.is_active = true
      }
      return category
    })
    handleUpdateCurrentPost("categories", [...data])
  }, [])

  // Variable
  let data = [...post.links]

  // Custom Functions
  const handleCreatePost = () => {
    errors.setValue({ ...errors.value })

    const formData = new FormData()
    if (post.selectedCategory !== 0) {
      formData.append("pipehome_category_id", post.selectedCategory)
    }
    formData.append("name", post.name)
    if (post.pictureUrl === "") {
      formData.append("media", post.pictureUrl)
    } else if (typeof post.pictureUrl === "object") {
      formData.append("media", post.pictureUrl.file)
    }
    formData.append("caption", post.caption)
    formData.append("is_highlight", post.isHighlight ? 1 : 0)
    post.links.map((link, key) => {
      formData.append(`post_links[${key}][url]`, link.url)
      formData.append(`post_links[${key}][label]`, link.label)
    })

    isSubmitLoading.setValue(true)
    createPost(personalize.value.id, formData)
      .then(() => {
        errors.setValue({})
        linkError.setValue({})
        isLoadingFeed.setValue(true)
        handleClosePostLayout()
        toast.success("Berhasil menyimpan perubahan!")
      })
      .catch(err => {
        if (err.response.status === 422) {
          errors.setValue({
            ...errors.value,
            name: err.response.data.errors.name
              ? err.response.data.errors.name
              : null,
            media: err.response.data.errors.media
              ? err.response.data.errors.media[0]
              : null,
            caption: err.response.data.errors.caption
              ? err.response.data.errors.caption[0]
              : null,
          })
          linkError.setValue(err.response.data.errors)
        }
        console.log(err.response)
      })
      .finally(() => isSubmitLoading.setValue(false))
  }

  const handleUpdateCurrentPost = (key, value) => {
    currentCreatePostValue.setValue(prevState => {
      return {
        ...prevState,
        [key]: value,
      }
    })
  }

  const handleAddLink = () => {
    currentCreatePostValue.setValue(prevState => {
      return {
        ...prevState,
        links: [...prevState.links, { label: "", url: "" }],
      }
    })
  }

  const handleSetActive = idx => {
    handleUpdateCurrentPost("selectedCategory", idx)
  }

  const handleDeleteLink = idx => {
    data[idx] = null
    data = _.filter(data)
    handleUpdateCurrentPost("links", data)
    linkError.setValue({})
  }

  const handleAddTitle = (key, value) => {
    let datum = { ...data[key] }
    datum.label = value
    data[key] = datum
    handleUpdateCurrentPost("links", data)
  }

  const handleAddUrl = (key, value) => {
    let datum = { ...data[key] }
    datum.url = value
    data[key] = datum
    handleUpdateCurrentPost("links", data)
  }

  const handleClosePostLayout = () => {
    isInputEmpty(nameRef)
    isInputEmpty(captionRef)
    currentCreatePostValue.setValue(prevState => {
      return {
        ...prevState,
        links: [
          {
            label: "",
            url: "",
          },
        ],
        name: "",
        caption: "",
        imageInput: "",
        pictureUrl: "",
        selectedCategory: 0,
        isHighlight: false,
      }
    })
    errors.setValue({})
    linkError.setValue({})
    if (postLayoutRef.current) {
      if (detectMob()) {
        if (
          postLayoutRef.current.classList.contains(
            "create-post__layout--slide-up"
          )
        ) {
          postLayoutRef.current.classList.add("create-post__layout--slide-down")
          postLayoutRef.current.classList.remove(
            "create-post__layout--slide-up"
          )
          document
            .querySelector(".create-post__image-placeholder")
            .classList.remove("image-filled")
          document
            .querySelector(".create-post__header--right")
            .classList.remove("layout__header--fixed-bottom")
        }
      } else {
        if (
          postLayoutRef.current.classList.contains(
            "create-post__layout--expanded"
          )
        ) {
          postLayoutRef.current.classList.add("create-post__layout--expand")
          postLayoutRef.current.classList.remove(
            "create-post__layout--expanded"
          )
          document
            .querySelector(".feed__layout")
            .classList.remove("feed__layout--double-expanded", "black-90-bg")
          document
            .querySelector(".feed__layout .backdrop")
            .classList.remove("backdrop--show")
          document
            .querySelector(".create-post__image-placeholder")
            .classList.remove("image-filled")
        }
      }
    }
  }

  // JSX
  return (
    <div
      ref={postLayoutRef}
      id={`${detectMob() ? "m-createPost" : ""}`}
      className={`customization__layout create-post__layout ${
        detectMob()
          ? "create-post__layout--slide-down"
          : "create-post__layout--expand"
      }`}
    >
      <CreateCategoryModal
        show={isModalShow.value}
        onHide={() => isModalShow.setValue(false)}
      />
      <form
        onSubmit={e => {
          e.preventDefault()
          handleCreatePost()
        }}
      >
        <div className="create-post__header">
          <div className="create-post__header--left">
            <span
              className="mdi mdi-close mdi-24px"
              onClick={() => handleClosePostLayout()}
            ></span>
            <h2 className="create-post__header-title--heading-5">
              <em>Create Post</em>
            </h2>
          </div>
          <div className="create-post__header--right">
            <button
              className={`button button-md button-secondary ${
                detectMob() ? "w-100" : ""
              }`}
              type="submit"
              disabled={isSubmitLoading.value}
            >
              {isSubmitLoading.value ? (
                <span
                  className="spinner-border spinner-border-sm ms-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                <>Simpan Perubahan</>
              )}
            </button>
          </div>
        </div>
        <div className="create-post__body">
          <div className="container">
            {errors.value.media && (
              <AlertError
                className={`flex-a-center ${detectMob() ? "" : "mt-3"}`}
                text={<p className="body mb-0">{errors.value.media}</p>}
              />
            )}
            <div className="row">
              <div className={detectMob() ? "col-12" : "col-4"}>
                <ImageUpload className="create-post" isEdit={false} />
              </div>
              <div className={detectMob() ? "col-12 px-0 mt-3" : "col-8"}>
                <div className="create-post__name">
                  <label
                    htmlFor="inputName"
                    className="create-post__name-label label-sm"
                  >
                    Nama Post
                    <sup>
                      <strong className="text-cherry-50">*</strong>
                    </sup>
                  </label>
                  <input
                    type="text"
                    className={`create-post__name-input ${
                      detectMob() ? "input-md" : "input-sm"
                    }`}
                    id="inputName"
                    spellCheck={false}
                    placeholder="Masukkan nama post kamu"
                    ref={nameRef}
                    value={post.name}
                    onChange={e => {
                      isInputEmpty(nameRef)
                      handleUpdateCurrentPost("name", e.target.value)
                    }}
                  />
                  {errors.value.name && (
                    <span className="error-message caption mb-1">
                      {errors.value.name}
                    </span>
                  )}
                </div>
                <div className="create-post__category">
                  {post.categories.length > 1 && (
                    <>
                      <label
                        htmlFor="inputCategory"
                        className={`create-post__category-label label-sm ${
                          detectMob() ? "mb-0" : ""
                        }`}
                      >
                        Pilih 1 Kategori
                      </label>
                      <div className="create-post__category-list">
                        {post.categories?.map((categoryItem, key) => {
                          return (
                            <PostCategory
                              className="create-post"
                              categoryItem={categoryItem}
                              idx={key}
                              isEdit={false}
                              handleSetActive={handleSetActive}
                            />
                          )
                        })}
                      </div>
                      <label className="label label-sm mt-2">atau</label>
                    </>
                  )}
                  <button
                    className="create-post__category-add-button flex button-sm"
                    type="button"
                    onClick={() => isModalShow.setValue(true)}
                  >
                    + Tambah Kategori
                  </button>
                </div>
                <div className="create-post__check form-check">
                  <input
                    type="checkbox"
                    className="create-post__check-input form-check-input"
                    id="inputCheck"
                    checked={post.isHighlight}
                    onChange={() => {
                      handleUpdateCurrentPost("isHighlight", !post.isHighlight)
                    }}
                  />
                  <label
                    className="create-post__check-label form-check-label label-sm"
                    htmlFor="inputCheck"
                  >
                    Soroti post ini
                  </label>
                </div>
                <p className="create-post__check-description--caption">
                  Soroti post jika kamu ingin pengunjung <em>pipehome</em> kamu
                  lebih memperhatikan post ini.
                </p>
              </div>
            </div>
            <hr />
            <div className="create-post__link-list" role="list">
              {post.links?.map((link, key) => {
                return (
                  <PostLink
                    className={"create-post"}
                    link={link}
                    idx={key}
                    linkError={linkError}
                    handleAddTitle={handleAddTitle}
                    handleAddUrl={handleAddUrl}
                    handleDeleteLink={handleDeleteLink}
                  />
                )
              })}
            </div>
            {post.links.length >= 6 ? (
              <></>
            ) : (
              <button
                className="create-post__add-link-button button button-sm button-outline-primary"
                type="button"
                onClick={() => handleAddLink()}
              >
                <span
                  className={`dashboard__post-layout__form__add-link__button__icon mdi mdi-link-variant ${
                    detectMob() ? "" : "mdi-18px"
                  }`}
                ></span>{" "}
                Tambah Link Baru
              </button>
            )}
            <p className="create-post__link-description--caption">
              Note: Link pada Post memungkinkan kamu menghubungkan berbagai
              platform tempat kamu memajang produk ini ke dalam satu post
              pipehome.
            </p>
            <div className="create-post__description">
              <label
                htmlFor="inputCaption"
                className="create-post__description-label label-sm"
              >
                Deskripsi Post{" "}
                <HelpTooltip>
                  Caption post bertujuan untuk menjelaskan lebih detail tentang
                  post ini.
                </HelpTooltip>
              </label>
              <textarea
                className="create-post__description-input textarea-sm"
                id="inputCaption"
                rows="4"
                spellCheck={false}
                placeholder="Tulis sesuatu yang menarik tentang post ini"
                ref={captionRef}
                value={post.caption}
                onChange={e => {
                  isInputEmpty(captionRef)
                  handleUpdateCurrentPost(
                    "caption",
                    e.target.value.replace(/  +/g, " ").trimStart()
                  )
                }}
              />
              {errors.value.caption && (
                <span className="error-message caption mb-1">
                  {errors.value.caption}
                </span>
              )}
              <span className="create-post__description--caption">
                {post.caption === "" ? "0" : post.caption.length}
                /500 karakter
              </span>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default CreatePostLayout
