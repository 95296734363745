import React, { useRef } from "react"
import Modal from "react-bootstrap/Modal"
import { useDashboardContext } from "@services/DashboardContext"
import { updateCategory, deleteCategory } from "@http/DashboardRepository"
import { useInput, isInputEmpty } from "@src/Helper"

const EditCategoryModal = ({ show, onHide, categoryItem, idx }) => {
  // Hooks
  const {
    personalize,
    feedCategories,
    currentCreatePostValue,
    currentEditPostValue,
    isLoadingFeed,
  } = useDashboardContext()
  const nameRef = useRef(null)
  const name = useInput(feedCategories.value[idx]?.name)
  const isSaveLoading = useInput(false)
  const isDeleteLoading = useInput(false)
  const isShowConfirmed = useInput(false)
  const error = useInput("")

  // Custom Function
  const handleUpdateCategory = () => {
    isSaveLoading.setValue(true)
    updateCategory(personalize.value.id, name.value, categoryItem.id)
      .then(res => {
        console.log("oke")
        error.setValue("")
        feedCategories.value.find(x => x.id === categoryItem.id).name =
          res.data.categories[idx - 1].name
        currentCreatePostValue.value.categories.find(
          x => x.id === categoryItem.id
        ).name = res.data.categories[idx - 1].name
        isSaveLoading.setValue(false)
        onHide()
      })
      .catch(err => {
        console.log("tidak")
        if (err.response.status === 422) {
          error.setValue(err.response.data.errors.name[0])
        }
        isSaveLoading.setValue(false)
      })
  }

  const handleDeleteCategory = () => {
    isDeleteLoading.setValue(true)
    deleteCategory(personalize.value.id, categoryItem.id)
      .then(() => {
        feedCategories.setValue(
          feedCategories.value?.filter(x => x.id !== categoryItem.id)
        )
        currentCreatePostValue.setValue(prevState => {
          return {
            ...prevState,
            selectedCategory: 0,
            categories: currentCreatePostValue.value.categories?.filter(
              x => x.id !== categoryItem.id
            ),
          }
        })
        currentEditPostValue.setValue(prevState => {
          return {
            ...prevState,
            selectedCategory: 0,
            categories: currentEditPostValue.value.categories?.filter(
              x => x.id !== categoryItem.id
            ),
          }
        })
        isDeleteLoading.setValue(false)
        feedCategories.value?.map(category => {
          if (category.id === 0) {
            category.is_active = true
          }
          return category
        })
        onHide()
      })
      .catch(err => {
        isDeleteLoading.setValue(false)
        console.log(err.response)
      })
  }

  // JSX
  return (
    <Modal
      show={show}
      onHide={() => {
        isShowConfirmed.setValue(false)
        onHide()
      }}
      id="editCategoryModal"
      className="category-modal"
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="category-modal__head">
        <Modal.Title id="contained-modal-title-vcenter">
          <span
            role="button"
            tabIndex="0"
            aria-label="Hide Modal Button"
            className="mdi mdi-36px mdi-close category-modal__close"
            onClick={() => {
              isShowConfirmed.setValue(false)
              onHide()
            }}
            onKeyDown={e => {
              if (e.key === "Enter") {
                isShowConfirmed.setValue(false)
                onHide()
              }
            }}
          ></span>
          <h2 className="category-modal--heading-5">Ubah Kategori</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="category-modal__body">
        <form onSubmit={e => e.preventDefault()}>
          <div>
            <label className="label-lg category-modal__label" htmlFor="name">
              Nama Kategori
            </label>
            <input
              className="input-lg category-modal__input filled"
              type="text"
              id="name"
              placeholder="Masukkan nama kategori kamu"
              ref={nameRef}
              defaultValue={feedCategories.value[idx]?.name}
              onChange={e => {
                isInputEmpty(nameRef)
                name.setValue(e.target.value)
              }}
            />
            {error.value && (
              <span className="error-message caption mb-1">{error.value}</span>
            )}
            <div className="flex-a-center">
              <button
                className="category-modal__button button button-md button-secondary"
                type="button"
                onClick={e => {
                  e.preventDefault()
                  handleUpdateCategory()
                }}
                disabled={isSaveLoading.value}
              >
                {isSaveLoading.value ? (
                  <span
                    className="spinner-border spinner-border-sm ms-1"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  <>Simpan Perubahan</>
                )}
              </button>
              <button
                ref={deleteRef =>
                  deleteRef &&
                  deleteRef.style.setProperty("color", "#D03E3F", "important")
                }
                className="category-modal__button button button-md button-default ms-2"
                type="button"
                onClick={e => {
                  e.preventDefault()
                  if (isShowConfirmed.value === false) {
                    isShowConfirmed.setValue(true)
                  } else {
                    handleDeleteCategory()
                    isLoadingFeed.setValue(true)
                    isShowConfirmed.setValue(false)
                  }
                }}
                disabled={isDeleteLoading.value}
              >
                {isDeleteLoading.value && !isShowConfirmed.value ? (
                  <span
                    className="spinner-border spinner-border-sm ms-1"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : !isDeleteLoading.value && isShowConfirmed.value ? (
                  <>
                    Konfirmasi
                    <span className="mdi mdi-18px mdi-trash-can"></span>
                  </>
                ) : (
                  <>Hapus Kategori</>
                )}
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default EditCategoryModal
