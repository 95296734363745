import React, { useState, useRef } from "react"
import { toast } from "react-toastify"
import { useDashboardContext } from "@services/DashboardContext"
import { detectMob } from "@src/Helper"
import HelpTooltip from "@components/utils/HelpTooltip"
import { ColorPickerModal } from "@components/utils/Modal"

const StylingBackground = () => {
  // Hooks
  const { currentStylingValue } = useDashboardContext()
  const [isSolidColorModal, setIsSolidColorModal] = useState(false)
  const [isGradientColorModal, setIsGradientColorModal] = useState(false)
  const input = useRef()

  // Custom Functions
  const ErrorMsg = () => (
    <>
      Maksimum ukuran{" "}
      <strong>
        <em>background</em>
      </strong>{" "}
      adalah 5MB.
    </>
  )

  const insertImage = e => {
    const newData = {
      file: e.target.files[0],
      fileUrl: URL.createObjectURL(e.target.files[0]),
    }
    if (e.target.files[0]?.size > 5120000) {
      toast.error(<ErrorMsg />)
    } else {
      currentStylingValue.setValue(prevState => {
        return {
          ...prevState,
          settings: {
            ...prevState.settings,
            theme: {
              ...prevState.settings.theme,
              background_type: "background_image",
              background_image: {
                value: newData,
              },
            },
          },
        }
      })
      input.current.value = ""
    }
  }

  const handleDeleteSelectedBackground = (bg_type, val) => {
    currentStylingValue.setValue(prevState => {
      const currentSelection = prevState.settings.theme.background_type
      return {
        ...prevState,
        settings: {
          ...prevState.settings,
          theme: {
            ...prevState.settings.theme,
            background_type:
              currentSelection === bg_type ? null : currentSelection,
            [bg_type]: {
              value: val,
            },
          },
        },
      }
    })
  }

  const handleSelectBackgroundType = bg_type => {
    currentStylingValue.setValue(prevState => {
      return {
        ...prevState,
        settings: {
          ...prevState.settings,
          theme: {
            ...prevState.settings.theme,
            background_type: bg_type,
          },
        },
      }
    })
  }

  // JSX
  return (
    <>
      <ColorPickerModal
        show={isSolidColorModal}
        onHide={() => setIsSolidColorModal(false)}
        id={detectMob() ? "m-backgroundSolid" : "backgroundSolid"}
        className={`${detectMob() ? "modal-z-index" : ""} color-picker-modal`}
        title="Warna Solid"
        size="sm"
        valueOne={
          currentStylingValue.value.settings.theme.solid_color?.value ??
          "#ffffff"
        }
        isGradient={false}
        fn={(currentStylingValue, color) => {
          currentStylingValue.setValue(prevState => {
            return {
              ...prevState,
              settings: {
                ...prevState.settings,
                theme: {
                  ...prevState.settings.theme,
                  background_type: "solid_color",
                  solid_color: {
                    value: color.value,
                  },
                },
              },
            }
          })
        }}
      />
      <ColorPickerModal
        show={isGradientColorModal}
        onHide={() => setIsGradientColorModal(false)}
        id={detectMob() ? "m-backgroundGradient" : "backgroundGradient"}
        className={`${detectMob() ? "modal-z-index" : ""} color-picker-modal`}
        title="Warna Gradien"
        size="md"
        valueOne={
          currentStylingValue.value.settings.theme.gradient_color?.value[0] ??
          "#ffffff"
        }
        valueTwo={
          currentStylingValue.value.settings.theme.gradient_color?.value[1] ??
          "#ffffff"
        }
        isGradient={true}
        fn={(currentStylingValue, colorOne, colorTwo) => {
          currentStylingValue.setValue(prevState => {
            return {
              ...prevState,
              settings: {
                ...prevState.settings,
                theme: {
                  ...prevState.settings.theme,
                  background_type: "gradient_color",
                  gradient_color: {
                    value: [colorOne.value, colorTwo.value],
                  },
                },
              },
            }
          })
        }}
      />
      <section className={`styling__background ${detectMob() ? "px-0" : ""}`}>
        <h2 className="background--heading-6">
          <em>Background</em>
          <HelpTooltip>
            Background ini dapat menggantikan background bawaan dari Tema yang
            kamu pilih.
          </HelpTooltip>
        </h2>
        <div className="background__list row" role="list">
          <div
            className={`background__item ${
              detectMob() ? "mob-background__item" : ""
            }`}
            role="listitem"
          >
            <div
              className={`background__item-container ${
                currentStylingValue.value.settings.theme.background_type ===
                null
                  ? "selected-background"
                  : ""
              }`}
            >
              <div
                className="background__image-container"
                role="button"
                tabIndex="0"
                aria-label="No Background"
                onClick={() => handleSelectBackgroundType(null)}
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    handleSelectBackgroundType(null)
                  }
                }}
              >
                <div className="background--none">
                  <span className="mdi mdi-image-off text-black-30"></span>
                  <p className="background__text--caption">
                    Tidak Ada <em>Background</em>
                  </p>
                </div>
              </div>
            </div>
            <h3 className="background__type--caption">Tidak Ada</h3>
          </div>
          <div
            className={`background__item ${
              detectMob() ? "mob-background__item" : ""
            }`}
            role="listitem"
          >
            <div
              className={`background__item-container ${
                currentStylingValue.value.settings.theme.background_image
                  ?.value === null
                  ? "background__border--dashed"
                  : ""
              } ${
                currentStylingValue.value.settings.theme.background_type ===
                "background_image"
                  ? "selected-background"
                  : ""
              }`}
            >
              {currentStylingValue.value.settings.theme.background_image
                ?.value ? (
                <>
                  <div
                    className="background__delete-button"
                    role="button"
                    tabIndex="0"
                    onClick={() =>
                      handleDeleteSelectedBackground("background_image", null)
                    }
                    onKeyDown={e => {
                      if (e.key === "Enter") {
                        handleDeleteSelectedBackground("background_image", null)
                      }
                    }}
                  >
                    <span className="mdi mdi-trash-can mdi-18px"></span>
                  </div>
                  <div
                    className="background__image-container"
                    role="button"
                    tabIndex="0"
                    onClick={() =>
                      handleSelectBackgroundType("background_image")
                    }
                    onKeyDown={e => {
                      if (e.key === "Enter") {
                        handleSelectBackgroundType("background_image")
                      }
                    }}
                  >
                    <img
                      className="background__image-placeholder"
                      src={
                        currentStylingValue.value.settings.theme
                          .background_image?.value.fileUrl !== undefined
                          ? currentStylingValue.value.settings.theme
                              .background_image.value.fileUrl
                          : currentStylingValue.value.settings.theme
                              .background_image.value
                      }
                      alt="Custom Background"
                    />
                  </div>
                </>
              ) : (
                <>
                  <input
                    ref={input}
                    className="background__image-input form-control"
                    type="file"
                    accept="image/png, image/jpeg"
                    onChange={e => insertImage(e)}
                    onClick={e => (e.target.value = null)}
                  />
                  <span className="mdi mdi-image-plus position-absolute text-black-30"></span>
                </>
              )}
            </div>
            <h3 className="background__type--caption">Gambar</h3>
          </div>
          <div
            className={`background__item ${
              detectMob() ? "mob-background__item" : ""
            }`}
            role="listitem"
          >
            <div
              className={`background__item-container ${
                currentStylingValue.value.settings.theme.solid_color?.value ===
                null
                  ? "background__border--dashed"
                  : ""
              } ${
                currentStylingValue.value.settings.theme.background_type ===
                "solid_color"
                  ? "selected-background"
                  : ""
              }`}
              style={
                currentStylingValue.value.settings.theme.solid_color?.value ===
                "#ffffff"
                  ? { border: "1px solid #f3f3f3" }
                  : {}
              }
              role="button"
              tabIndex="0"
              onClick={() =>
                currentStylingValue.value.settings.theme.solid_color?.value ===
                  null && setIsSolidColorModal(true)
              }
              onKeyDown={e => {
                if (e.key === "Enter") {
                  currentStylingValue.value.settings.theme.solid_color
                    ?.value === null && setIsSolidColorModal(true)
                }
              }}
            >
              {currentStylingValue.value.settings.theme.solid_color?.value ? (
                <>
                  <div
                    className="background__delete-button"
                    role="button"
                    tabIndex="0"
                    onClick={() =>
                      handleDeleteSelectedBackground("solid_color", null)
                    }
                    onKeyDown={e => {
                      if (e.key === "Enter") {
                        handleDeleteSelectedBackground("solid_color", null)
                      }
                    }}
                  >
                    <span className="mdi mdi-trash-can mdi-18px"></span>
                  </div>
                  <div
                    className="background__color-container"
                    role="button"
                    tabIndex="0"
                    onClick={() => handleSelectBackgroundType("solid_color")}
                    onKeyDown={e => {
                      if (e.key === "Enter") {
                        handleSelectBackgroundType("solid_color")
                      }
                    }}
                  >
                    <div
                      className="background__color-placeholder"
                      style={{
                        background: currentStylingValue.value.settings.theme
                          .solid_color?.value
                          ? currentStylingValue.value.settings.theme.solid_color
                              .value
                          : "",
                      }}
                    ></div>
                  </div>
                </>
              ) : (
                <span className="mdi mdi-palette text-black-30"></span>
              )}
            </div>
            <h3 className="background__type--caption">Solid</h3>
          </div>
          <div
            className={`background__item ${
              detectMob() ? "mob-background__item" : ""
            }`}
            role="listitem"
          >
            <div
              className={`background__item-container ${
                currentStylingValue.value.settings.theme.gradient_color?.value
                  .length <= 1
                  ? "background__border--dashed"
                  : ""
              } ${
                currentStylingValue.value.settings.theme.background_type ===
                "gradient_color"
                  ? "selected-background"
                  : ""
              }`}
              role="button"
              tabIndex="0"
              onClick={() =>
                currentStylingValue.value.settings.theme.gradient_color?.value
                  .length <= 1 && setIsGradientColorModal(true)
              }
              onKeyDown={e => {
                if (e.key === "Enter") {
                  currentStylingValue.value.settings.theme.gradient_color?.value
                    .length <= 1 && setIsGradientColorModal(true)
                }
              }}
            >
              {currentStylingValue.value.settings.theme.gradient_color?.value
                .length > 1 ? (
                <>
                  <div
                    className="background__delete-button"
                    role="button"
                    tabIndex="0"
                    onClick={() =>
                      handleDeleteSelectedBackground("gradient_color", [])
                    }
                    onKeyDown={e => {
                      if (e.key === "Enter") {
                        handleDeleteSelectedBackground("gradient_color", [])
                      }
                    }}
                  >
                    <span className="mdi mdi-trash-can mdi-18px"></span>
                  </div>
                  <div
                    className="background__color-container"
                    role="button"
                    tabIndex="0"
                    onClick={() => handleSelectBackgroundType("gradient_color")}
                    onKeyDown={e => {
                      if (e.key === "Enter") {
                        handleSelectBackgroundType("gradient_color")
                      }
                    }}
                  >
                    <div
                      className="background__color-placeholder"
                      style={{
                        background:
                          currentStylingValue.value.settings.theme
                            .gradient_color?.value.length > 1
                            ? `linear-gradient(to right, ${currentStylingValue.value.settings.theme.gradient_color?.value[0]}, ${currentStylingValue.value.settings.theme.gradient_color?.value[1]})`
                            : "",
                      }}
                    ></div>
                  </div>
                </>
              ) : (
                <span className="mdi mdi-palette text-black-30"></span>
              )}
            </div>
            <h3 className="background__type--caption">Gradien</h3>
          </div>
        </div>
      </section>
    </>
  )
}

export default StylingBackground
