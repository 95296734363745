import React from "react"
import { detectMob, isInputEmpty } from "@src/Helper"

const ChangeUsernameInputUsername = ({ usernameRef, username, error }) => {
  return (
    <div
      className={`account-settings__layout__username__form__new-username ${
        detectMob() ? "col-12" : "col-5"
      }`}
    >
      <label
        htmlFor="inputUsername"
        className="account-settings__layout__username__form__new-username__label label-sm"
      >
        Username Baru
      </label>
      <input
        type="text"
        className="account-settings__layout__username__form__new-username__input input-md"
        id="inputUsername"
        spellCheck={false}
        placeholder="Minimal 6 karakter"
        ref={usernameRef}
        value={username.value}
        onChange={e => {
          isInputEmpty(usernameRef)
          username.setValue(e.target.value)
        }}
      />
      {error.value === "" ? (
        <></>
      ) : (
        <span className="error-message caption mb-2">
          {error.value.username[0]}
        </span>
      )}
    </div>
  )
}

export default ChangeUsernameInputUsername
