import React from "react"
import { Link } from "gatsby"
import { Icon } from "@iconify/react"
import { detectMob } from "@src/Helper"
import { useLocation } from "@reach/router"
import Logo from "@images/sociopipe-white-logo.svg"

export const Footer = () => {
  // Hooks
  const location = useLocation()

  // Variables
  const clientRoute = [/^\/analytics/, /^\/dashboard/, /^\/account-settings/]
  const routeMatch = clientRoute.filter(cr => {
    return location.pathname.match(RegExp(cr))
  })

  // JSX
  if (routeMatch.length > 0) {
    return <></>
  }

  if (detectMob()) {
    return (
      <footer id="m-footer" className="footer">
        <div className="container">
          <Link className="footer__logo" to="/">
            <img
              className="footer__logo__image"
              src={Logo}
              alt="Sociopipe Logo"
            />
          </Link>
          <nav className="footer__sitemap flex-col">
            <Link className="footer__sitemap__link body" to="/privacy-policy">
              Kebijakan Privasi
            </Link>
            <Link
              className="footer__sitemap__link body"
              to="/terms-and-conditions"
            >
              Syarat &amp; Ketentuan
            </Link>
            <a
              href="mailto:support@sociopipe.com"
              className="footer__sitemap__link body"
            >
              Bantuan
            </a>
            <Link className="footer__sitemap__link body" to="/contact-us">
              Hubungi Kami
            </Link>
          </nav>
          <div className="flex-a-center jc-between">
            <div className="footer__social__media flex-a-center">
              <a
                className="footer__social__media__button"
                href="https://www.instagram.com/sociopipe/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon icon="simple-icons:instagram" width="20" height="20" />
              </a>
              <a
                className="footer__social__media__button"
                href="https://www.linkedin.com/company/sociopipe"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon icon="simple-icons:linkedin" width="20" height="20" />
              </a>
              <a
                className="footer__social__media__button"
                href="https://twitter.com/sociopipe"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon icon="simple-icons:twitter" width="20" height="20" />
              </a>
              <a
                className="footer__social__media__button"
                href="https://www.facebook.com/sociopipe"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon icon="simple-icons:facebook" width="20" height="20" />
              </a>
              <a
                className="footer__social__media__button"
                href="https://www.tiktok.com/@sociopipe"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon icon="simple-icons:tiktok" width="20" height="20" />
              </a>
            </div>
          </div>
        </div>
      </footer>
    )
  } else {
    return (
      <footer className="footer">
        <div className="container">
          <div className="flex-a-center jc-between">
            <Link className="footer__logo" to="/">
              <img
                className="footer__logo__image"
                src={Logo}
                alt="Sociopipe Logo"
              />
            </Link>
            <div className="footer__social__media flex-a-center top-index">
              <a
                className="footer__social__media__button"
                href="https://www.instagram.com/sociopipe/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon icon="simple-icons:instagram" width="20" height="20" />
              </a>
              <a
                className="footer__social__media__button"
                href="https://www.linkedin.com/company/sociopipe"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon icon="simple-icons:linkedin" width="20" height="20" />
              </a>
              <a
                className="footer__social__media__button"
                href="https://twitter.com/sociopipe"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon icon="simple-icons:twitter" width="20" height="20" />
              </a>
              <a
                className="footer__social__media__button"
                href="https://www.facebook.com/sociopipe"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon icon="simple-icons:facebook" width="20" height="20" />
              </a>
              <a
                className="footer__social__media__button"
                href="https://www.tiktok.com/@sociopipe"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon icon="simple-icons:tiktok" width="20" height="20" />
              </a>
            </div>
          </div>
          <nav className="footer__sitemap">
            <Link
              className="footer__sitemap__link caption"
              to="/privacy-policy"
            >
              Kebijakan Privasi
            </Link>
            <Link
              className="footer__sitemap__link caption"
              to="/terms-and-conditions"
            >
              Syarat &amp; Ketentuan
            </Link>
            <a
              href="mailto:support@sociopipe.com"
              className="footer__sitemap__link caption"
            >
              Bantuan
            </a>
            <Link className="footer__sitemap__link caption" to="/contact-us">
              Hubungi Kami
            </Link>
          </nav>
        </div>
      </footer>
    )
  }
}
