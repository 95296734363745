import React, { useRef } from "react"
import { Icon } from "@iconify/react"
import { useInput, handleFocus } from "@src/Helper"
import SwitchButton from "@components/utils/SwitchButton"

const SocialLinkItem = ({
  id,
  socialIcon,
  socialLink,
  handleToggleLink,
  handleChangeUrl,
  handleBlurUrl,
  errors,
}) => {
  // Hooks
  const focusUrl = useInput(false)
  const urlRef = useRef(null)

  // JSX
  return (
    <div className="social-link__item" role="listitem" key={id}>
      <div className={!socialLink?.is_active ? "social-link--disabled" : ""}>
        <div className="social-link__image-container">
          <Icon
            className="social-link__image"
            icon={`simple-icons:${socialIcon}`}
            width="18"
            height="18"
          />
        </div>
        <div>
          <h3 className="social-link__label--caption">{socialLink.label}</h3>
          <div className="social-link__url-container">
            <span className="social-link__url-icon mdi mdi-18px mdi-link-variant"></span>
            <input
              ref={urlRef}
              className={`social-link__url-input--body input--hidden ${
                focusUrl.value ? "input-visible" : ""
              }`}
              type="text"
              value={socialLink.url}
              onChange={e => {
                handleChangeUrl(id, e.target.value)
              }}
              onBlur={e => {
                handleBlurUrl(id, e.target.value)
                focusUrl.setValue(false)
              }}
            />
            <div
              className={`${focusUrl.value ? "d-none" : ""}`}
              role="button"
              tabIndex="0"
              onClick={() => {
                handleFocus(urlRef)
                focusUrl.setValue(true)
              }}
              onKeyDown={e => {
                if (e.key === "Enter") {
                  handleFocus(urlRef)
                  focusUrl.setValue(true)
                }
              }}
            >
              <h4
                className={`social-link__url-text--body ${
                  socialLink.url !== "" ? "text-black-90" : ""
                }`}
              >
                {socialLink.url === ""
                  ? socialLink.placeholder
                  : socialLink.url}
              </h4>
              <span
                className="social-link__url-edit-icon mdi mdi-pencil"
                role="button"
                tabIndex="0"
                aria-label="Edit URL"
              ></span>
            </div>
          </div>
          {errors.value[`links.${id}.url`] && (
            <span className="error-message caption mt-0">
              {errors.value[`links.${id}.url`][0]}
            </span>
          )}
        </div>
      </div>
      <div>
        <SwitchButton
          checked={socialLink.is_active}
          handleToggle={handleToggleLink}
          idx={id}
        />
      </div>
    </div>
  )
}

export default SocialLinkItem
